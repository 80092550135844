// -----------------------------------------------------------------------------
// This file contains color styles.
// -----------------------------------------------------------------------------

// GDWM
$color-aqua-sidebar: #043e42;
$color-assets-accounts: #3b9fa5;
$color-assets-equity-compensation: #99dadf;
$color-assets-private-business: #68b3b7;
$color-assets-investment-property: #0a7a82;
$color-assets-future-inflows: #66aa93;
$color-assets-personal: #4a8b67;
$color-assets-life-insurance: #a6d8be;
$color-goals-core-lifestyle: #449689;
$color-goals-discretionary: #d99131;
$color-goals-philanthropic: #f1b040;
$color-goals-family: #ffc24b;
$color-goals-tax-liabilities: #ffdf77;
$color-sufficiency-positive: #7de0e6;
$color-sufficiency-negative: #fcd76e;
$color-risk-assets: #fabe0f;
$color-risk-control: #00a0aa;

// Primary
$color-nt-primary-green: #115740;
$color-nt-primary-gold: #ae9132;
$color-nt-primary-aqua: #0a7a82;
$color-nt-text: #3d4042;
$color-nt-page-background: #eaeaea;
$color-nt-container-background: #fff;

// Secondary
$color-nt-link: #2d8031;
$color-nt-link-hover: #125d15;
$color-nt-disabled-state: #b1b1b1;
$color-nt-borders: #cecece;
$color-nt-border-lines: #eaeaea;


// NT Green
$color-nt-green-100: #dfe8e5;
$color-nt-green-200: #bdd0c9;
$color-nt-green-300: #9ab8ae;
$color-nt-green-400: #78a093;
$color-nt-green-500: #568777;
$color-nt-green-600: #336f5c;
$color-nt-green-700: #115740;
$color-nt-green-800: #0d4936;
$color-nt-green-900: #093b2b;

// NT Gold
$color-nt-gold-100: #f2ebd7;
$color-nt-gold-200: #e1d4ae;
$color-nt-gold-300: #d0be85;
$color-nt-gold-400: #bfa85b;
$color-nt-gold-500: #ae9132;
$color-nt-gold-600: #a8872a;
$color-nt-gold-700: #a27d23;
$color-nt-gold-800: #9c731c;
$color-nt-gold-900: #966914;

// NT Grey
$color-nt-grey-025: #fafafa;
$color-nt-grey-050: #f6f6f6;
$color-nt-grey-100: #eaeaea;
$color-nt-grey-200: #cecece;
$color-nt-grey-300: #b1b1b1;
$color-nt-grey-400: #949494;
$color-nt-grey-500: #808182;
$color-nt-grey-550: #767676;
$color-nt-grey-600: #6b6e6f;
$color-nt-grey-700: #3d4042;
$color-nt-grey-800: #4a4d4f;
$color-nt-grey-900: #3d4042;

// NT Warm Grey
$color-nt-warm-grey-100: #f7f6f3;
$color-nt-warm-grey-200: #efede7;
$color-nt-warm-grey-300: #e7e4dc;
$color-nt-warm-grey-400: #dedbd0;
$color-nt-warm-grey-500: #d6d2c4;
$color-nt-warm-grey-600: #cbc6b3;
$color-nt-warm-grey-700: #c0b9a2;
$color-nt-warm-grey-800: #b5ad91;
$color-nt-warm-grey-900: #aaa080;

// Dark Green
$color-dark-green-100: #e2fce3;
$color-dark-green-200: #b8e1ba;
$color-dark-green-300: #8ec791;
$color-dark-green-400: #64ac68;
$color-dark-green-500: #3a913f;
$color-dark-green-600: #2d8031;
$color-dark-green-700: #206f23;
$color-dark-green-800: #125d15;
$color-dark-green-900: #054c07;

// Green
$color-green-100: #e6f7c4;
$color-green-200: #c6e396;
$color-green-300: #a5cf68;
$color-green-400: #85bb39;
$color-green-500: #64a70b;
$color-green-600: #5d970c;
$color-green-700: #56870e;
$color-green-800: #4f760f;
$color-green-900: #486610;

// Light Green
$color-light-green-100: #fcfcc5;
$color-light-green-200: #eaec94;
$color-light-green-300: #d9dd63;
$color-light-green-400: #c7cd31;
$color-light-green-500: #b5bd00;
$color-light-green-600: #a4aa03;
$color-light-green-700: #949807;
$color-light-green-800: #83850a;
$color-light-green-900: #72720d;

// Dark Blue
$color-dark-blue-100: #cbeafa;
$color-dark-blue-200: #86d2f9;
$color-dark-blue-300: #4ab0e5;
$color-dark-blue-400: #2e8fc2;
$color-dark-blue-500: #0a699b;
$color-dark-blue-600: #165f86;
$color-dark-blue-700: #1c5472;
$color-dark-blue-800: #104866;
$color-dark-blue-900: #063d5b;

// Aqua
$color-aqua-100: #e1fcff;
$color-aqua-200: #a2eaf4;
$color-aqua-300: #68d2d8;
$color-aqua-400: #36b4bc;
$color-aqua-500: #00a0aa;
$color-aqua-600: #088b94;
$color-aqua-700: #0a7a82;
$color-aqua-800: #05676e;
$color-aqua-900: #085459;
$color-aqua-950: #283a3c;

// Teal
$color-teal-100: #e9fff9;
$color-teal-200: #d0f4eb;
$color-teal-300: #b5e3d8;
$color-teal-400: #8ad2c6;
$color-teal-500: #64c8af;
$color-teal-600: #49b69b;
$color-teal-700: #37a085;
$color-teal-800: #1f8a6f;
$color-teal-900: #12745b;

// Purple
$color-purple-100: #ffeefd;
$color-purple-200: #e3c1de;
$color-purple-300: #c489bb;
$color-purple-400: #ac5d9f;
$color-purple-500: #8e3a80;
$color-purple-600: #813275;
$color-purple-700: #752a6a;
$color-purple-800: #68215e;
$color-purple-900: #5b1953;

// Red
$color-red-100: #ffe8e6;
$color-red-200: #f7c2bb;
$color-red-300: #f57d69;
$color-red-400: #f5634d;
$color-red-500: #e04e39;
$color-red-600: #d04532;
$color-red-700: #bf3b2a;
$color-red-800: #aa2d28;
$color-red-900: #941a0d;

// Orange
$color-orange-100: #ffeede;
$color-orange-200: #f9cfa7;
$color-orange-300: #fba755;
$color-orange-400: #fa9619;
$color-orange-500: #e97c05;
$color-orange-600: #d77200;
$color-orange-700: #bf5e00;
$color-orange-800: #ac5300;
$color-orange-900: #994900;

// Yellow
$color-yellow-100: #fff4c0;
$color-yellow-200: #ffdf84;
$color-yellow-300: #fcd76e;
$color-yellow-400: #fdcf4b;
$color-yellow-500: #fabe0f;
$color-yellow-600: #e5ad08;
$color-yellow-700: #d09d07;
$color-yellow-800: #b98a00;
$color-yellow-900: #a0761c;

// BLACK
$color-black: #000000;