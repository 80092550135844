.audio-video-controls-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 501px;
  margin: 0 auto;

  .video-preview {
    height: 283px;
    width: 501px;
    background-color: #E3E3E3;
  }

  .video-controls {
    display: flex;
    align-items: center;
    margin-top: 4px;
    height: 65px;
    width: 501px;
    background-color: #E3E3E3;

    .control-button {
      display: flex;
      align-items: center;
    }

    .video-cam-icon {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .audio-controls {
    display: flex;
    align-items: center;
    margin-top: 4px;
    height: 65px;
    width: 501px;
    background-color: #E3E3E3;

    .volume-up-icon {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .audio-video-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 4px;

    .join-now {
      margin-left: 10px;
    }
  }
}