$color-aqua-200: #a2eaf4;
$color-assets-personal: #4a8b67;
$color-white: #fff;

.bar-chart-active {
  background: repeating-linear-gradient(
                  -45deg,
                  $color-white,
                  $color-white 2px,
                  $color-assets-personal 1px,
                  $color-assets-personal 10px
  );
}

.bar-chart-summary-table {
  display: flex;
  flex-direction: column;

  .bar-chart-summary-lifestyle-present-value {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 500;

    &:first-child {
      font-size: 15px;
      line-height: 20px;
      padding-top: 4px;
      border-top: 1px $color-white solid;
    }
  }

  .bar-chart-summary-table-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;

    & > div > * {
      vertical-align: middle;
    }

    &:first-child {
      font-size: 15px;
      line-height: 20px;
      padding-bottom: 4px;
      border-bottom: 1px $color-white solid;
    }
  }

  .bar-chart-summary-table-row-icon {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    display: inline-block;
  }

  .bar-chart-summary-table-row__highlight * {
    color: $color-aqua-200;
  }
}
