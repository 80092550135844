@import "../abstracts/colors";

$funding-view-default: (
    name: "funding-view-default",
    grid-template-columns: auto repeat(2, minmax(4ch, 16ch)) minmax(4ch, 16ch) 32px,
    accordion-header-height: auto,
    total-label-column-span: span 3
);

$funding-view-with-inflows-or-non-investable: (
    name: "funding-view-with-inflows-or-non-investable",
    grid-template-columns: minmax(25%, 40%) repeat(3, minmax(4ch, 16ch)) minmax(9ch, 20ch) minmax(4ch, 16ch) 32px,
    accordion-header-height: 40px,
    total-label-column-span: span 5
);

$funding-view-with-inflows-and-non-investable: (
    name: "funding-view-with-inflows-and-non-investable",
    grid-template-columns: minmax(20%, 30%) repeat(3, minmax(4ch, 16ch)) repeat(2, minmax(9ch, 20ch)) minmax(4ch, 16ch) 32px,
    accordion-header-height: 40px,
    total-label-column-span: span 6
);

@mixin funding-grid-make($views-map) {
  @for $index from 1 through length($views-map) {
    $current-view: nth($views-map, $index);

    &.#{map-get($current-view, name)} {
      .funding-grid {
        display: grid;
        grid-column-gap: 16px;
        grid-template-columns: #{map-get($current-view, grid-template-columns)};

        .total-label {
            padding-left: 44px;
            grid-column: #{map-get($current-view, total-label-column-span)};
        }
      }

      .funding-accordion-header {
        height: #{map-get($current-view, accordion-header-height)}
      }
    }
  }
}

.alert-pr-activated {
    font-size: 13px;
    font-weight: 500;
    margin: 13px 18px -14px 49px;
    width: 1250px;
}

.funding-page {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 18px;
    background-color: $color-nt-grey-025;

    .highcharts-legend-item {
        pointer-events: none;
    }

    .no-goal-funding-placeholder {
        background-color: transparent;
        max-width: 100%;
        margin: 0 auto;
    }

    &__body {
        width: 100%;
        height: 100%;
        display: flex;
    }

    .funding-charts-section {
        display: block;
        height: 360px;
    }

    .excess-shortfall-label {
        background-color: transparent;
        padding: 7px 0 7px 0;
        margin: -49px 0 16px 49px !important;
        z-index: 3;

        .info-icon {
            font-size: 15px;
            margin-right: 5px;
            margin-bottom: 3px;
        }

        .subhead {
            font-size: 15px;
            font-weight: 500;
            font-family: "Roboto", sans-serif;
            color: #BF5E00;
        }
    }

    .highcharts-background {
        background-color: $color-nt-grey-025;
    }

    .highcharts-tooltip > span {
        text-align: center;
        font-size: 12px;
        background-color: #FFFFFF;
        z-index: 999 !important;
    }

    .funding-page-action-menu__dropdown {
        width: 240px;
        border: 1px;
        padding: 9px 1px 9px 1px;
    }

    .funding-chart-plot-line {
        font-family: "Roboto", sans-serif;
        background-color: white;
        color: #085459;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        border-radius: 3px;
        min-height: 27px;
        padding: 8px 16px;
        box-shadow: -5px 5px 7px 2px black;
    }

    .funding-page-action-menu-options {
        display: flex;
        flex-wrap: wrap;
        white-space: inherit;
        padding: 0px 8px 0px 8px;
    }

    .display-option-container {
        display: flex;
        padding: 2px 0px 2px 0px;

        .display-option-checkbox {
            padding: 0;
            margin-left: 0;
            margin-right: 0.5em;
            vertical-align: inherit;
            position: relative;
            top: -2;
        }
        .display-option-label {
            margin-left: 0.5em;
        }
    }

    .funding-menu-divider {
        padding: 0;
        margin: 8px -16px;
        border-bottom: 1px solid $color-nt-grey-200;
    }

    .funding-page__age-input-container {
        display: flex;
        align-items: center;
        padding-left: 48px;
    }

    .years-input-container-with-icons {
        position: relative;
    }

    .input__icon {
        color: #05676E;
        position: absolute;

        &__increase-support-button {
            top: 45%;
            left: 5%;
        }

        &__decrease-support-button {
            top: 85%;
            left: 5%;
        }
    }

    &__age-input {
        width: 100%;
        height: 100%;
    }

    #fundingAtAge {
        background-color: #ffffff;
        border-radius: 6px;
        width: 150px;
        height: 70px;
        font-size: 30px;
        font-weight: 300;
    }

    input:focus {
        outline: none !important;
        border: 0 ! important;
    }

    .input-inline-label {
        height: 100%;
        padding-left: 30%;
        font-size: 18px;
    }

    .funding-summary {
        margin: 16px 48px;
    
        @include funding-grid-make($funding-view-default $funding-view-with-inflows-or-non-investable $funding-view-with-inflows-and-non-investable);
    
        &__totals {
            display: flex;
            margin-bottom: 24px;
            font-size: 15px;
    
            .funding-donut-chart {
                display: flex;
                width: 44%;
                padding: 24px;
                background-color: white;
                border: 1px solid $color-nt-grey-100;
                border-radius: 5px;
    
                .funding-donut-container {
                    position: relative;
                    height: 166px;
                    width: 166px;
    
                    .funding-donut {
                        width: 150px;
                        height: 150px;
                    }
    
                    .funding-donut-shaded {
                        width: 166px;
                        height: 166px;
                    }
                
                    &__solid {
                        position: absolute;
                        top: 8px;
                        left: 8px;
                    }
                
                    &__shaded {
                        position: absolute;
                    }
                }
    
                .funding-donut-legend-table {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    margin: auto 0 auto 24px;
                    width: 300px;
    
                    .funding-donut-legend-item {
                        display: grid;
                        grid-template-columns: 24px minmax(112px, auto) 6ch minmax(12ch, 16ch);
                        gap: 8px;
                        height: 24px;
    
                        &__nested {
                            font-size: 12px;
                            color: $color-nt-grey-550;
                            margin-bottom: 16px;
                        }
                    }
    
                    .funding-legend-nested-connector {
                        position: absolute;
                        border-left: 2px solid $color-nt-grey-100;
                        border-bottom: 2px solid $color-nt-grey-100;
                        width: 19px;
                        height: 19px;
                        border-bottom-left-radius: 6px;
                        top: 16px;
                        left: 13px;
                    }
                }
            }
    
            .goals-funded-by-category-table {
                width: calc(56% - 24px);
                margin-left: 24px;
                background-color: white;
                padding: 24px;
                border: 1px solid $color-nt-grey-100;
                border-radius: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                &__row {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    line-height: 18px;
                    gap: 8px;

                    &__currency {
                        font-size: 24px;
                        font-weight: 300;
                        line-height: 30px;
                    }
                }

                hr {
                    width: 1px;
                    height: 64px;
                    margin: 24px 0;
                    background-color: $color-nt-border-lines;
                }
            }

            .shortfall-label {
                font-size: 12px;
                color: #BF5E00;
                margin-left: 16px;
            }
        }
    
        &__tables {
            background-color: white;
            border: 1px solid $color-nt-grey-100;
            border-radius: 5px;
            padding: 24px;

            .shortfall {
                color: #BF5E00;
            }

            .accordion-header-content {
                height: 64px;
                background-color: #fff;
                align-items: center;
                border-radius: 5px;
                line-height: 24px;

                &__achieved-and-expanded {
                    background-color: #D0F4EA;
                }
            }
        
            .funding-accordion-header {
                align-items: center;
                line-height: 24px;
                margin-bottom: 12px;
            }
        
            .row-divider {
                @include nt-border-bottom;
            }
        
            .funding-table-row {
                height: 48px;
                align-items: center;
                line-height: 20px;

                .icon {
                    font-size: 14px;
                }
            }

            &__total {
                height: 48px;
                align-items: center;
            }
        }
    }

    .goal-funding-achieved-column {
        margin-right: -8px;

        &__text {
            font-size: 15px;
            font-style: italic;
            color: #767676;
        }

        &__text-header {
            font-size: 18px;
            color: #767676;
        }

        .goal-funding-achieved-popover {
            display: flex;
            flex-direction: column;
            width: 300px;

            &__title {
                font-style: italic;
                font-size: 15px;
                height: 34px;
                line-height: 20px;
                align-self: flex-start;
            }

            &__present-value {
                line-height: 32px;
                font-weight: 500;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .tax-liability-popover-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: 40px;
        line-height: 32px;
        font-size: 15px;
    }
}