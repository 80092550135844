.ownership-details-section {
  .ownership {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 16px;
  }

  .legal-entity-ownership {
    padding: 0 16px;

    button.delete {
      margin-top: 5px;
      margin-left: 0;
      padding-left: 0;

      i {
        color: $color-nt-text;
        border-bottom: none;
        font-size: 18px;
      }
    }
  }

  .legal-entity-ownership-table-row {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  .legal-entity-ownership-table-sub-row {

    .input-wrapper {
      width: unset;
    }

    .checkbox {
      margin: unset;
    }
  }



  .legal-entity-ownership-table-sub-row:nth-child(2) {
    justify-content: space-between;
    margin-left: 6px;
    margin-top: 14px;

    .checkbox {
      height: 20px;
    }

  }

  .legal-entity-ownership-table {
    width: 100%;

    > * {
      border-bottom: solid 1px #CECECE;
    }

    .legal-entity-ownership-owned-col {
      text-align: right;
    }

    > :first-child {
      font-size: 13px;
      font-family: 'RobotoCondensed', 'Roboto', sans-serif;
      padding-bottom: 8px;
      padding-top: 12px;
    }

    [role="row"] {
      display: grid;
      grid-template-columns: minmax(210px, 1.3fr) 1fr 0.4fr 25px;
      column-gap: 10px;

      .total-owned-container {
        grid-column: 2/5;
        padding-right: 8px;
        text-align: right;
      }
    }
  }

  .grid-span-2 {
    grid-column: span 2;
  }

  .ownership-table {
    width: 100%;

    > :first-child {
      font-size: 13px;
      font-family: 'RobotoCondensed', 'Roboto', sans-serif;
      border-bottom: solid 1px #CECECE;
      padding-bottom: 12px;
      padding-top: 12px;
    }

    > [role="5-column-row"] {
      display: grid;
      grid-template-columns: 2fr 0.5fr 0.9fr 1fr 1.2fr;
      column-gap: 10px;
    }

    > [role="4-column-row"] {
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 1fr;
      column-gap: 10px;
    }


    input[type="text"] {
      width: 72px;
    }

    .ownership-table-row {
      height: 48px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-bottom: 1px #CECECE solid;

      > div {
        margin: auto 0;
      }

      > div.ownership-table-checkbox-field {
        margin: -8px auto 0;
      }
    }

    .right-align {
      justify-content: flex-end;
      text-align: right;
    }

    .center-align {
      justify-content: center;
      text-align: center;
    }
  }

  .layout-data-entry-form__field {
    #ownershipDropdown {
      .dropdown__item {
        font-size: 15px;
      }
    }
  }

  .percentInput input {
    text-align: right;
  }

  .non-client-individual-ownership {
    padding: 0 16px;

    button.delete {
      margin-top: 5px;
      margin-left: 0;
      padding-left: 0;

      i {
        color: $color-nt-text;
        border-bottom: none;
        font-size: 18px;
      }
    }

    .data-form-table {
      //padding-bottom: 8px;
      border-bottom: solid 1px #CECECE;

      .data-form-table-header__placeholder {
        border-bottom: solid 1px #CECECE;
      }

      .data-form-table-header {
        padding-left: 0;
        border-bottom: solid 1px #CECECE;
      }

      .data-form-table-row {
        border-bottom: none;
        padding-left: 0;
      }

      .with-extra-padding {
        padding-top: $spacing-12;
        padding-bottom: $spacing-12;
      }
    }
  }
}

.sub-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn--small {
    padding: 0;
  }
}

.disable-text{
  opacity: 70%;
  color: #696767;
}

.error-text{
  color: #d04532;
}
.ownership-icon{
  color: #00a0aa;
}
.ownership-dropdown-item{
  white-space: pre-wrap;
}