.asset-reliance-chart-container {
  height: 248px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;

  .asset-reliance-barchart-row {
    display: flex;
    flex-flow: nowrap !important;

    &.barchart-row-underlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
    }

    .barchart-container {
      height: 100%;

      .stacked-bar-chart {
        display: flex;
        justify-content: center;
        height: 100%;
        flex-grow: 0;
        margin: 0 12px;
      }
    }
  }
}

.asset-reliance-barchart-legend-label {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 18px;
}