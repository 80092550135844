.page-unavailable {
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 40px;
    /* identical to box height, or 111% */

    text-align: center;

    /* Aqua / 900 */

    color: #085459;
  }

  span {
    width: 400px;
    height: 90px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    /* or 125% */

    text-align: center;

    /* Grey / 900 */

    color: #3D4042;

  }

}