@import "../abstracts/colors";

.investment-programs {

  .investment-program-pagination {
    button {
      min-width: 32px;
    }

    .pagination__input {
      display: block;

      .page__input {
        width: 45px;
      }
    }

    .page__item-skin--active.btn-skin--secondary {
      background-color: $color-aqua-800;
      color: white;
      border-color: $color-aqua-800;
    }
  }

  .investment-programs-container {
    padding: 0 48px 24px 48px;

    .investment-program-name {
      font-size: 13px;
      font-weight: 400;
      width: 240px;
    }

    .investment-program-portfolio-manager {
      width: 170px;
    }

    .cell-text-content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

   .alert-skin--error {
      margin-bottom: 24px;
      font-size: 13px;
   }

    .warning-message {
      grid-column: 1 / -1;

      .alert__actions {
        align-self: center;
        margin: -8px 0 -10px 0;
      }

      .btn-skin--borderless:not(.btn__icon-only) {
        color: #3D4042;
      }

      .btn-skin--borderless {
        border: none;
      }
    }

    .tab__item-skin {
      font-size: 12px;
      text-transform: uppercase;
    }

    .accordion__item--expanded {
      background-color: $color-nt-grey-025;

      .investment-program-name {
        font-weight: 500;
      }
    }

    .accordion__button {
      cursor: pointer;
    }

    .investment-program-header-grid {
      display: grid;
      grid-template-columns: 12fr 8fr 5fr 5fr 5fr;
      grid-column-gap: 16px;
      line-height: 40px;
    }

    .investment-program-header {
      font-weight: 500;
      border-top: 1px solid $color-nt-grey-200;
      border-bottom: 1px solid $color-nt-grey-400;
    }

    .investment-program-accordion-header {
      .investment-program-header-column {
        font-size: 13px;
        font-weight: 400;
      }
    }

    .investment-program-expanded-view {
      font-size: 13px;
      font-weight: 400;
      border-bottom: 1px solid $color-nt-grey-200;

      .investment-program-tables {
        margin: 0 24px;

        .investment-program-table {
          margin-top: 24px;

          .investment-program-grid {
            display: grid;
            grid-template-columns: 10fr 5fr 8fr 4fr 5fr;
            grid-column-gap: 15px;
          }

          .heldAwayInstitution {
            line-height: 20px;
            margin: 10px 0px 10px 24px;
          }

          .truncate {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .investment-program-table-header {
            line-height: 40px;
            font-size: 13px;
            font-weight: 500;
            border-bottom: 1px solid $color-nt-grey-200;
          }

          .investment-program-table-row {
            line-height: 40px;
            font-size: 13px;
            font-weight: 400;
            border-bottom: 1px solid $color-nt-grey-100;

            &:hover,
            &:focus {
              background-color: $color-aqua-100;
            }

            &:focus-visible {
              outline: none;
            }
          }
        }
      }

      .table-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .total-accounts-label {
          font-weight: bold;

          .total-accounts-value {
            font-size: 18px;
            font-weight: normal;
            margin: 12px;
          }
        }
      }
    }

    .total-investment-programs-count {
      background-color: #FAFAFA;
      font-size: 13px;
      font-weight: 500;
      padding: 10px 0 10px 34px;
      border-bottom: 1px solid #cdcdcd;
    }

    .search-bar-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      gap: 16px;
    }

    #search-input {
      flex-grow: 1;
    }
  }

  .add-investment-program-dialog-grid {
    display: grid;
    grid-template-columns: 10fr 4fr 4fr;
    grid-column-gap: 18px;
    line-height: 32px;
  }

  .btn-skin--borderless {
    border: transparent;
  }

  .alert__content {
    .marginright-lg {
      margin-right: 5px;
    }
  }

  .empty-state-container {

    &.no-investment-programs-placeholder,
    &.no-search-result-placeholder {
      margin-top: 112px;
      text-align: center;
      box-shadow: none;
    }
  }
}