.data-entry-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: $spacing-xl $spacing-2xl;
  background-color: white;

  &--sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transform: translateZ(0);
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-right: $spacing-lg;
  }

  &__buttons {
    white-space: nowrap;
    .refresh-button{
      padding-left: 3px;
    }
  }
}
