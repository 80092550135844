.family-goal-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 120px;

  section:not(:first-child) {
    margin-top: 36px;
  }

  > section {
    margin-bottom: 0 !important;

    > div {
      padding: 0 40px 0 20px;

      > label {
        font-weight: 500;
        margin-top: 12px;
      }
    }
  }

  .checkbox {
    display: inherit;
  }
}

.family-goal-details-table {
  display: grid;
  grid-template-columns: 33% 67%;
  gap: 10px 20px;

  > .beneficiary-label {
    margin-top: 12px !important;
    margin-bottom: 6px !important;
  }


  > .drop-wrapper {
    width: 100%;
    height: 40px;
  }

  #goalTypeInput {
    .dropdown__item {
      font-size: 15px;
      width: 100%;
      height: 40px;
    }
  }

  .input-wrapper {
    width: 100%;
  }
}

.family-goal-time-frame-table {
  display: grid;
  grid-template-columns: 33% 67%;
  gap: 10px 20px;

  .family-goal-date-cell {
    display: flex;
    margin-top: 12px;
    margin-left: 32px;
    width: 100%;
    justify-content: space-between;
  }

}

.family-goal-glide-path-table {
  display: grid;
  grid-template-columns: 31% 11% 50%;
  gap: 10px 20px;

  .percent-field {
    width: fit-content;
  }
}

.family-grid-table {
  height: 40px;
  display: grid;
  grid-template-columns: 618px 150px 30px;
  gap: 10px 20px;
  font-size: 15px;

  .beneficiaryGoalsActionMenu {
    margin-top: 3px;
  }

  > label {
    font-weight: 500;
  }

  > span {
    margin-top: 12px;
    margin-bottom: 12px;
  }

}

.apply-family-asset-modal {
  font-size: 13px;
  width: 300px;
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.total-asset-error-message {
    font-size: 13px;
    align-items: center;
    display: flex;
}

.total-asset-input {
    width: 100% !important;
    padding-top: 8px;
}