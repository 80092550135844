@import '../abstracts/colors';

ul#addCustomProduct_Menu {
  h1 {
    font-weight: bold;
    font-size: 1.2em;
    padding-left: $spacing-lg;
  }

  li {
    &.menu__item {
      padding-left: $spacing-lg;
    }
  }
}

.holdings-container {
  .add-product {
    margin-top: 35px;
    margin-bottom: 0 !important;
    margin-left: 48px;
    margin-right: 48px;

    display: flex;
    gap: 10px;

    .typeahead {
      flex-grow: 4;

      .dropdown__panel {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        width: 1050px;
      }
    }

    .search__input {
      i {
        &.input__icon {
          margin-top: -34px;
        }
      }
    }

    .btn--medium {
      height: 48px;
      margin-left: 10px;
    }

    .product-types {
      .dropdown__label {
        width: 226px;
      }
      .dropdown__panel {
        width: 226px;
        overflow: hidden;
      }
    }

    .product-search {
      display: flex;
      width: 100%;
      .dropdown {
        width: 232px !important;
      }
    }
  }

  .accented-accordion-item {
    margin-bottom: 0;
    border-radius: 0;

    .accordion-header-content {
      height: 64px;
      background-color: #fff;
      align-items: center;
      border-radius: 5px;
    }

    .accordion__button {
      border-radius: 0;
    }

    &:not(:first-child) {
      .accented-accordion-item__accent {
        border-top-left-radius: 0;
      }
    }

    &:not(:last-child) {
      .accented-accordion-item__accent {
        border-bottom-left-radius: 0;
      }
    }
  }

  .empty-grid-row {
    min-height: 12px;
  }

  .empty-grid-column {
    min-width: 24px;
  }

  .grid-display-contents {
    display: contents;
  }

  .no-value {
    color: $color-nt-grey-300;
  }

  .line {
    grid-column: 1 / -1;
    height: 1px;
    background: $color-nt-grey-100;
  }

  .holdings-info-container {
    background-color: $color-nt-grey-050;
    margin: 8px 48px;
    display: grid;
    grid-template-columns: 40% 60%;
  }

  .holdings-info-chart {
    display: grid;
    grid-template-columns: 72px 16ch auto;
    padding: 24px 32px;
    row-gap: 8px;
    column-gap: 16px;

    .account-info {
      margin-bottom: 24px;
      white-space: pre-wrap;
    }

    .risk-legend {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin: 4px 8px;
    }
  }

  .holdings-by-liquidity-category {
    padding-left: 166px;

    &__table {
      padding: 24px 32px;
      font-size: 13px;
      font-weight: 400;

      &__header {
        text-transform: uppercase;
        line-height: 15px;
        font-family: RobotoCondensed, Roboto, sans-serif;
      }

      &__data {
        line-height: 18px;
      }

      &__row {
        display: grid;
        grid-template-columns: 40% 30% 30%;
        padding-bottom: 4px;

        span:not(:first-child) {
          text-align: right;
        }
      }
    }
  }

  .holdings-footer {
    min-height: 48px;
    grid-column: 1/-1;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 12px;

    .asset-classification-total {
      font-size: 15px;
      font-weight: 500;
      text-align: right;
    }
  }

  &__edit-subclass {

    .edit-subclass-grid,
    .holding-grid {
      display: grid;
      grid-template-columns: 10.75fr repeat(3, 3fr) 0.25fr 0.25fr;
      grid-column-gap: 16px;
    }

    .holding-grid-column-custom-span {
      grid-column: span 1;
    }

    .holding-grid-container {
      padding: 24px 48px;

      .holding-asset-classification {
        .holding-grid-accordion-item {
          grid-column: 1 / -1; // Span through columns first to last

          .holding-subclass-row {
            line-height: 40px;
            font-weight: 700;
            border-bottom: 1px solid #cecece;

            .holding-subclass-name {
              padding-left: 64px;
              font-size: 13px;
            }
          }
        }

        .asset-class-total {
          font-size: 15px;
          font-weight: 500;
        }

        .input-wrapper {
          width: 100%;
          max-width: max-content;
          float: right;

          input[type="text"] {
            text-align: right;
          }
        }

        .holding-subclass-detail-row {

          &:hover,
          &:focus {
            background-color: #e1fcff;
          }

          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }

  &__holdings-summary {
    .holdings-subtitle {
      color: $color-nt-grey-550;
      white-space: pre-wrap;
    }

    .holding-grid {
      display: grid;
      grid-template-columns: 6fr 5fr repeat(3, 2.5fr) 1fr 1fr 1fr;
      grid-column-gap: 16px;
    }

    .holding-grid-column-custom-span {
      grid-column: span 2;
    }

    .holding-grid-container {
      padding: 24px 48px;

      .holding-asset-classification {
        .holding-grid-accordion-item {
          grid-column: 1 / -1; // Span through columns first to last

          .holding-subclass-row {
            line-height: 40px;
            font-weight: 700;

            .holding-subclass-name {
              padding-left: 32px;
            }
          }

          .holding-subclass-detail-row {
            line-height: 40px;
            border-top: 1px solid #eaeaea;

            .holding-subclass-detail-name {
              padding-left: 64px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .product-name {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .delete-holding-button {
              border: none;
            }
          }
        }
      }
    }

    .checkbox {
      display: block;
      padding: 5px 0 0 7px;
      align-self: center;

      input {
        margin-top: 5px;
      }
    }
  }

  .holding-scrollable-wrapper {
    grid-column: span 8;
  }
}