.card-container {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  padding: $spacing-xl;
  margin: $spacing-xl;
}

.page-container {
  background-color: white;
  padding: $spacing-xl;
  margin: $spacing-xl;
  min-height: 560px;

  @include nt-border-bottom;
}

.sticky-container {
  position: sticky;
  top: 0;
  width: 100%;
}

.scrollable-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
