.modal-wrapper-container {
  .button--destructive {
    &.btn-skin--primary {
      background-color: $color-red-700;

      &:focus {
        box-shadow: 0 0 0 2px $color-red-500;
      }

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        background-color: $color-red-900;
      }
    }

    &.btn-skin--secondary {
      border-color: $color-red-900;

      &:focus {
        box-shadow: 0 0 0 2px $color-red-500;
        background-color: $color-red-900;
      }

      &:active,
      &:hover:not([disabled]) {
        background-color: $color-red-900;
        border-color: $color-red-900;
      }

      &:hover:disabled,
      &:not(:active):not(:focus):not(:hover):not(.btn__icon-only) {
        color: $color-red-900;
      }
    }

    &.btn-skin--borderless {
      &:not(.btn__icon-only) {
        color: $color-red-800;
      }

      &:focus {
        box-shadow: 0 0 0 2px $color-red-500;
      }

      &:active,
      &:focus,
      &:hover:not([disabled]),
      &:hover:disabled {
        color: $color-red-800;
      }
    }
  }
}

.ReactModalPortal .ReactModal__Overlay {
  .modal__body {
    padding: 0 $spacing-xxxl $spacing-xxxl !important;

    .secondary-text {
      color: #767676;
    }
  }

  div.modal__body:has(div.no-content) {
    display: none;
  }

  .modal__footer {
    padding: 0 $spacing-xxxl $spacing-xxl !important;
  }

  .modal__content--small {
    max-width: 540px !important;
  }

  .modal__overlay {
    z-index: 1002;
  }
}
