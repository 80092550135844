
.prioritization-page-report {
  display: flex;
  flex-direction: column;
  background-color: $color-nt-grey-050;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-size: 18px;

  &__body {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .activated {
    padding-left: 23px;
    padding-top: 30px;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: end;
  }
}

.goal-prioritization-cards {
  padding-top: 18px;
  display: flex;
  flex-direction: row;
  text-align: right;

  .goal-prioritization-card {
    align-items: center;
    background: #ffffff;
    border: 1px solid $color-nt-grey-100;
    border-radius: 5px;
    height: 128px;
    width: 623px;
    padding: 24px;
    line-height: 32px;

    &-header {
      font-weight: bold;
      line-height: 24px;
      text-align: left;
    }

    &-first-column {
      text-align: left;
    }
  }

  .asset-allocation-card {
    line-height: 32px;

    .current-and-proposed {
      font-family: RobotoCondensed, Roboto, sans-serif;
      font-size: 13px;
      line-height: 26px;
      letter-spacing: 0.5px;
    }
  }
}

.goal-prioritization-card-shortfall {
  align-items: center;
  background: #ffffff;
  border: 1px solid orange;
  border-radius: 5px;
  height: 128px;
  width: 623px;
  padding: 24px;
  line-height: 32px;
}

.goal-wants-container {
  padding: 24px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    line-height: 24px;
    padding: 0 0 8px 0;
    font-weight: 500;
  }

  .no-goals-warning {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $color-nt-grey-500;
  }

  .core-goal-list {
    padding: 0px 6px 0px;

    .accordion-tables {
      li {
        list-style: none;
        background-color: #ffffff;
      }

      padding-top: 8px;

      .ReactTable.table {
        border-color: transparent;

        .rt-thead .rt-tr {
          height: 36px !important;
        }

        .rt-td {
          border-color: transparent;
        }

        .rt-td:focus,
        .rt-td:hover {
          background-color: transparent !important;
        }

        .rt-th {
          border-color: #eaeaea !important;
        }

        .rt-th,
        .rt-resizable-header-content {
          font-family: RobotoCondensed, Roboto, sans-serif;
          color: #3d4042;
          font-size: 11px;
          font-weight: 400;
          font-stretch: condensed;
          text-transform: uppercase;
        }

        .rt-expandable .icon {
          position: relative;
          left: 4px;
        }

        .disabled-column {
          color: $color-nt-grey-400;
        }

        .table-label-13px,
        .table-label-15px,
        .table-sublabel-12px {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .table-label-13px {
          font-size: 13px;
          line-height: 18px;
        }

        .table-label-15px {
          font-size: 15px;
          line-height: 20px;
        }

        .table-sublabel-12px {
          font-size: 12px;
          line-height: 15px;
          color: $color-nt-grey-550;
        }
      }

      .underlined-header {
        padding-left: 24px;
        padding-right: 36px;
        border: none;
        margin-bottom: 0 !important;
      }

      .value-label {
        width: 190px;
        text-align: right;
      }
    }

    .page-action-menu-options {
      display: flex;
      justify-content: space-between;
    }

    input[name="discount-group"] {
      accent-color: #3D4042;
    }

    input[name="show-lifestyle-spending"] {
      accent-color: #3D4042;
    }
  }
}

.goal-prioritization-container {
  padding: 24px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    line-height: 24px;
    padding: 0 0 8px 0;
    font-weight: 500;
  }

  .title-continued {
    font-size: 18px;
    line-height: 24px;
    padding: 0 0 8px 0;
    padding-left: 3px;
  }

  .no-goals-warning {
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $color-nt-grey-500;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $spacing-2xxl;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    > span {
      display: flex;
      align-items: center;
    }
  }

  .core-goal-list {
    padding: 0px 6px 0px;

    .accordion-tables {
      li {
        list-style: none;
        background-color: #ffffff;
      }

      padding-top: 8px;

      .ReactTable.table {
        border-color: transparent;

        .rt-thead .rt-tr {
          height: 36px !important;
        }

        .rt-td {
          border-color: transparent;
        }

        .rt-td:focus,
        .rt-td:hover {
          background-color: transparent !important;
        }

        .rt-th {
          border-color: #eaeaea !important;
        }

        .rt-th,
        .rt-resizable-header-content {
          font-family: RobotoCondensed, Roboto, sans-serif;
          color: #3d4042;
          font-size: 11px;
          font-weight: 400;
          font-stretch: condensed;
          text-transform: uppercase;
        }

        .rt-expandable .icon {
          position: relative;
          left: 4px;
        }

        .disabled-column {
          color: $color-nt-grey-400;
        }

        .table-label-13px,
        .table-label-15px,
        .table-sublabel-12px {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .table-label-13px {
          font-size: 13px;
          line-height: 18px;
        }

        .table-label-15px {
          font-size: 15px;
          line-height: 20px;
        }

        .table-sublabel-12px {
          font-size: 12px;
          line-height: 15px;
          color: $color-nt-grey-550;
        }
      }

      .underlined-header {
        padding-left: 24px;
        padding-right: 36px;
        border: none;
        margin-bottom: 0 !important;
      }

      .value-label {
        width: 190px;
        text-align: right;
      }
    }

    .page-action-menu-options {
      display: flex;
      justify-content: space-between;
    }

    input[name="discount-group"] {
      accent-color: #3D4042;
    }

    input[name="show-lifestyle-spending"] {
      accent-color: #3D4042;
    }
  }
}

.goals-table-single-row {
  @extend .goals-table;
  .rt-tr:last-of-type {
    border-radius: 0 0 5px 5px;

    .rt-td:first-of-type {
      padding: 0;
      width: 10px;
      max-width: 10px;
      background-color: #86ACA5;
      border-radius: 5px 0 0 5px !important;
    }
  }
}

.goals-table {
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 10px;

  .ReactTable {
    border: 0 solid transparent;
    border-radius: 5px;
  }

  .ReactTable.table .rt-thead.-header .rt-th {
    border-bottom: 0 solid transparent;
    display: flex;
    align-items: center
  }

  .width-100p {
    border-radius: 20px;
    width: 100%;
    display: grid;
  }

  .ReactTable.table .rt-td, .ReactTable.table .rt-th, .ReactTable.table .rt-tr-group {
    border-color: rgba(206, 206, 206, 0.22) !important;
  }

  .rt-thead {
    background-color: transparent;
  }

  .rt-tbody {
    .rt-tr {
      background-color: #ffffff;
    }

    .rt-tr:first-of-type {
      border-radius: 5px 5px 0 0;

      .rt-td:first-of-type {
        padding: 0;
        width: 10px;
        max-width: 10px;
        background-color: #86ACA5;
        border-radius: 5px 0 0 0;
      }
    }

    .rt-tr:last-of-type {
      border-radius: 0 0 5px 5px;

      .rt-td:first-of-type {
        padding: 0;
        width: 10px;
        max-width: 10px;
        background-color: #86ACA5;
        border-radius: 0 0 0 5px;
      }
    }

    .rt-td:first-of-type {
      padding: 0;
      background-color: #86ACA5;
    }
  }


  .rt-th {
    font-family: RobotoCondensed, Roboto, sans-serif;
    color: #3d4042;
    font-size: 11px;
    font-weight: 400;
    font-stretch: condensed;
    text-transform: uppercase;
  }

  .table-label-13px,
  .table-label-15px,
  .table-sublabel-12px {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .table-label-13px {
    font-size: 13px;
    line-height: 18px;
  }

  .table-label-15px {
    font-size: 15px;
    line-height: 20px;
  }

  .table-sublabel-12px {
    font-size: 12px;
    line-height: 15px;
    color: $color-nt-grey-550;
  }
}

.goals-wants-table-single-row {
  @extend .goals-wants-table;
  .rt-tr:last-of-type {
    border-radius: 0 0 5px 5px;

    .rt-td:first-of-type {
      padding: 0;
      width: 10px;
      max-width: 10px;
      background-color: #C2D6D2;
      border-radius: 5px 0 0 5px !important;
    }
  }
}

.goals-wants-table {
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 10px;

  .ReactTable {
    border: 0 solid transparent;
    border-radius: 5px;
  }

  .ReactTable.table .rt-thead.-header .rt-th {
    border-bottom: 0 solid transparent;
    display: flex;
    align-items: center
  }

  .width-100p {
    border-radius: 20px;
    width: 100%;
    display: grid;
  }

  .ReactTable.table .rt-td, .ReactTable.table .rt-th, .ReactTable.table .rt-tr-group {
    border-color: rgba(206, 206, 206, 0.22) !important;
  }

  .rt-thead {
    background-color: transparent;
  }

  .rt-tbody {
    .rt-tr {
      background-color: #ffffff;
    }

    .rt-tr:first-of-type {
      border-radius: 5px 5px 0 0;

      .rt-td:first-of-type {
        padding: 0;
        width: 10px;
        max-width: 10px;
        background-color: #C2D6D2;
        border-radius: 5px 0 0 0;
      }
    }

    .rt-tr:last-of-type {
      border-radius: 0 0 5px 5px;

      .rt-td:first-of-type {
        padding: 0;
        width: 10px;
        max-width: 10px;
        background-color: #C2D6D2;
        border-radius: 0 0 0 5px;
      }
    }

    .rt-td:first-of-type {
      padding: 0;
      background-color: #C2D6D2;
    }
  }


  .rt-th {
    font-family: RobotoCondensed, Roboto, sans-serif;
    color: #3d4042;
    font-size: 11px;
    font-weight: 400;
    font-stretch: condensed;
    text-transform: uppercase;
  }

  .table-label-13px,
  .table-label-15px,
  .table-sublabel-12px {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .table-label-13px {
    font-size: 13px;
    line-height: 18px;
  }

  .table-label-15px {
    font-size: 15px;
    line-height: 20px;
  }

  .table-sublabel-12px {
    font-size: 12px;
    line-height: 15px;
    color: $color-nt-grey-550;
  }
}

.goals-fbni-table-single-row {
  @extend .goals-fbni-table;
  .rt-tr:last-of-type {
    border-radius: 0 0 5px 5px;

    .rt-td:first-of-type {
      padding: 0;
      width: 10px;
      max-width: 10px;
      background-color: #EAEAEA;
      border-radius: 5px 0 0 5px !important;
    }
  }
}

.goals-fbni-table {
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 10px;

  .ReactTable {
    border: 0 solid transparent;
    border-radius: 5px;
  }

  .ReactTable.table .rt-thead.-header .rt-th {
    border-bottom: 0 solid transparent;
    display: flex;
    align-items: center
  }

  .width-100p {
    border-radius: 20px;
    width: 100%;
    display: grid;
  }

  .ReactTable.table .rt-td, .ReactTable.table .rt-th, .ReactTable.table .rt-tr-group {
    border-color: rgba(206, 206, 206, 0.22) !important;
  }

  .rt-thead {
    background-color: transparent;
  }

  .rt-tbody {
    .rt-tr {
      background-color: #ffffff;
    }

    .rt-tr:first-of-type {
      border-radius: 5px 5px 0 0;

      .rt-td:first-of-type {
        padding: 0;
        width: 10px;
        max-width: 10px;
        background-color: #EAEAEA;
        border-radius: 5px 0 0 0;
      }
    }

    .rt-tr:last-of-type {
      border-radius: 0 0 5px 5px;

      .rt-td:first-of-type {
        padding: 0;
        width: 10px;
        max-width: 10px;
        background-color: #EAEAEA;
        border-radius: 0 0 0 5px;
      }
    }

    .rt-td:first-of-type {
      padding: 0;
      background-color: #EAEAEA;
    }
  }


  .rt-th {
    font-family: RobotoCondensed, Roboto, sans-serif;
    color: #3d4042;
    font-size: 11px;
    font-weight: 400;
    font-stretch: condensed;
    text-transform: uppercase;
  }

  .table-label-13px,
  .table-label-15px,
  .table-sublabel-12px {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .table-label-13px {
    font-size: 13px;
    line-height: 18px;
  }

  .table-label-15px {
    font-size: 15px;
    line-height: 20px;
  }

  .table-sublabel-12px {
    font-size: 12px;
    line-height: 15px;
    color: $color-nt-grey-550;
  }
}

.lifestyle-background-color {
  background-color: rgb(82, 137, 127) !important;
}




.progress-bar {
  display: flex;
  height:3px;
  width:120px;
  background-color: #DC7B04;
}

.filler{
  background:#52897F;
  height:13px !important;
  transition: width .2s ease-in;
}