.quick-slide-info-card {
  border: none;
  box-sizing: border-box;
  position: absolute;
  top: 200px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 20%);
  transform: translateY(0);
  z-index: 10;
  left: 25%;
  max-width: 50%;

  &__content {
    border: 1px solid #cecece;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding: 16px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    z-index: 10;
    background-color: #fff;

    img {
      &.disable-edge-visual-search {
        pointer-events: none;
      }
    }
  }

  &__header {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }

  &__lg {
    font-size: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }

  &__expanded {
    left: 15%;
    max-width: 70%;
    border: none;
    box-sizing: border-box;
    position: absolute;
    top: 150px;
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 20%);
    transform: translateY(0);
    z-index: 10;
  }
}
