.meeting-info-modal-scan-qr-instructions {
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 12px;
}

.meeting-info-modal-qr-code-box {
  width: 100%;
  height: 264px;
  border: 1px solid $color-nt-disabled-state;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meeting-info-modal-qr-code {
  width: 175px;
  height: 175px;
  background: #2d8031;
}

.meeting-info-modal-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 12px;
}

.meeting-info-modal-text-bold {
    font-weight: 500;
}

.copy-meeting-details {
  margin-top: 12px;
}