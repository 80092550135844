.planning-period {
  margin-top: 10px !important;
  > span {
    padding-left: 8px !important;
  }
}

.lifestyle-planning-period{
  margin-top: 5px !important;
  >span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
  }
}
