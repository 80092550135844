.asset-reliance {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.asset-reliance-popover {
  font-size: 15px;
  font-weight: 400;
}

.asset-reliance-label-for-popover {
  font-size: 15px !important;
  line-height: 20px !important;
  letter-spacing: 0px !important;
}

.asset-reliance-popover-text {
  padding-bottom: 8px;
}

.asset-reliance-popover-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 8px;
}

.table-row-container{
  .row {
    .col {
      overflow: visible;
    }
  }
  .table-row-title-container {
    .table-row-title {
      overflow: visible;
    }
  }
}