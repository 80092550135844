.family-tree-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .condensed-subtitle {
    margin-left: 3px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
  }

  .details-tab {
    margin-bottom: 240px;
    width: 481px;

    .input__field {
      border: 1px solid $color-nt-borders
    }

    input:disabled {
      background-color: #f6f6f6;
    }

    .input-skin--disabled {
      background-color: #f6f6f6;
    }
  }

  .inlineInputError {
    margin-left: 197px;
    margin-top: 4px;
  }

  .relationships-tab {
    margin-top: 32px;

    .input__field {
      border: 1px solid $color-nt-borders
    }

    input:disabled {
      background-color: #f6f6f6;
    }

    .input-skin--disabled {
      background-color: #f6f6f6;
    }
  }

  #planning_period_label {
    padding-right: 48px;
    position: absolute;
    text-align: right;
    width: 100%;
    pointer-events: none;
  }

  .other_members {
    position: absolute;
    flex-direction: row;
    bottom: 0;
    text-align: center;
    width: 100%;
    z-index: 4;
    height: 170px;

    .other-members-container {
      display: flex;
      height: 90%;
    }

    .other-member-diagram-component {
      height: 100% !important;
      width: 100% !important;
    }
  }

  .pan-and-zoom-container {
    position: absolute;

    .btn-skin--borderless {
      border: transparent;
    }
  }

  .family-tree-page__header {
    border-bottom: 1px solid #cdcdcd;
  }

  .familyTree {
    background-color: #f1f1f1;
  }

  .loader__wrapper {
    height: 100%;
  }

  .SingleDatePickerInput {
    height: 40px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .add-family-member {
    color: $color-nt-grey-700;
    background-color: white;
  }

  .add-family-member:hover {
    color: $color-aqua-950 !important;
    background-color: $color-aqua-100 !important;
  }

  .header {
    color: $color-nt-text;
    background-color: white;

    h1 {
      color: $color-nt-text;
    }
  }

  &__tree {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #f6f6f6;

    .tag-container {
      display: flex;
      margin-top: 28px;
    }

    .diagram-component {
      top: 2%;
      left: 4%;
      width: 92.5%;
      height: 87%;
      position: relative;
      border: transparent;
    }

    &__header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 32px 48px;
      z-index: 1;
      transform: translateZ(0);

      .cancel-bar {
        position: relative;
        background-color: #d0f4eb;
        border: 1px solid #00a0aa;
        padding: 10px 0;

        &__button {
          position: absolute;
          top: 5px;
          right: 0;

          button {
            color: #3d4042 !important;
          }
        }
      }
    }

    &__family-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__buttons {
        button {
          margin: 0 6px;
        }

        button:first-child {
          margin-left: 0;
        }

        button:last-child {
          margin-right: 0;
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 48px;
      z-index: 1;
      transform: translateZ(0);

      i {
        padding-bottom: 2.5px;
      }
    }

    .tree-graph {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      &__other {
        h4 {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 16px;
        }

        .tree-node__content {
          width: fit-content;
          height: 44px;
          padding: 12px 24px;
          margin: 12px;
        }
      }

      &__main,
      &__other {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__main {
        //margin-bottom: 108px;

        rect[id="primary"] {
          stroke: #37a085;
        }

        rect[id="secondary"] {
          stroke: #8ad2c6;
        }

        rect[id="Deceased"] {
          stroke: #cecece;
        }

        rect[id="Ex-Spouse"] {
          stroke: #8ad2c6;
          stroke-dasharray: 3 3;
        }
      }

      &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  &__list {
    margin: 16px 30px;
    background-color: white;

    &__section {
      padding: 10px 24px;
      padding-bottom: 16px;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .tabs__list {
    padding: 0 48px;

    button {
      display: inline-flex;
      flex-direction: row-reverse;
    }
  }

  #tree,
  #list {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
  }

  .menu-hide {
    display: none;
  }

  .label-margin {
    margin-top: 1%;
  }
}

.family-form-side-drawer {
  input#middleInitialInput,
  input#ageInput,
  input#personalPlanningHorizonInput {
    width: 88px;
  }

  .dropdown .dropdown__panel .dropdown__link {
    font-size: 15px;
    font-weight: 400;
  }

  .input-inline-label b {
    font-weight: 500;
    font-size: 13px;
  }

  .input-inline-label {
    font-size: 13px;
  }

  .percent-field b {
    font-weight: 800;
  }

  .marginright-lg.overflowx-hidden {
    font-size: 15px;
  }

  .age,
  .personalPlanningHorizon {
    .input-wrapper {
      position: relative;

      .input-inline-label {
        left: 40px;
      }
    }
  }

  .inline-alert {
    padding-left: 195px;
    padding-bottom: 15px;
    color: $color-orange-900;
    font-size: 12px;
    line-height: 14px;
  }

  .inline-alert-delete-goal {
    padding-left: 210px;
    padding-bottom: 15px;
    color: $color-orange-900;
    font-size: 12px;
    line-height: 14px;
  }

  .partner-relationship-alert {
    padding: 8px 32px 0px 196px;

    p {
      font-size: 13px;
      font-style: italic;
      margin-top: 0;
    }

    .info-icon {
      float: left;
      padding-bottom: 2px;
      padding-right: 8px;
      margin-bottom: 24px;
      color: $color-aqua-800;
    }
  }

  header {
    padding: 32px;

    .data-entry-header__title {
      width: 49%;
    }

    .data-entry-header__buttons {
      justify-content: flex-end;
      width: 51%;
      display: flex;
    }
  }

  .color-text--error {
    color: #d04532;
  }

  article {
    padding-left: 32px;
    padding-right: 32px;
  }

  .tabs__list {
    padding: 0;
    margin-bottom: 20px;
  }

  .tab__item-skin {
    font-size: 18px;
    letter-spacing: normal;
    text-transform: none;
  }

  .data-entry-header {
    align-items: flex-start;
  }

  .layout-data-entry-form__field {
    padding: 0;
    margin-top: 0;
  }

  .layout-data-entry-form__field .partner {
    .input-skin--disabled {
      background-color: white;
      color: #3d4042;
      opacity: 0.5;
    }
  }

  .layout-data-entry-form__field > label {
    margin-right: 48px;
    flex-basis: 33%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .layout-data-entry-form__field > :last-child {
    flex-basis: 0;
    flex-grow: 1;
  }

  .familyMemberFormField > :last-child {
    margin-left: 30px;
  }

  .familyMemberFormField > label {
    margin-right: 8px !important;
  }

  .input--width-small {
    width: 80px;
    max-width: 80px;
  }

  .input__field {
    width: 284px;
  }

  .btn-skin--borderless {
    border: none !important;
    color: #3d4042;
  }

  .lifeStatus > label {
    margin-right: 32px;
  }

  .lifeStatusField {
    margin-left: 0 !important;
    padding-left: 8px;
  }

  .relationships-view {
    > div {
      padding-bottom: 48px;
    }

    .parents {
      padding-top: 10px;
    }

    .children {
      .layout-data-entry-form__field {
        align-items: flex-start;
      }

      .layout-data-entry-form__field .checkboxgroup__label {
        margin-bottom: 0;
      }
      .input-field-div {
        display: flex;
        flex-direction: column;

        .input-field {
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          padding: 0 0 5px 0;
        }
      }
    }

    .header-container {
      border-bottom: 1px solid #eaeaea;
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 8px;
      margin-bottom: 8px;
    }

    .sub-text {
      color: #565a5c;
      padding-top: 10px;
      font-size: 18px;
    }

    .empty-relationship-message {
      font-size: 18px;
      line-height: 24px;
      font-family: Roboto, sans-serif;
      font-weight: 300;
      margin-top: 20px;
      height: 40px;
      padding-top: 7px;
    }
  }

  .info-message {
    display: inline-flex;
    margin-top: 18px;

    > i {
      color: #00a0aa;
    }

    span {
      padding-left: 10px;
    }
  }

  .input__info {
    font-size: 13px;
  }

  .footer {
    .edit-family-member-footer {
      border-top: 1px solid $color-nt-borders;
      padding: 24px 32px;
    }

    .edit-family-member-footer-message {
      padding: 24px 32px;

      p {
        font-size: 13px;
        font-style: italic;
        margin-top: 0;
      }

      .info-icon {
        float: left;
        padding-bottom: 2px;
        padding-right: 9.5px;
        color: $color-aqua-800;
      }
    }
  }
}

.react-table7 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
  border-color: #cecece;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
  border-top-color: #565a5c;
  font-size: 13px;
  border-spacing: 0;

  .empty-state-container--small {
    max-width: none;
    border-bottom: 1px solid #cecece;
  }

  .empty-state-container {
    margin: auto;
    box-shadow: none;
  }

  .empty-state-container__description {
    font-size: 18px;
    line-height: 24px;
    font-family: Roboto, sans-serif;
    font-weight: 300 !important;
    padding: 8px;
  }

  &__thead {
    display: block;

    .rt-th {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 8px;
      line-height: normal !important;
      border-bottom: 1px solid #cecece;
      color: #3d4042;
      font-weight: 500;
    }
  }

  &__tbody {
    display: block;
    height: auto;

    .row-bg-aqua {
      background-color: #e1fcff;

      .rt-td {
        overflow: visible !important;
      }
    }

    .btn__icon-only:hover {
      height: 30px;
    }

    .rt-td:focus,
    .rt-td:hover,
    .rt-tr:focus,
    .rt-tr:hover {
      background-color: #e1fcff;
    }

    .rt-tr {
      position: relative;
    }

    .no-data-tr {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rt-td {
      display: block;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #cecece;
      padding: 0 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .input--small {
        padding: 0 4px;
      }

      .select--small {
        padding: 0 4px;
      }

      .DateInput_input {
        padding: 0 4px;
        font-size: 15px !important;
      }

      .state-input {
        i {
          margin-top: -30px;
        }
      }

      input[id="list-personalPlanningHorizon"] {
        text-align: right;
      }

      #list-relationship,
      #list-related-to {
        .position-relative.width-100p {
          margin-top: 0 !important;
        }

        #list-related-to-DropdownField {
          span {
            color: #3d4042 !important;
          }
        }
      }

      .input-wrapper {
        width: auto;
      }

      .tooltip-child {
        visibility: hidden;
        padding: 5px;
        line-height: 20px;
        top: -40px;
        background-color: #ffe8e6;
        border: 1px solid #e04e39;
      }

      .tooltip-parent:hover .tooltip-child {
        visibility: visible;
      }
    }

    .rt-td.overflow-visible {
      overflow: visible !important;
    }

    .padding-required {
      .rt-td {
        padding: 0 8px;
      }
    }

    .SingleDatePickerInput_calendarIcon {
      i {
        display: none;
      }
    }

    .DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
      height: 345px !important;
    }
  }
}

.app-viewport--in-meeting .h4 input,
.app-viewport--in-meeting .h4 span,
.app-viewport--in-meeting .h4 .radio__label {
  font-size: 15px;
  font-weight: 400;
}
