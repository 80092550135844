.meeting-portal-footer {
  height: 64px;
  width: 100%;
  flex-shrink: 0;
  background: #000000;
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;

  .leave-meeting .icon {
    margin-left: 32px;
  }
}