.family-tree-tag {

  padding-right: 11px;

  button {
    width: 136px;
    height: 28px;
    padding: 5px 16px 5px 16px;
    border: 0;
    border-radius: 100px;
    background-color: #05676E;
    color: #FFF;
    font-weight: 500;
    font-size: 13px;
  }

  button:disabled,
  button[disabled]{
    opacity: 1;
  }
}