.life-insurance {

  .life-insurance-section {
    #cashValuetoFundGoals {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 16px;
      margin-bottom: 8px;

      label {
        flex-basis: 34%;
        margin-right: 16px;
      }
    }

    .asset-year-input{
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        flex-basis: 34%;
        margin-right: 1em;
      }
      .input-inline-align--right input{
        display: flex;
        flex-basis: 40%;
        margin-right: -100px;
        margin-left: -3px;
        margin-bottom: 4px;
        margin-top: 4px;

      }
      .input-inline-label {
        right: 8px;
        bottom: 6px;
        font-weight: bold;
      }
      b{
        position: relative;
      }
    }
  }

}
