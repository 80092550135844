.asset-titling-summary-page {
  .empty-state-container {
     margin-top: 200px;
  }

  .empty-state-container__description {
    font-size: 18px;
    line-height: 24px;
    font-family: Roboto, sans-serif;
    font-weight: 300 !important;
  }

  .asset-titling-summary-grid {
    display: grid;
    grid-template-columns: minmax(22%, 30%) minmax(12.5%, 55ch) minmax(16%, 4fr) minmax(16%, 5fr) minmax(14%, 55ch) 40px;
  }

  .asset-titling-grid-table [role=cell]:first-child {
    padding-left: 49px;
  }

  .asset-titling-grid-table {

    [role="cell"] {
      position: relative;
      padding-left: 8px;
      padding-right: 8px;

      &:first-child {
        padding-left: 32px;
      }

      .cell-with-popover {
        display: flex;

        .popover-wrapper {
          width: 20px;
        }
      }

      .cell-text-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}