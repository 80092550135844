.asset-reliance-empty-container {
  padding-top: 88px;
  flex-direction: column;
  padding-left: 40%;
  flex-grow: 1;

  .header-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 16px;
  }

  .body-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    padding-left: 15px;
  }
}