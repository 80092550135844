.legal-agreement {
  .account-details {
    .layout-data-entry-form__field {
      padding-bottom: 20px;
    }
  }

  li {
    list-style: none;
  }
}