.typeahead {
    .btn-skin--borderless {
        border: transparent;
    }
    .dropdown__item {
        &:hover,
        &:focus {
            background-color: $color-aqua-100;
        }
    }
    .more-results-loader {
        display: flex;
        height: 50px;
        justify-content: center;
        margin-top: 0;
        .h3 {
            color: var(--text-placeholder-grey-550, #767676);
            font-feature-settings: 'clig' off, 'liga' off;

            font-family: Roboto;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 138.462% */
        }

        .loader__wrapper {
            height: 0;
            width: 70px;
            margin: 0;
        }
    }
}