.page-action-menu {
  &__dropdown > button {
    transform: translateZ(0);

    i {
      font-size: 24px;
      margin: auto;
    }

    &:focus {
      background-color: $color-aqua-950;
      box-shadow: 0 0 0 2px $color-aqua-500;
      border: 1px solid $color-nt-container-background;
      color: white;
      text-decoration: none;
    }

    &:active,
    &:hover:not([disabled]) {
      background-color: $color-aqua-950;
      box-shadow: none;
      color: white;
    }
  }

  .dropdown__panel {
    z-index: 300;
    transform: translateZ(0);
  }

  .dropdown__item {
    display: flex;
    align-items: center;
  }
}
