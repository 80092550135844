.accordion {
  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    @include nt-border-bottom;

    &:first-child {
      @include nt-border-top;
    }

    &--hidden {
      display: none;
    }

    &:focus {
      outline: 3px solid transparent;
      box-shadow: 0 0 0 3px $color-aqua-500;
      border-radius: 0.5px;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;

    .rt-fit-content.table__body--fixed {
      &:focus {
        outline: 3px solid transparent;
        box-shadow: 0 0 0 3px $color-aqua-500;
        border-radius: 0.5px;
      }
    }
  }

  &__item--expanded &__content {
    display: block;
  }

  &__item--expanded &__content-right-moved {
    display: block;
    .accordion {
      padding-left: 23px;
    }
  }

  &__item--collapsed &__content {
    display: none;
  }

  &__item--collapsed &__content-right-moved {
    display: none;
  }

  &__button {
    -webkit-appearance: none;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid transparent;
    color: $color-nt-text;

    &:focus {
      box-shadow: 0 0 0 2px $color-aqua-500;
      border: 1px solid $color-nt-container-background;
      outline: none;
    }

    &:hover,
    &:focus {
      background-color: $color-aqua-100;
    }
  }

  &__heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: $spacing-xxxl;
    padding: $spacing-md;
    box-sizing: border-box;
  }

  &__title {
    margin-left: $spacing-md;
  }
}
