.layout-split-sidebar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > article {
    position: relative;
    flex-basis: 70%;
    height: 100%;
    background-color: white;
  }

  > aside,
  > section {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    height: 100%;
    min-width: 400px;
    overflow-y: auto;
    position: sticky;
    top: 0;
  }

  .page-overlay {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transform: translateZ(0);
    transition: right 0.4s ease-out, visibility 0.4s;
    background-color: white;

    @include nt-border-left;

    &--open {
      right: 0;
      visibility: visible;
    }

    &--closed {
      right: -100%;
      visibility: hidden;
    }
  }
}

.layout-split-left {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > section:first-of-type {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    height: 100%;
    min-width: 400px;
    overflow-y: auto;
    position: sticky;
    top: 0;
  }

  & > section:last-of-type {
    position: relative;
    flex-basis: 70%;
    height: 100%;
    background-color: #fff;
  }
}
