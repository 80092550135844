.edit-asset-reliance-button-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #05676E;
  .dds-icons {
    font-size: 22px;
  }
  button {
    padding-top: 6px;
    text-transform: none;
    line-height: 20px;
    font-size: 15px;
  }
}