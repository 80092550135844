.layout-data-entry-form {
  width: 100%;
  height: 100%;

  section {
    margin-bottom: $spacing-2xl;

    header {
      margin-bottom: $spacing-lg;
    }
  }

  > article {
    padding-left: $spacing-2xl;
    padding-right: $spacing-xl;
    min-height: calc(100% - 88px - 64px);
  }

  > article > section {
    padding-right: $spacing-md;
  }

  > aside,
  > section {
    padding-left: $spacing-xl;
    padding-right: $spacing-2xl;
    margin-top: $spacing-xxxl;
    margin-bottom: $spacing-xxxl;
  }

  &--split {
    > article {
      float: left;
      width: 65%;
    }

    > aside,
    > section {
      float: right;
      width: 35%;
    }
  }

  &__field {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: $spacing-lg;
    padding-right: $spacing-lg;
    margin-bottom: $spacing-md;

    > label {
      margin-right: $spacing-lg;
      flex-basis: 33%;
    }
  }

  &__field > :last-child {
    flex-grow: 1;
  }

  &__field--wide > label {
    flex-basis: 66%;
  }

  .tab__item-skin {
    font-size: 18px;
    letter-spacing: normal;
    text-transform: none;
  }
}
