@import '../abstracts/colors';

.edit-family-tree-view {
  position: relative;
  margin: 48px;
  width: calc(100% - 96px);
  aspect-ratio: 1366 / 1024;

  .report-header {
    position: absolute;
    right: 0;
    top: 16px;
    color: $color-nt-grey-500;
    z-index: 999;
  }

  button,
  .eyebrow-label,
  .data-entry-side-drawer {
    display: none;
  }

  .family-tree-page {
    .condensed-subtitle{
      display: none;
    }
    div[role="tabpanel"] {
      background-color: white;
    }
  }

  .accordion-tables {
    .icon {
      display: none;
    }

    .accented-accordion-item__right-content {
      padding-right: 32px;
    }
  }

  .layout-split-sidebar > section[data-theme=aqua] {
    background-color: $color-nt-grey-050;
  }

  [data-theme=aqua] * {
    color: $color-nt-grey-900;
  }
  .family-tree-page__tree {
    border: 3px dashed green;
  }
  .family-tree-page__header {
    padding-left: 0;
  }

}

.preview-class-heading{
  padding-left: 48px;
}
