@import '../abstracts/colors';

.connection-failed {
  min-height: calc(100vh - 112px) !important;

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 432px;
    text-align: center;
  }

  &__header {
    font-size: 36px;
    font-weight: 300;
    line-height: 40px;
    margin: 16px;
  }

  &__message {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }

  &__footer {
    color: $color-nt-grey-550;
    font-size: 15px;
  }
}