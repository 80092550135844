body {
  font-family: $font-family-stack;
  font-size: 13px;
  font-weight: $font-weight-normal;
  line-height: 18px;
}

h1,
.h1 {
  font-family: $font-family-stack;
  font-size: 30px;
  font-weight: $font-weight-light;
  line-height: 36px;
}

h2,
.h2 {
  font-family: $font-family-stack;
  font-size: 24px;
  font-weight: $font-weight-light;
  line-height: 30px;
}

h3,
.h3 {
  font-family: $font-family-stack;
  font-size: 18px;
  font-weight: $font-weight-medium;
  line-height: 24px;
}

h4,
.h4 {
  font-family: $font-family-stack;
  font-size: 15px;
  font-weight: $font-weight-medium;
  line-height: 20px;
}

h5,
.h5 {
  font-family: $font-family-stack;
  font-size: 13px;
  font-weight: $font-weight-medium;
  line-height: 18px;
}

h6,
.h6 {
  font-family: $font-family-stack;
  font-size: 12px;
  font-weight: $font-weight-medium;
  line-height: 15px;
}

.app-viewport--in-meeting {
  font-size: 15px;
  line-height: 20px;

  h1,
  .h1 {
    font-size: 36px;
    font-weight: $font-weight-light;
    line-height: 42px;
  }

  h2,
  .h2 {
    font-size: 30px;
    font-weight: $font-weight-light;
    line-height: 36px;
  }

  h3,
  .h3 {
    font-size: 24px;
    font-weight: $font-weight-light;
    line-height: 30px;
  }

  h4,
  .h4 {
    font-size: 18px;
    font-weight: $font-weight-medium;
    line-height: 24px;
  }

  h5,
  .h5 {
    font-size: 15px;
    font-weight: $font-weight-medium;
    line-height: 20px;
  }

  h6,
  .h6 {
    font-size: 13px;
    font-weight: $font-weight-medium;
    line-height: 18px;
  }
}

.btn--small {
  line-height: 30px;
}

.btn--medium {
  line-height: 38px;
}

.btn-large {
  line-height: 46px;
}

.btn-skin--primary {
  background-color: $color-aqua-900;
  border: 1px solid transparent;

  &:focus {
    background-color: $color-aqua-950;
    box-shadow: 0 0 0 2px $color-aqua-500;
    border: 1px solid white;
  }

  &:active,
  &:hover:not([disabled]) {
    background-color: $color-aqua-950;
    box-shadow: none;
    border: 1px solid transparent;
  }
}

.btn-skin--secondary {
  border-color: $color-aqua-950;

  &:focus {
    background-color: $color-aqua-950;
    border-color: $color-aqua-950;
    box-shadow: 0 0 0 2px $color-aqua-500;
    border: 1px solid $color-nt-container-background;
  }

  &:active,
  &:hover:not([disabled]) {
    background-color: $color-aqua-950;
    border-color: $color-aqua-950;
    box-shadow: none;
  }
}

.btn-skin--borderless {
  border-color: $color-aqua-950;
  border: 1px solid $color-nt-container-background;

  &:not(.btn__icon-only) {
    color: $color-aqua-800;
  }

  &:focus {
    color: $color-aqua-800;
    border-color: $color-aqua-950;
    box-shadow: 0 0 0 2px $color-aqua-500;
    border: 1px solid $color-nt-container-background;
  }

  &:active,
  &:hover:not([disabled]),
  &:hover:disabled {
    color: $color-aqua-800;
    box-shadow: none;
  }
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox__label::before {
  top: 6px;
}

.checkbox__label::after {
  top: 9px;
}

.checkbox__input:focus + .checkbox__label::before {
  border-color: $color-aqua-900;
  box-shadow: 0 0 3px 1px $color-aqua-800;
}

.checkbox__input:hover + .checkbox__label::before {
  border-color: $color-aqua-900;
}

.checkbox__input:checked:focus + .checkbox__label::before {
  border-color: $color-aqua-900;
  box-shadow: 0 0 3px 1px $color-aqua-800;
}

.checkbox__input:checked:hover + .checkbox__label::before {
  border-color: $color-aqua-900;
  background-color: $color-aqua-800;
}

.DateInput_input {
  border: none;
  cursor: pointer;
}

.datepicker .CalendarDay__default:hover {
  background-color: $color-aqua-100;
}

.datepicker--medium .DateInput > .DateInput_input {
  height: $spacing-xxxl;
}

.datepicker--medium .DateInput > .DateInput_input,
.datepicker--small .DateInput > .DateInput_input {
  font-size: 15px;
}

.draggable:hover {
  background-color: transparent;
}

.draggable:focus {
  background-color: $color-aqua-100;
  box-shadow: 0 0 0 3px $color-aqua-500;
}

.dropdown__item {
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown__item--disabled:focus {
  background-color: $color-aqua-100;
}

.dropdown__label {
  vertical-align: middle;
}

.footer .height-xsmall {
  display: none;
}

.header .container-fluid {
  @include nt-border-bottom;

  padding: 0 $spacing-xl;
}

.header__link-skin {
  color: $color-nt-text;
}

.header__link-skin:focus,
.header__link-skin:hover {
  color: $color-nt-text;
  border-bottom: solid 4px $color-aqua-800;
}

.header__main {
  height: $spacing-2xxl;
}

.header-skin--light {
  background-color: $color-nt-page-background;
}

.hover-highlight--primary:focus {
  box-shadow: 0 0 0 2px $color-aqua-500;
}

.hover-highlight--primary:focus,
.hover-highlight--primary:hover {
  background-color: $color-aqua-100;
}

.input__field::placeholder {
  color: $color-nt-text !important;
  opacity: 0.5 !important;
}

input:disabled {
  border: none;
}

.input-container {
  background-color: white;
  cursor: text;

  &:focus-within {
    border-color: $color-aqua-900;
    box-shadow: 0 0 5px 1px $color-aqua-800;
  }

  input {
    height: 100%;
  }

  .input-skin--noborder {
    padding-right: 8px;
  }

  .input__label--inline {
    font-size: 15px;
    text-transform: none;
    pointer-events: none;
  }
}

.input-skin,
input:disabled {
  background-color: white;
  color: $color-nt-text;
  opacity: 1;
}

.input-skin:focus {
  border-color: $color-aqua-900;
  box-shadow: 0 0 0 2px $color-aqua-500;
  border: 1px solid $color-aqua-500;
}

.input-skin--error:focus {
  box-shadow: 0 0 0 2px #e04e39;
}

.modal__header {
  padding-top: $spacing-xxl;
  padding-bottom: $spacing-xxl;
  padding-left: $spacing-xxxl;
  padding-right: $spacing-xxxl;

  .h3 {
    font-size: 24px;
    font-weight: $font-weight-medium;
    line-height: 30px;
  }
}

.modal__body {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: $spacing-xxxl;
  padding-right: $spacing-xxxl;
}

.modal__footer {
  padding-top: $spacing-xxxl;
  padding-bottom: $spacing-xxl;
  padding-left: $spacing-xxxl;
  padding-right: $spacing-xxxl;
}

.modal__content--medium {
  max-width: 540px;
}

.modal__overlay {
  z-index: 1002;
}

.navdrawer__wrapper {
  z-index: 1001;
}

.nav__item.active,
.nav__item--active {
  border-bottom: solid 4px $color-aqua-800;
}

.nav__item:focus {
  box-shadow: 0 0 0 2px $color-aqua-500;
}

.nav__item:focus,
.nav__item:hover {
  border-bottom: solid 4px $color-aqua-800;
}

.radio__label {
  &::before {
    width: 12px;
    height: 12px;
    top: 6px;
  }

  &::after {
    width: 8px;
    height: 8px;
    top: 8px;
    left: 2px;
  }

  &:hover::before {
    border-color: $color-aqua-900;
  }
}

.radio__input:focus + .radio__label::before,
.radio__input:checked:focus + .radio__label::before {
  border-color: $color-aqua-900;
  box-shadow: 0 0 3px 1px $color-aqua-800;
}

.sidedrawer {
  z-index: 1001;

  .appbar {
    display: none;
  }

  &__body.padding-lg {
    padding: 0;
  }

  &__overlay {
    z-index: 1000;
  }
}

.ReactTable .rt-tbody.rt-fit-content.table__body--fixed {
  overflow: visible;
}

.ReactTable.table {
  .rt-table {
    overflow: visible;
  }

  .rt-thead {
    outline: none;
  }

  .rt-tbody {
    .rt-td:focus,
    .rt-td:hover,
    .rt-tr:focus,
    .rt-tr:hover {
      background-color: $color-aqua-100;
    }

    .rt-tr:last-child {
      margin-bottom: 1px;
    }
  }

  .rt-th {
    padding: $spacing-md;
  }

  .rt-resizable-header:hover,
  .rt-resizable-header:focus,
  .rt-resizable-header-content:hover,
  .rt-resizable-header-content:focus {
    background-color: $color-aqua-100;
  }

  .table__noData {
    background-color: transparent;

    .h3 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .overflow-visible {
    overflow: visible !important;
  }
}

.tab__item-skin a:focus,
.tab__item-skin:focus {
  box-shadow: 0 0 0 2px $color-aqua-500;
}

.tab__item-skin:focus,
.tab__item-skin a:focus,
.tab__item--active {
  border-bottom: 4px solid $color-aqua-800;
}

.tab__item-skin:hover,
.tab__item-skin a:hover,
.tab__item-skin.active a:hover {
  border-bottom: 4px solid $color-aqua-950;
}
