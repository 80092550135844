.asset-reliance-barchart-legend-container {
  width: 12px;
  height: 12px;
  border-radius: 0;
  margin: 4px 8px;
  display: inline-block;
  justify-content: flex-end;

  .barchart-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}