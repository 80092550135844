.grid-row-span-5 {
  grid-row: span 5;
}

.grid-row-span-4 {
  grid-row: span 4;
}

.grid-row-span-3 {
  grid-row: span 3;
}

.grid-row-span-2 {
  grid-row: span 2;
}

.grid-row-span-2-error-bg {
  grid-row: span 2;
  background-color: #FFE8E6;
}

.grid-span-all {
  grid-column: 1 / -1;
}

.grid-span-5 {
  grid-column: span 5;
}

.grid-span-4 {
  grid-column: span 4;
}

.grid-span-3 {
  grid-column: span 3;
}

.grid-span-2 {
  grid-column: span 2;
}

.grid-display-contents {
  display: contents;
}

.grid-col-span-2-5 {
  grid-column: 2/5;
}

.grid-col-span-1-2 {
  grid-column: 1/2;
}

.grid-col-span-2-3 {
  grid-column: 2/3;
}

.grid-col-span-3-4 {
  grid-column: 3/4;
}

.grid-col-span-4-5 {
  grid-column: 4/5;
}

.grid-col-span-5-6 {
  grid-column: 5/6;
}
