@import '../abstracts/colors';
@import '../abstracts/spacing';

.radiogroup .radio label {
  min-width: 60px;
}

.radiogroup.show-red-asterisk>label:after {
  content: '*';
  color: $color-red-600;
  margin-left: 6px;
}

.radiogroup--horizontal {
  display: flex;
  flex-direction: row;

  .radio {
    margin-left: 0;
    margin-right: $spacing-lg;
  }

  .dds-icons.icon {
    color: $color-aqua-800;
  }

  .dds-icons.icon:hover {
    color: #283a3c;
  }
}

.radiogroupwitherrormessage_label {
  flex-basis: 66%;
}

.radiogroupwitherrormessage_empty_button {
  width: 32px;
}