/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Set the default background color of the application.
 */
body {
  background-color: #eaeaea;
}

/**
 * Disable all user interactions, if applicable.
 */
body.no-interaction {
  pointer-events: none !important;
  user-select: none !important;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: $color-aqua-800;
  text-decoration: none;
  &:hover {
    color: $color-aqua-900;
    text-decoration: underline;
  }
}

.nav__item:hover {
  text-decoration: none;
}