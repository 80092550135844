.input-wrapper {
  position: relative;
  width: max-content;

  input {
    width: 100%;
  }
}

.percent-field {
  .input-inline-label {
    font-weight: 700 !important;
  }
}

.input-inline-label {
  position: absolute;
  display: inline-flex;
  align-items: center;
}

.input-inline-align--left {
  input {
    padding-left: $spacing-20;
  }
}

.input-inline-align--right {
  .input-inline-label {
    right: 0;
  }

  input {
    padding-right: $spacing-20;
  }

  &.years-field input {
    padding-right: 52px;
  }
}

.input--width-small {
  width: 80px;
  max-width: 80px;
}

.input--width-medium {
  width: 120px;
  max-width: 120px;
}

.input--width-large {
  width: 300px;
  max-width: 300px;
}

.input-skin--borderless input:not(:focus) {
  border: none;
}

.input-text-align-right {
  input {
    text-align: right;
  }
}

