.risk-assets-and-risk-control {
  margin-top: 32px;

  .risk {
    margin-top: 12px;
    margin-left: 2px;
    .risk-legend-label {
      justify-content: space-between;
      .risk-label-bold {
        padding-right: 16px;
        font-weight: bold;
        vertical-align: top;
        margin-left: 2px;
      }
      .risk-label {
        padding-right: 16px;
        vertical-align: top;
        margin-left: 2px;
      }
      .risk-percentage{
        vertical-align: top;
      }
    }
  }
}

.risk-donut-grid{
  display: grid;
  grid-template-columns: 35px 205px;
  gap: 8px;
  justify-items: center;

  .risk-legend-container{
    margin: 4px 0px !important;
  }


}




