.gpi-datepicker {
  .btn-skin--borderless.datepicker__inputicon {
    border: none;
  }

  .input-skin--focus {
    border-color: $color-aqua-900;
    box-shadow: 0 0 0 2px $color-aqua-500;
    border: 1px solid $color-aqua-500;
  }
}