.asset-reliance-content {
  margin-top: 44px;
  padding-left: 48px;
  padding-right: 48px;
}

.table-cell-values {
  justify-content: center !important;
}

.row:has(.excess_assets):not(.summary-row) {
  height: auto;

  .table-row-title-container {
    padding-top: 8px;
    padding-bottom: 22px;
  }
}
