@import "../abstracts/colors";

.beneficiary-section {
  .underlined-sub-header {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .banner-text{
    display: flex;
    justify-content: space-between;
  }

  button.delete {
    margin-top: 5px;
    margin-left: 0;
    padding-left: 0;

    i {
      color: $color-nt-text;
      border-bottom: none;
      font-size: 18px;
    }
  }

  .beneficiary-section-header {
    padding: 0 0 0 16px;
  }

  .beneficiary-section-table {
    width: 100%;

    > :first-child {
      font-size: 13px;
      font-family: 'RobotoCondensed', 'Roboto', sans-serif;
      padding-bottom: 12px;
      padding-top: 12px;
    }

    [role="row"] {
      display: grid;
      grid-template-columns: 12fr 3fr;
      column-gap: 10px;
    }

    .total-owned-container {
      grid-column: 3/3;
      padding-top: 10px;

      .total-allocation {
        text-align: center;

        .allocation-warning {
          color: $color-orange-600
        }
      }
    }

    .beneficiary-table-row {
      margin-left: 15px;

      [role="row"] {
        display: grid;
        grid-template-columns: 14fr 1fr 16px;
        column-gap: 10px;
      }
    }
  }

  .percentInput {
    .percent-field {
      max-width: 83px;
    }
  }

  .percentInput input {
    text-align: right;
  }

  .beneficiary-table-row {
    height: 48px;
    padding-top: 4px;
    padding-bottom: 4px;

    > div {
      margin: auto 0;
    }

  }

}