$summary_chart_width: 220px;

.liabilities-bar {
  height: 14px !important;
}

.plan-summary-page {
  display: flex;
  flex-direction: column;
  background-color: $color-nt-grey-050;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  .condensed-subtitle {
    margin-left: 3px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    font-family: Roboto;
  }

  &__body {
    display: flex;

    .excess-assets-card {
      padding: 32px;
      display: flex;
      flex-direction: column;
      min-width: 550px;
      max-width: 550px;
      min-height: 780px;
      background-color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      border: 1px solid $color-nt-grey-100;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 32px;

      .excess-assets-value {
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 30px;
      }

      .excess-assets-barchart-legend {
        position: relative;
        top: 22px;
        width: calc(100% + 64px);
        left: -32px;

        display: flex;
        justify-content: flex-start;

        padding-left: 24px;
        padding-bottom: 20px;

        border-bottom: 1px solid $color-nt-grey-100;

        .asset-reliance-barchart-legend-label:nth-child(1) {
          padding-left: 0;
        }

        .asset-reliance-barchart-legend-label {
          font-size: 12px;

          .barchart-label {
            position: relative;
            top: 2px;
          }
        }
      }

      .excess-assets-chart-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 48px;

        &.excess-assets-extra-padding-top {
          padding-top: 32px;
        }

        .excess-assets-chart-label {
          position: absolute;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          width: 100%;
          z-index: 1;

          &.net-assets__label-color {
            color: $color-nt-primary-aqua;
          }

          &.goals__label-color {
            color: $color-orange-800;
          }

          &.zero_dollar__label-color {
            color: $color-nt-grey-550;
          }

          &.excess-assets__label-color {
            color: $color-nt-primary-aqua;

            .excess-assets-chart-line {
              background: $color-sufficiency-positive;
            }
          }

          &.asset-shortfall__label-color {
            color: $color-orange-800;

            .excess-assets-chart-line {
              background: $color-orange-500;
            }
          }

          .excess-assets-chart-line {
            margin-bottom: 5px;
            height: 3px;
            border-radius: 6px;

            &.net-assets-chart-line__color {
              background: $color-assets-accounts;
            }

            &.goals-chart-line__color {
              background: $color-goals-philanthropic;
            }

            &.zero-dollar-chart-line__color {
              background: $color-nt-borders;
            }

            &__full-width {
              width: 362px;
            }
          }

          .excess-assets-shift-upward {
            position: relative;
            top: -50px;
          }
        }

        .excess-assets-chart__left-panel {
          position: relative;
          width: 118px;
        }

        .excess-assets-chart__center-panel {
          position: relative;

          .assets-chart-excess-pill {
            display: inline-block;
            padding: 3px 12px;
            margin: auto;
            position: absolute;
            z-index: 1;
            left: 50%;
            transform: translate(-50%);
            background: rgba(255, 255, 255, 0.9);
            border: 2px solid;
            border-radius: 32px;
            height: 34px;

            &.excess-assets__pill-color {
              color: $color-aqua-800;
              border-color: $color-aqua-800;
            }

            &.asset-shortfall__pill-color {
              color: $color-orange-800;
              border-color: $color-orange-800;
            }
          }

          .assets-chart-excess-bar {
            margin: auto;
            position: absolute;
            z-index: 1;
            left: 50%;
            transform: translate(-50%);
            width: 12px;
            border-radius: 6px;

            &.excess-assets__bar-color {
              background: $color-sufficiency-positive;
            }

            &.asset-shortfall__bar-color {
              background: $color-orange-500;
            }
          }

          .assets-chart-container {
            height: 100%;

            .assets-chart.stacked-bar-chart {
              height: 100%;
              flex-grow: 0;
              margin: 0 12px;
            }
          }
        }

        .excess-assets-chart__right-panel {
          position: relative;
          text-align: right;
          width: 123px;
        }
      }
    }

    .net-assets-goals-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px 40px;
      height: 111px;
      background: #ffffff;
      border: 1px solid $color-nt-grey-100;
      box-sizing: border-box;
      border-radius: 5px;
      flex-grow: 1;
      margin: 32px 32px 32px 0;
      cursor: pointer;

      :hover * {
        color: $color-aqua-800;
        text-decoration: underline;
      }

      :focus * {
        border: $color-aqua-800;
        text-decoration: underline;
      }

      :focus-visible {
        outline-color: $color-aqua-500;
      }

      .net-assets {
        border-right: solid 1px;
        border-color: $color-nt-grey-100;
      }

      .goals {
        padding-left: 40px !important;
      }

      .monetary-value-card {
        flex-grow: 1;
        padding: 5px;
      }
    }

    .proposed-asset-allocation-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 40px 20px 40px;
      background: #ffffff;
      border: 1px solid $color-nt-grey-100;
      box-sizing: border-box;
      border-radius: 5px;
      flex-grow: 1;
      margin: 32px 32px 32px 0;
      cursor: pointer;
      justify-content: space-between;


      .proposed-asset-allocation-text:focus {
        border: $color-aqua-800;
        text-decoration: underline;
      }

      &:hover .proposed-asset-allocation-text {
        color: $color-aqua-800;
        text-decoration: underline;
      }

      .proposed-asset-allocation-text:focus-visible {
        outline-style: auto;
        outline-color: $color-black;
      }

      .proposed-asset-allocation {
        font-weight: 500;
        padding-right: 8px;
        font-size: 18px;
        line-height: 24px;
        white-space: nowrap;
        margin-bottom: 4px;
      }


      .total-portfolio {
        color: #808182;
      }

      .risk-assets-and-risk-control {
        padding-left: 8px;
        line-height: 24px;
        font-size: 18px;
        margin-top: 0;

        .risk {
          display: flex;
          justify-content: space-between;

          .risk-legend-label {
            display: flex;
            align-items: center;

            .risk-label {
              padding-right: 16px;
              white-space: nowrap;
            }
          }

          .risk-percentage {
            font-weight: 500;
          }
        }
      }
    }

    .container {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      padding-left: unset;
      padding-right: unset;
    }

    .plan-summary-card {
      background-color: $color-white;
      padding: 24px 40px;
      margin: 0px 32px 32px 0;
      border: 1px solid $color-nt-grey-100;
      border-radius: 5px;
      &:not(.no-excess-assets) {
        cursor: pointer;
      }

      &:hover:not(.no-excess-assets, [id^="highcharts-"],[class^="highcharts-"]) {
        color: $color-aqua-800;
        text-decoration: underline;
      }

      .header {
        margin: 0;
        padding-bottom: 8px;
      }

      :focus {
        outline-color: $color-black;
        text-decoration: underline;
      }

      [id^="highcharts-"], [class^="highcharts-"] {
        text-decoration: none;

        :hover * {
          text-decoration: none;
        }
      }

      .insufficient-assets-message {
        margin: 0 25px 0 25px;
        .asset-reliance-container {
          background-color: rgb(255, 255, 255);
          color: #283A3C;
          border-color: #283A3C;
          &:hover {
            color: rgb(255, 255, 255);
            background-color: #283A3C;
          }
        }
      }
    }
  }

  .sub-header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 12px;
  }

  .subhead {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
  }

  .dollar-value {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
  }

  .future-asset-purchase-disclaimer {
    margin: 18px 32px;

    span {
      margin-left: 8px;
    }

    &-text {
      font-family: Roboto;
      font-size: 15px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}
