@import '../abstracts/colors';

.cover-page {
  height: 100%;
  zoom: 1.2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(8, 84, 89, 0.8), rgba(8, 84, 89, 0.8)), url("../../Reporting/cover-page-background.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    width: 60%;
    height: 70%;
    background: #fff;
    border-radius: 24px;
    padding: 72px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        color: #05676E;
        text-shadow: 0px 0px 1px 0px rgba(255, 255, 255, 0.50);
    }

    &__info,
    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__info {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: $spacing-2xl;

        .profile-display-name {
            text-transform: uppercase;
        }
    }

    &__footer {
        margin-top: 76px;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;

        .report-credits {
            margin-top: $spacing-2xxxl;
        }
    }
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    margin: 24px;
    border: solid 4px #ae9132;
    pointer-events: none;
  }
}