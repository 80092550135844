button {
  &.full-width {
    width: 100%;
  }

  &.center-align {
    text-align: center;
  }
}

.button--rounded {
  border-radius: 20px;
}

.button--destructive {
  &.btn-skin--primary {
    background-color: $color-red-700;

    &:focus {
      box-shadow: 0 0 0 2px $color-red-500;
    }

    &:active,
    &:focus,
    &:hover:not([disabled]) {
      background-color: $color-red-900;
    }
  }

  &.btn-skin--secondary {
    border-color: $color-red-900;

    &:focus {
      box-shadow: 0 0 0 2px $color-red-500;
      background-color: $color-red-900;
    }

    &:active,
    &:hover:not([disabled]) {
      background-color: $color-red-900;
      border-color: $color-red-900;
    }

    &:hover:disabled,
    &:not(:active):not(:focus):not(:hover):not(.btn__icon-only) {
      color: $color-red-900;
    }
  }

  &.btn-skin--borderless {
    &:not(.btn__icon-only) {
      color: $color-red-800;
    }

    &:focus {
      box-shadow: 0 0 0 2px $color-red-500;
    }

    &:active,
    &:focus,
    &:hover:not([disabled]),
    &:hover:disabled {
      color: $color-red-800;
    }
  }
}

.button--toggle {
  text-transform: none;
  font-size: 13px;
  font-weight: $font-weight-normal;
  letter-spacing: 0;
  border: none;
  box-shadow: none;
  
  &.btn--small {
    height: 28px;
  }
  
  &.btn-skin--secondary {
    background-color: white !important;
    
    &:hover {
      color: $color-aqua-800;
    }
    
    &:focus:not(:hover) {
      border: 1px solid $color-aqua-500;
      color: $color-nt-grey-800;
    }
  }
}
