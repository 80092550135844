.section-header {
  display: flex;
  justify-content: space-between;
  gap: $spacing-2xl;
  align-items: center;
  margin-bottom: 24px;

  div[role=presentation] {
    z-index: 300;
    transform: translateZ(0);
  }

  > * {
    margin: auto 0;
  }
}

.summary-page {
  .summary-page-content {
    padding: 48px;

    section {
      margin-bottom: 71px;
    }
  }

  .section-header {
    gap: 4px;
    h3 {
      line-height: 34px;
    }
    .page-action-menu-options {
      display: flex;
      justify-content: space-between;
    }
  }
}