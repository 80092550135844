.left-right-alert-banner {
  margin-bottom: 26px;

  .alert__actions {
    display: none;
  }

  .btn-skin--borderless {
    border: transparent;
  }
}
.liability-width{
  width: 90%;
  margin-left: 47px;
}
.liability-holdings-width{
  width: 93%;
  margin-left: 48px;
}


