.set-portfolio-reserve-page {
  display: flex;
  flex-direction: column;
  background-color: $color-nt-grey-050;
  position: relative;
  width: 100%;
  height: 100%;

  .condensed-subtitle {
    margin-left: 3px;
    font-family: Roboto, sans-serif;
  }

  &__header {
    h1 {
      width: 82%;
    }

    .set-my-reserve-target {
      color: $color-nt-grey-700;
      background-color: white;
      width: 193px;
    }

    .set-my-reserve-target:hover {
      color: $color-aqua-950 !important;
      background-color: $color-aqua-100 !important;
    }

    .three-dots-menu {
      .icon {
        font-size: 18px;
        font-weight: 400;
      }
    }

    .set-portfolio-reserve-header-text {
      justify-content: left;

      &__header-toggle-text {
        color: white;
      }
    }

    .btn-skin--borderless {
      color: #ffffff;
    }

    .reserve-page-action-menu-options {
      display: flex;
      justify-content: space-between;

      input {
        accent-color: #3D4042;
      }
    }

    .reserve-page-action-menu-rangeofoutcomes-options {
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;

      input {
        accent-color: #3D4042;
      }
    }

    .reserve-page-action-menu-radio-options {
      padding-top: 1px;
      padding-bottom: 0;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;

      input {
        accent-color: #3D4042;
      }
    }

    .set-my-reserve-row-text {
      color: #3D4042;
      width: 100%;
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-grow: 1;

    &__chart {
      display: flex;
      flex-direction: column;
      flex: 2 1;
      background-color: $color-nt-grey-050;
      align-items: center;

      &__image {
        margin-top: 48px;
      }
    }

    &__customized-allocation {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 3 1;
      background-color: #ffffff;

      &__image {
        margin-top: 48px;
        height: 562px;
        width: 601px;
      }
    }
  }

  &__donut-container {
    position: relative;
    height: 300px;
    width: 300px;

    &__solid {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    &__shaded {
      position: absolute;
    }
  }
}

.investable-insufficiency-warning {
  margin-top: 12px;
}

.review-portfolio-reserve-page {
  display: flex;
  flex-direction: column;
  background-color: $color-nt-grey-050;
  position: relative;
  width: 100%;
  height: 100%;

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__customized-allocation {
      display: flex;
      flex-direction: column;
      flex: 3 1;
      background-color: #ffffff;

      &__sub-header {
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
        padding: 30px 114px 0 77px;
      }

      &__sub-header-text {
        padding-left: 77px;
        margin-top: 0;
      }

      &__line-break {
        padding-top: 32px;
        padding-left: 92px;
        width: 696.641px;
        height: 33px;
      }

      &__icon {
        color: #05676E;
        width: 30px;
        height: 3px;
        font-size: 22px !important;
        padding-left: 90px;
        padding-bottom: 15px;
        margin-top: -15px;
        align-items: start;
      }

      &__minus-icon {
        margin-top: -24px;
        margin-bottom: 8px;
      }

      .btn--small {
        line-height: 24px;
        height: 24px;
      }

      &__text {
        font-size: 18px;
        font-weight: 420;
        line-height: 24px;
        padding-left: 122px;
        padding-top: 32px;
        align-items: start;
        display: grid;
        grid-template-columns: 57% 27% 16%;
        max-width: 800px;
        color: #3d4042;

        .quantity {
          justify-self: end;
        }

        &__spending-period {
          display: flex;
          flex-direction: column;
          align-items: end;
        }
      }

      &__sub-text {
        font-size: 15px !important;
        font-weight: 400 !important;
        font-style: italic;
        line-height: 20px !important;
        padding-left: 122px;
        color: #767676;
        align-items: start;
      }
      &__sub-text-years {
        font-size: 15px !important;
        font-weight: 400 !important;
        font-style: italic;
        line-height: 20px !important;
        color: #767676;
        align-items: start;
      }
    }

    &__aligned-inflows {
      padding-left: 122px;
      padding-top: 21px;
      display: grid;
      grid-template-columns: 84% 16%;
      max-width: 800px;

      &__content {
        &:not(:last-child) {
          padding-bottom: 14px;
        }
      }

      &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      &__header {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 10px;
        padding-top: 16px;
        line-height: 12px;
        margin-bottom: 8px;
      }

      &__data {
        padding-top: 14px;
      }

      hr {
        margin: 0
      }
    }

  }
}

.edit-portfolio-reserve-page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  .condensed-subtitle {
    margin-left: 3px;
    font-family: Roboto, sans-serif;
  }

  &__table {
    margin: 0 48px;

    &__row {
      display: flex;
      flex-direction: row
    }

    &__excess-assets-total {
      height: 72px;
      width: 320px;
      display: grid;
      grid-template-columns: 41% 59%;
      align-items: center;

      .icon {
        font-size: 32px;
      }

      &__amount {
        font-family: Roboto, sans-serif;
        text-align: right;
      }
    }

    &__excess-assets {
      height: 72px;
      width: 320px;
      display: grid;
      grid-template-columns: 31% 69%;
      align-items: center;

      .icon {
        font-size: 32px;
      }

      &__amount {
        font-family: Roboto, sans-serif;
        text-align: right;
      }
    }

    .pr-page-action-menu {
      display: flex;
      justify-content: right;
      width: 5%;
      margin-right: 1%;
      .icon {
        font-size: 18px;
        font-weight: 400;
      }
      .btn--medium {
        width: 40px;
      }
    }
    .btn--medium {
      width: 220px;
    }

    .btn-skin--primary {
      background-color: #05676E;
    }

    &__portfolio-risk {
      width: 320px;
      align-items: center;

      &__cvar {
        font-size: 15px;
        font-weight: 500;
        width: 250px;
        padding-bottom: 1px;
      }

      &__expanded-risk-row {
        font-size: 15px;
        font-weight: 500;
        width: 250px;
      }

      &__excess-assets-impact {
        font-size: 15px;
        font-weight: 400;
        padding-top: 35px;
        display: flex;
        justify-content: space-between;
      }
    }


    &__returns {
      width: 320px;
      display: grid;
      grid-template-columns: 45% 55%;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;

      .trending-down {
        color: #BF5E00;
      }

      .trending-up {
        color: #05676E;
      }

      .trending-container {
        width: 15px;
        height: 9px;
      }

      &__worse {
        border-bottom: solid 1px $color-nt-grey-100;
      }

      &__better {
        border-top: solid 1px $color-nt-grey-100;
      }

      &__label {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 125px;
      }

      &__amount {
        font-family: Roboto, sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: #3d4042;
        font-weight: 400;
        text-align: right;
      }

      &__percentile-text {
        font-family: Roboto, sans-serif;
        font-size: 13px;
        color: #6B6E6F;
        font-weight: 400;
      }

      &__info-text {
        height: 40px;
        grid-column: span 2;
        padding-top: 24px;
        padding-bottom: 32px;
      }
    }

    &__asset-allocation {
      justify-content: space-around;
      flex-direction: row;
      display: flex;
      width: 320px;
      align-items: center;
    }

    &__row:after {
      content: "";
      display: table;
      clear: both;
    }

    &__column {
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      width: 33.33%;
      border-bottom: 2px solid $color-nt-grey-050;
      padding-top: 32px;
      padding-bottom: 32px;

      &__content {
        margin-top: 32px;
        padding-right: 10px;
        font-size: 18px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        font-style: normal;
      }

      &__middle {
        margin-top: 35px;
        margin-left: 10%;
        font-size: 18px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        font-style: normal;
        width: 250px;
      }

      &__middle-less-margin {
        margin-top: 14px;
        margin-left: 10%;
        font-size: 18px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        font-style: normal;
        width: 250px;
      }

      &__subtext {
        margin: 0 5% 0 0;
        font-size: 15px;
        font-weight: 400;
        font-family: Roboto, sans-serif;
        font-style: normal;
        color: #6B6E6F;
      }
    }

    &__second-column {
      background-color: $color-nt-grey-025;
      border-bottom: 2px solid $color-nt-grey-100;
    }

    &__third-column {
      border-bottom: 2px solid $color-nt-grey-100;
    }
  }

  &__button {
    border: none !important;
    color: #05676E !important;

    :hover {
      color: #085459
    }

    .icon {
      font-size: 32px !important;
    }
  }

  &__centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    display: flex;
    align-items: center;
    padding: 15px 70px 0 70px;
  }

  &__justified {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .years-input-container-with-icons {
    position: relative;
  }

  .input__icon {
    top: 88px;
    left: 18px;
    color: #05676E;
    position: absolute;
    border: 0;

    &__increase-support-button {
      top: 66px;
      left: 2px;
    }

    &__decrease-support-button {
      top: 94px;
      left: 2px;
    }

    &__increase-risk-button {
      top: 66px;
      left: 2px;
    }

    &__decrease-risk-button {
      top: 94px;
      left: 2px;
    }
  }

  &__target-length-input {
    padding-bottom: 16px;
    padding-top: 32px;
    width: 100%;
    height: 100%;
  }

  input:focus {
    outline: none !important;
    border: 0 ! important;
  }

  input:disabled {
    border: 1px solid #cecece !important;
  }

  .editable-target-border {
    .input-skin {
      border: 1px solid #FABE0F !important;
      outline: none;
    }
  }

  &__target-length-input:focus {
    .input-skin {
      border: none;
    }
  }

  &__minimum-risk-input {
    padding-bottom: 16px;
    padding-top: 32px;
  }

  #portfolioReserveTargetLength {
    background-color: #ffffff;
    border-radius: 6px;
    width: 302px;
    height: 72px;
    font-size: 30px;
    font-weight: 300;
  }

  #lifestyleMinimumRisk {
    background-color: #ffffff;
    border-radius: 6px;
    width: 302px;
    height: 72px;
    font-size: 30px;
    font-weight: 300;
  }

  .input-inline-label {
    height: 72px;
  }

  .row-inactive {
    opacity: 0.4;
  }

  .row-active {
    height: 500px !important;
  }

  .loader__wrapper {
    margin: 0 0 0 65%;
    height: 72px;
  }

  .risk-label-centered {
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .risk-legend-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .risk-label-margin-right {
    margin-right: 8px;
  }
}

.monitor-portfolio-reserve-page {
  display: flex;
  flex-direction: column;
  background-color: $color-nt-grey-050;
  position: relative;
  width: 100%;
  height: 100%;

  .change-reserve-target {
    border: 1px solid #fff;
    width: 260px;
    color: #ffffff;
    margin-left: 30px;
  }

  &__body {
    margin-left: 48px;
    margin-top: 32px;
    margin-right: 48px;

    &__cards {
      display: flex;
      justify-content: space-between;

      .drawdown-card {
        max-width: 50% !important;
        min-width: 550px;
        background-color: #ffffff;
        font-style: normal;
        line-height: 24px;
        border: 1px solid $color-nt-grey-100;
        border-radius: 5px;
        margin-top: 24px;
        margin-left: 24px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;

        &__current {
          max-width: 50% !important;
          min-width: 550px;
          background-color: #ffffff;
          font-style: normal;
          line-height: 24px;
          border: 1px solid $color-nt-grey-100;
          border-radius: 5px;
          margin-top: 24px;
          margin-left: -20px !important;
          padding-top: 24px !important;
          padding-right: 24px !important;
          max-height: 270px !important;

          &__header {
            display: flex;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 24px !important;
          }

          &__subhead {
            overflow-wrap: break-word;
            font-size: 21px;
            font-weight: 300;
          }
        }

        .section {
          display: inline-grid;
          margin-top: 24px;
        }

        &__header {
          display: flex;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
        }

        .portfolio-reserve-subhead {
          margin-top: 24px;
          overflow-wrap: break-word;
          font-size: 21px;
          font-weight: 300;
        }
      }
    }

    &__text {
      align-items: start;

      &__title-card {
        display: inline-flex;

        &__year {
          display: inline-flex;
          font-weight: bold;
          padding-left: 10px;
        }
      }
    }


    .risk-assets-and-risk-control {
      font-size: 15px;
      display: inline-flex;
      align-items: center;
      margin: auto;
      padding: 20px 20px 20px 0px;

      .asset-allocation-text {
        align-items: start;
        font-weight: bold;
        padding-right: 2px;
        width: 160px;
        .total-portfolio {
          font-weight: normal;
        }
      }

      .risk-legend {
        margin-top: 0px !important;
        align-items: center;
        margin-left: 24px;
        margin-right: 24px;

        .risk {
          display: inline-flex;
          justify-content: space-between;
          width: 100%;

          .risk-legend-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .risk-label {
              padding-right: 16px;
              white-space: nowrap;
            }
          }

          .risk-percentage {
            float: right !important;
            font-weight: bold;
          }
        }


      }
    }
  }
}

.off-toggle-modal {
  display: flex;
  justify-content: space-between;

  &__list-item {
    margin-left: 30px;
    display: list-item;
  }

  .hover-text {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: red;
    font-style: normal;
    line-height: 18px;
  }
}

.activate-pr-tooltip {
  border: none;
  box-sizing: border-box;
  position: absolute !important;
  display: block !important;
  width: 623px !important;
  overflow: hidden !important;
  z-index: 11;
  justify-content: flex-start;
  align-items: baseline;


  &_popover-content {
    transform-origin: 0 0;
    -webkit-box-sizing: border-box;
    -webkit-transform: translateY(-150px);
    transform: translateY(-150px);
    padding: 16px;
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 20%);
    border: 1px solid #cecece;
    background-color: #fff;
    position: fixed;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    flex-direction:row;
    align-items: baseline;
  }

  &_button {
    padding-left: 10px;
    background-color: transparent !important;
    cursor: pointer;
  }


  .nav-drawer-button {
    border: none transparent !important;
    position: absolute;
  }
}

.pr-funded-by-non-investable-assets-slider-container {
  display: flex;
  flex-direction: row;


  .pr-funded-by-non-investable-assets-slider {
    flex-grow: 0;
    padding-left:35px !important;
    padding-top: 10px !important;
  }

  .pr-spending-section-header {
    justify-content: flex-start !important;
  }
}

.toggle-label {
  font-size: 13px;
  padding-right: 15px;
  padding-top: 15px;

  &__white {
    color: white;
  }
}
