.service-team-table {
  margin-top: 10px;

  .search-bar-container {
    width: 400px;

    .typeahead {
      flex-grow: 4;

      .dropdown__panel {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        width: 70rem;
        padding: 1rem;
        z-index: 11;
      }
    }
  }

  .search-results-grid {
    display: grid;
    grid-template-columns: 5rem 15rem 10rem 13rem auto;
    grid-column-gap: 16px;

    &.disabled {
      color: $color-nt-grey-500;
      font-style: italic;
      cursor: default;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .delete-service-team-button {
    .icon {
      margin-top: -2px;
    }
    border: none
  }
}