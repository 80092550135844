.partner-dashboard-page {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;


  .actionMenu {
    float: right;
  }

  #search_container {
    height: 3.077rem;
    width: 375px;
    margin-left: 8px;
    font-size: 15px;
    float: left;

    .search__btn--close-no-label {
      margin-top: 1px;
    }
  }

  .search_ddOptions {
    padding-left: 12px;
  }

  .client-table {
    max-width: 100%;
    min-height: 560px;
    height: auto;
    background-color: white;
    padding: 24px;
  }

  h4 {
    font-size: 15px;
    line-height: 20px;
    padding: 13px 24px 15px 0px;
  }

  .table-header {
    max-width: 100%;
    height: 48px;
    width: 1016px;
  }

  .icon-padding {
    padding: 13px 9px 17px 8px;
  }

  .tab__item {
    opacity: 0.4;
  }

  .tab__item--active {
    opacity: 1;
  }

  .tabs__list {
    border-bottom: none;
    padding-left: 3.077rem;
  }

  .ReactTable.table .table-displayName {
    font-weight: 500;
  }

  .ReactTable .rt-tbody.rt-fit-content {
    // todo this was getting overwritten. Delete it? overflow-y: hidden;
    overflow: visible;
  }

  .ReactTable.table .rt-thead.-header .rt-tr.table__row--medium {
    height: 3.077rem;
  }

  .ReactTable.table {
    border-top: 2px solid #565a5c;

    .table__noData {
      .h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        padding-top: 88px;
      }
    }
  }

  i {
    vertical-align: top;
  }

  .table-spacing {
    margin-bottom: 24px;
  }

  .loading-dashboard {
    text-align: center;

    .h3 {
      font-weight: 300;
      color: #3d4042;
      font-size: 24px;
    }
  }

  .monitoring-tool {
    width: 296px;
    height: 196px;
    margin-bottom: 32px;
    background-color: white;
    display: flex;
  }

  .tool-border {
    width: 296px;
    height: 52px;
    font-size: 15px;
    line-height: 20px;
    color: #3d4042;
    font-weight: 500;
    border-bottom: 1px solid #d8d8d8;
    padding: 18px 8px 17px 18px;
  }

  .placeholder {
    width: 296px;
    padding: 56px 31px 66px 31px;
    margin-top: 12px;
    margin-left: -18px;
    text-align: center;
    color: #6b6e6f;
    font-size: 18px;
    font-weight: 300;
  }

  //.container-fluid {
  //    padding-left: 0px;
  //}
  .row {
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 1024px) {
    .table-header {
      max-width: 100%;
      height: 48px;
      width: auto;
    }
    .client-table {
      max-width: 100%;
      width: auto;
      height: auto;
      background-color: white;
      padding: 24px;
    }
    .monitoring-tool {
      max-width: 100%;
      width: 240px;
      height: 196px;
      margin-bottom: 32px;
      background-color: white;
      display: flex;
    }
    .tool-border {
      max-width: 100%;
      width: 240px;
      height: 52px;
      font-size: 15px;
      line-height: 20px;
      color: #3d4042;
      font-weight: 500;
      border-bottom: 1px solid #d8d8d8;
      padding: 18px 8px 17px 18px;
    }
    .placeholder {
      width: 240px;
      padding: 56px 26px 66px 26px;
      text-align: center;
      word-wrap: break-word;
      color: #6b6e6f;
      font-size: 18px;
      font-weight: 300;
    }
  }

  .newClientId {
    float: right;
  }

  .newClientText {
    padding: 12px 1px 13px 1px;
    height: 13px;
    width: 124px;
  }

  .color-text {
    color: #3d4042;
    font-size: 13px;
  }

  #undefined-DropdownField-Label {
    color: #3d4042;
  }

  .dropdown__group__label {
    display: none;
  }

  .profileMenu {
    width: 24px;
  }

  .dropdown__panel--right {
    margin-top: -1px;
  }

  .ReactTable.table .rt-tbody .table__row--large {
    height: 48px;
    line-height: 48px;
    padding-right: 8px;
  }

  .btn-skin--borderless {
    border: none !important;
  }

  button.btn--medium.btn-skin--borderless.btn__icon-only.iconNameActive {
    margin-top: -4px;
    margin-left: -16px;
  }

  .parent1 input {
    width: 3.077rem;
  }

  .parent1 {
    // todo this was getting overwritten. Delete it? display: inline-flex;
    display: flex;
    align-items: center;
  }

  .parent2 {
    float: none;
    margin: auto;
    width: 50%;
  }

  .parent3 {
    float: right;
  }

  span.pageSize {
    margin-right: 8px;
  }

  #btnPageNumber,
  input#pageInput {
    margin-left: 8px;
    margin-right: 8px;
  }

  .h3.margin-none,
  .pagination,
  .pagination__input,
  .pagination__size {
    margin-top: 87px;
  }

  #profileActionMenu button i.dds-icons.icon {
    padding-right: 2px;
  }

  #btnPageNumber i.dds-icons.icon {
    font-size: 13px;
  }

  // Styles for Table component
  // TODO: Extract this to stylesheet for HeaderIcon component once HeaderIcon is used outside of PartnerDashboard
  .header-icon {
    vertical-align: middle;
  }

  .ReactTable.table.dashboard-conditions-table {
    border-top: 1px $color-nt-grey-200;
  }

  .dashboard-conditions-table {
    .rt-thead.-header {
      visibility: hidden;
      display: none;
    }

    .rt-tr .rt-td:nth-child(1) {
      border-right: solid 4px $color-red-500;
      border-bottom: 0;
    }

    .rt-tr:first-child {
      border-top: 1px solid $color-nt-grey-200;
    }

    .rt-tr:last-child .rt-td:nth-child(1) {
      border-bottom: 1px solid $color-nt-grey-200;
    }
  }

  .btn__icon-none.page__item-skin--active.btn-skin--secondary {
    background-color: $color-aqua-800;
    border-color: $color-aqua-800;
    color: white;
  }
}

.partner-dashboard-section {
  width: calc(100%);
}

.badge-condition-length {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
}

.profile-alerts {
  text-align: center;
}

.days-active {
  text-align: left;
}

.portfolio-reserve-status-bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 4px 2px;
  display: inline-block;
}

.up-popover-button {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  .dds-icons.icon {
    color: $color-red-800;
    vertical-align: top;
    font-size: 12px;

    &:hover {
      color: $color-red-800;
    }
  }
}


.no-linked-client-modal {
  font-size: 1.15385rem;
  line-height: 1.53846rem;
  font-family: sans-serif;
}