.nav-drawer-item {
  min-height: $spacing-xxxl;
  margin: 0;

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $spacing-xxxl;
    min-height: $spacing-xxxl;
    line-height: 18px;
    font-size: 13px;
    padding: 0 $spacing-lg;

    &:focus {
      box-shadow: 0 0 0 2px $color-aqua-500;
      border: 1px solid $color-nt-container-background;
    }

    &.active:not(.disabled) {
      background-color: $color-aqua-800;
      color: white;
    }
  }
}

.nav-drawer-sub-item {
  min-height: $spacing-xxl;
  margin: $spacing-sm 0;

  &__link {
    display: flex;
    height: $spacing-xxl;
    min-height: $spacing-xxl;
    line-height: $spacing-xxl;
    font-size: 12px;
    padding: 0 $spacing-lg 0 $spacing-xl;
    justify-content: space-between;
    align-items: center;

    &:focus {
      box-shadow: 0 0 0 2px $color-aqua-500;
      border: 1px solid $color-nt-container-background;
    }

    &.active:not(.disabled) {
      background-color: $color-nt-grey-050;
      color: $color-aqua-800;
    }
  }
}

.nav-drawer-item,
.nav-drawer-sub-item {
  &__link {
    width: 100%;
    color: $color-nt-text;
    font-weight: 500;
    text-decoration: none !important;
    text-transform: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
    cursor: pointer;

    &:hover:not(.active):not(.disabled),
    &:focus:not(.active):not(.disabled) {
      background-color: $color-nt-grey-200;
      text-decoration: none;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
