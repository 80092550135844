.clientProfileHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .clientProfileDropdown {
    height: 53px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #3D4042;
    padding-right: 35px;

    .display-flex {
      flex-direction: column;
      row-gap: 1px;
    }
    .align-items-center {
      align-items: start !important;
    }
    .select--medium, .select--small {
      padding-top: 11px;
      padding-bottom: 11px;
      padding-left: 11px;
      height: 53px;
    }
    .input__label--inline {
      font-size: 12px;
    }
    .input__icon {
      margin-top: -35px;
    }

    label, span {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      padding-right: 8px;
      margin-right: 0;
    }

    .dropdown__panel .dropdown__item {
      white-space: normal;
    }
  }

  &__right-aligned-content {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 16px;
  }

  &__previous-visited-section {
    display: -webkit-box;
    justify-content: flex-end !important;
    align-items: center;
  }

  &__previous-visited-title {
      font-weight: 500;
      font-size: 11px;
      line-height: 5px;
      text-align: right;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #3D4042;
  }
  &__previous-visited-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 5px;
    text-align: right;
  }
}