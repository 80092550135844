@import '../abstracts/borders';
@import '../abstracts/spacing';
@import '../abstracts/colors';

.underlined-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: $spacing-xxxl;
  padding: $spacing-md 0;

  @include nt-border-bottom;

  h4 {
    margin: 0;
  }

  .underlined-header__right-content {
    color: $color-nt-text;
    font-size: 13px;
    font-weight: 400;
    font-family: 'RobotoCondensed', 'Roboto', sans-serif;
  }

  &__left-content,
  &__right-content {
    display: flex;
    align-items: center;

    button {
      height: $spacing-xxl;
    }
  }
}

.underlined-sub-header {
  min-height: $spacing-xxl;
  font-size: 15px;
  margin-top: $spacing-xxl;
  display: flex;
  justify-content: space-between;

  @include nt-border-bottom;

  &__right-content {
    display: flex;
    align-items: end;

    button {
      padding-right: 0;
    }
  }
}
