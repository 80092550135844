.grouped-table-grid {
  display: grid;
  grid-template-columns: minmax(22%, 30%) minmax(12.5%, 4fr) minmax(12.5%, 4fr) minmax(10%, 55ch) minmax(10%, 55ch) 40px;
}

.grouped-table {
  background-color: $color-nt-grey-025;

  [role="cell"], .grouped-table-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48px;

    .cell-text-content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-left: 12px;
    }
  }

  .invisible-row-wrapper {
    display: contents;

    &.within-group {
      [role="cell"]:first-child {
        padding-left: 40px;
      }
    }

    [role="cell"]:first-child {
      padding-left: 39px;
    }

    &:hover,
    &:focus {
      [role="cell"], .grouped-table-cell {
        background-color: $color-aqua-100;
        outline: 0;
      }
    }
  }

  [role="columnheader"] {
    display: flex;
    align-items: center;
    line-height: 15px;
    border-bottom: 1px solid $color-nt-grey-100;
    height: 34px;
    padding-left: 39px;
    font-family: RobotoCondensed, Roboto, sans-serif;
    color: #3d4042;
    font-size: 11px;
    font-weight: 400;
    font-stretch: condensed;
    text-transform: uppercase;
  }

  .grouped-table-subheader {
    height: 48px;
    padding-left: 39px;
    line-height: 48px;

    &[role="row"] > :first-child {
      padding-left: 32px;
    }
  }

  .group-value {
    text-align: right;
    color: $color-nt-grey-550;
  }
}
