.data-entry-side-drawer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  transform: translateZ(0);

  &__form {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition: transform 0.4s ease-out, visibility 0.4s;
    background-color: white;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);

    .panel {
      font-size: 13px;
      line-height: 18px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.4s ease-out;
  }

  &--small &__form {
    width: 560px;
  }

  &--large &__form {
    width: 800px;
  }

  &--left {
    left: 0;

    .data-entry-side-drawer__form {
      left: 0;
    }
  }

  &--right {
    right: 0;

    .data-entry-side-drawer__form {
      right: 0;
    }
  }

  &--open &__form {
    transform: translateX(0);
    visibility: visible;
  }

  &--open &__overlay {
    background-color: black;
    opacity: 0.4;
  }

  &--closed {
    pointer-events: none;
  }

  &--closed &__form {
    visibility: hidden;
  }

  &--closed.data-entry-side-drawer--left &__form {
    transform: translateX(-200%);
  }

  &--closed.data-entry-side-drawer--right &__form {
    transform: translateX(200%);
  }

  &--closed &__overlay {
    background-color: black;
    opacity: 0;
  }

  &--small,
  &--large {
    .data-entry-header {
      padding: 32px;
    }

    .layout-data-entry-form {
      > article {
        padding-left: 32px;
        padding-right: 32px;
      }

      &__field {
        padding: 0;
      }
    }
  }

  .layout-data-entry-form > article > .tabs__list {
    margin-bottom: 16px;
  }
}
