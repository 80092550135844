.meetings-details-container {
  display: flex;
  flex-direction: column;

  .meetings-details-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ReactTable {
    padding-top: 34px;
    border: none;
  }

  h5 {
    font-weight: 400 !important;
    font-family: 'Roboto';
  }

  .ReactTable.table {
    .rt-thead.-header .rt-th {
      border-bottom: 1px solid #cecece !important;
    }

    .table__noData {
      .h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        padding-top: 400px;
      }
    }
  }
  #search_container {
    height: 3.077rem;
    width: 375px;
    margin-left: 8px;
    font-size: 15px;
    float: left;
    .search__btn--close-no-label {
      margin-top: 1px;
    }
  }
  .search_ddOptions {
    padding-left: 12px;
  }

  .input__placeholder {
    color: black;
  }

}