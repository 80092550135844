.general-inflow {

  .review-disclosure-banner {

    .review-disclosure-banner-btn {
      height: 20px;
      line-height: 0;
      float: right;
    }

    .btn-skin--borderless{
      border: none;
    }
  }

  .inflow-section {

    .input-wrapper {
      width: 65%
    }

    .inflow-section-grid-container {
      display: grid;
      grid-template-columns: 34% minmax(16ch, auto) 12ch 10ch;
      padding: 0 16px;
      gap: 8px;
      align-items: center;

      .inflow-time-frame-error-msg {
        grid-column: 2 / span 3;
        line-height: 18px;
        font-family: 'Roboto';
        font-size: 13px;
        color: #D04532
      }

      .owner-planning-period {
        grid-column: 2 / -1;
        line-height: 40px;
      }
      .warning-color {
        color: #AC5300;
      }

      .range-inputs {
        display: flex;
        align-items: center;

        .between {
          width: 80px;
          text-align: center;
        }
      }

      .empty-grid-2-columns {
        grid-column: 3 / span 2;
      }

      .warning-message {
        grid-column: 1 / -1;

        .alert__actions {
          align-self: center;
          margin: -8px 0 -10px 0;
        }
        .btn-skin--borderless:not(.btn__icon-only) {
          color: #3D4042;
        }

        .btn-skin--borderless {
          border: none;
        }
      }
    }

    .quick-slide-info-card {
      border: none;
      box-sizing: border-box;
      position: absolute;
      top: 600px;
      box-shadow: 0 3px 5px 0 rgb(0 0 0 / 20%);
      transform: translateY(0);
      z-index: 10;
      left: 25%;
      max-width: 50%;

      &__content {
        border: 1px solid #cecece;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        padding: 16px;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        z-index: 10;
        background-color: #fff;
      }

      &__header {
        font-size: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
      }

      &__lg {
        font-size: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
      }

      &__expanded {
        left: 15%;
        max-width: 70%;
        border: none;
        box-sizing: border-box;
        position: absolute;
        top: 600px;
        box-shadow: 0 3px 5px 0 rgb(0 0 0 / 20%);
        transform: translateY(0);
        z-index: 10;
      }
    }
  }
}
