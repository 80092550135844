.family-asset-details-table {
  display: grid;
  grid-template-columns: 33% 25% 25%;
  gap: 10px 20px;
  margin-top: 16px;
  margin-left: 72px;

  * {
    margin-right: 10px;
  }

  > label {
    font-weight: 500;
  }

  > span {
    margin-top: 8px;
  }

  > .input-wrapper {
    width: 100%;
    height: 40px;
  }
}

.family-goal-container {
  padding: 0 48px;
  display: flex;
  flex-direction: row;
}

.family-goal-live-preview {
  margin-top: 40px;
  margin-left: 48px;
  flex-grow: 0;
  flex-basis: 33%;
}

.non-lifestyle-goal-live-preview {
  margin-top: 40px;
  margin-left: 48px;
  flex-grow: 0;
  flex-basis: 33%;
}

.family-asset-details {
  padding: 0 48px;
}

.new-family-goal-page-cta {
  text-align: center !important;
  margin: 100px auto 16rem auto;
  width: 689px;

  ul {
    width: 145px;
  }

  h2 {
    font-size: 24px;
    width: 689px;
    margin: 0 auto 15px;
  }

  div[role=presentation] {
    z-index: 300;
    transform: translateZ(0);
  }
}

.add-goal-dropdown-menu .btn--medium {
  width: 118px !important;
  text-align: center;
  margin-top: 8px;

  .dropdown__panel {
    width: 200px;
  }
}

.add-goal-dropdown-menu-item {
  width: 180px !important;
  text-align: left !important;
}

.add-family-goals_title {
  padding-left: 48px !important;
}

.family-asset_details_header {
  padding-left: 48px !important;
  width: 95%;
  margin-bottom: 0px !important;
}

.family-summary-accordions {
  .accordion__item {
    border-style: hidden !important;
  }
}


.paddingright-xl2 {
  padding-right: 38px;
}

.beneficiary-goal-table-numeric-columns {
  @extend .display-flex;
  @extend .flex-column;
  @extend .justify-content-center;
  @extend .align-items-end;
  @extend .height-100p;
}

.add-new-goal-entry-form {
  .data-entry-footer__button-delete {
    color: $color-red-800 !important;
  }

  .radio__input {
    display: none;
  }
}

.goals-table-delete-icon {
  margin-left: 122px;
}