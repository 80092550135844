@import '../abstracts/colors';

$raa-dollar-view: (
        name: "dollar-view",
        left-columns: 47fr repeat(2, 14fr) repeat(2, 11fr) 152px,
        right-columns: 14fr 4fr,
        left-columns-start: 1,
        left-columns-end: 7,
        right-columns-start: 8,
        right-columns-end: 10,
        input-width: 132px
);

$raa-dollar-view-without-ipc: (
        name: "dollar-view-without-ipc",
        left-columns: 54fr repeat(2, 14fr) 152px,
        right-columns: 14fr 4fr,
        left-columns-start: 1,
        left-columns-end: 5,
        right-columns-start: 6,
        right-columns-end: 8,
        input-width: 132px
);

$raa-percentage-view: (
        name: "percentage-view",
        left-columns: 38fr repeat(2, 10fr) repeat(2, 10fr) 120px,
        right-columns: 10fr 4fr,
        left-columns-start: 1,
        left-columns-end: 7,
        right-columns-start: 8,
        right-columns-end: 10,
        input-width: 100px
);

$raa-percentage-view-without-ipc: (
        name: "percentage-view-without-ipc",
        left-columns: 58fr repeat(2, 12fr) 120px,
        right-columns: 10fr 4fr,
        left-columns-start: 1,
        left-columns-end: 5,
        right-columns-start: 6,
        right-columns-end: 8,
        input-width: 100px
);

@mixin revise-allocation-grid-make($views-map) {
  @for $index from 1 through length($views-map) {
    $current-view: nth($views-map, $index);

    .revise-allocation-subclass-grid {
      &.revise-allocation-#{map-get($current-view, name)} {
        grid-template-columns: #{map-get($current-view, left-columns)} 1px #{map-get($current-view, right-columns)};

        .grid-left-contents {
          grid-column: #{map-get($current-view, left-columns-start)}/#{map-get($current-view, left-columns-end)};
          grid-template-columns: #{map-get($current-view, left-columns)};
        }

        .grid-right-contents {
          grid-column: #{map-get($current-view, right-columns-start)}/#{map-get($current-view, right-columns-end)};
          grid-template-columns: #{map-get($current-view, right-columns)};
        }

        .input-wrapper {
          width: #{map-get($current-view, input-width)};
        }
      }
    }

    .revise-allocation-class-grid {
      &.revise-allocation-#{map-get($current-view, name)} {
        grid-template-columns: #{map-get($current-view, left-columns)} 1px #{map-get($current-view, right-columns)};

        .grid-left-contents {
          grid-column: #{map-get($current-view, left-columns-start)}/#{map-get($current-view, left-columns-end)};
          grid-template-columns: #{map-get($current-view, left-columns)};
        }

        .grid-right-contents {
          grid-column: #{map-get($current-view, right-columns-start)}/#{map-get($current-view, right-columns-end)};
          grid-template-columns: #{map-get($current-view, right-columns)};
        }

        .input-wrapper {
          width: #{map-get($current-view, input-width)};
        }
      }
    }

  }
}

.revise-asset-allocation {
  margin-bottom: 120px;

  &__header-subtitle {
    color: $color-nt-grey-550;
    white-space: pre-wrap;
  }

  &__table {
    margin-right: 48px;
    margin-left: 48px;
    padding-bottom: 48px;
    font-size: 13px;

    &__header {
      text-transform: uppercase;
      border-left: 12px solid transparent;
      height: 48px;
      align-items: center;

      .grid-left-contents,
      .grid-right-contents {
        align-items: center;
        border-bottom: 1px solid $color-nt-grey-400 !important;
        height: inherit;
      }

      .grid-divider {
        height: 48px;
      }
    }

    &__footer {
      font-size: 15px;
      font-weight: 500;
      border-left: 12px solid transparent;
      height: 48px;
      align-items: center;

      .grid-left-contents {
        margin-left: -10px;
      }

      .grid-left-contents,
      .grid-right-contents {
        align-items: center;
        border-bottom: none !important;
      }

      .grid-footer-alloc-error {
        margin-top: -10px;
      }

      .grid-divider {
        height: 48px;
      }
    }

    &__content {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      margin-top: -1px;
    }

    @include revise-allocation-grid-make($raa-dollar-view $raa-dollar-view-without-ipc $raa-percentage-view $raa-percentage-view-without-ipc);

    .revise-allocation-subclass-grid {
      display: grid;
      grid-column-gap: 16px;

      .grid-left-contents {
        padding-left: 32px;

        display: grid;
        grid-column-gap: 16px;
        border-bottom: 1px solid $color-nt-grey-200;
      }

      .grid-divider {
        border-right: 1px solid $color-nt-grey-200;
      }

      .grid-right-contents {
        display: grid;
        grid-column-gap: 16px;
        border-bottom: 1px solid $color-nt-grey-200;
      }

      .ipc-range {
        border-bottom: 1px solid $color-nt-grey-200;
        grid-column: 4/6;
        padding: 4px 0;
      }

      .input-wrapper {
        input[type="text"] {
          text-align: right;
        }

        &.warning-bg {
          input[type="text"] {
            border: 1px solid $color-orange-600;
          }
        }
      }

      .ipc-range-info-icon {
        width: 15px;
        height: 15px;
        color: $color-orange-600;
      }

      .popoverContainer {
        margin: 0;

        .popoverContainer__popover {
          line-height: 18px;
        }
      }

      .checkbox {
        align-self: flex-start;
        margin: 0;
      }
    }

    .revise-allocation-class-grid {
      display: grid;
      grid-column-gap: 16px;
      font-weight: bold;

      .grid-left-contents {
        padding-left: 22px;

        display: grid;
        grid-column-gap: 15px;
        border-bottom: 1px solid $color-nt-grey-200;

        .proposed-column {
          padding-right: 2.2rem;
        }
      }

      .grid-divider {
        border-right: 1px solid $color-nt-grey-200;
      }

      .grid-right-contents {
        display: grid;
        grid-column-gap: 16px;
        border-bottom: 1px solid $color-nt-grey-200;
      }

      .ipc-range {
        border-bottom: 1px solid $color-nt-grey-200;
        grid-column: 4/6;
        padding: 4px 0;
      }

      .input-wrapper {
        input[type="text"] {
          text-align: right;
        }

        &.warning-bg {
          input[type="text"] {
            border: 1px solid $color-orange-600;
          }
        }
      }

      .ipc-range-info-icon {
        width: 15px;
        height: 15px;
        color: $color-orange-600;
      }

      .popoverContainer {
        margin: 0;

        .popoverContainer__popover {
          line-height: 18px;
        }
      }

      .checkbox {
        align-self: flex-start;
        margin: 0;
      }
    }

    .excluded-subclass {
      color: $color-nt-grey-400;

      .input-wrapper {
        input {
          color: $color-nt-grey-400;
        }
      }
    }

    .revise-allocation-subclass-row , .revise-allocation-assetclass-row {
      line-height: 40px;

      .subclass-name {
        display: flex;
        align-items: center;
        line-height: 20px;
        height: 100%;

        .risk-control-label {
          font-size: 11px;
          align-items: center;
        }
      }
    }

    .aqua-bg {
      background-color: rgba($color-aqua-500, 0.1);
      position: relative;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        height: 100%;
        width: 8px;
        display: block;
        content: "";
        background-color: rgba($color-aqua-500, 0.1) !important;
      }

      &::before {
        left: -8px;
      }

      &::after {
        right: -8px;
      }
    }

    .grey-bg {
      background-color: $color-nt-grey-050;
      position: relative;
      margin-top: 1px;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        height: 100%;
        width: 8px;
        display: block;
        content: "";
        background-color: $color-nt-grey-050 !important;
      }

      &::before {
        left: -8px;
      }

      &::after {
        right: -8px;
      }
    }

    .color-aqua-800 {
      color: $color-aqua-800;
    }

    .error-bg {
      background-color: #FFE8E6;
    }
  }

  .menu-divider {
    padding: 0;
    margin: 8px -16px;
    border-bottom: 1px solid $color-nt-borders;
  }
}

.one-year-of-cash {
  &__popover-content {
    width: 280px;
  }

  &__popover-title {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  }

  &__goal-item {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 18px;
    margin-top: 8px;
  }

  &__goal-item-bold {
    font-weight: bold;
  }
}