.new-client-profile-page {
  width: 100%;
  height: 100%;
  padding: 24px 32px 24px 32px;

  .client-table {
    max-width: 100%;
    height: auto;
    min-height: calc(100vh - 112px);
    background-color: white;
    padding: 24px;
  }

  .container {
    padding: 0;
    max-width: none !important;
  }

  .requiredAlert {
    color: #d04532;
  }

  div#genderInput-DropdownField,
  div#genderInput .dropdown__panel.display-block.overflowy-auto {
    margin-left: -3px;
  }

  .errorMessage {
    margin-top: 4px;
  }

  .noMargin {
    margin-top: 0;
  }

  .row-margin {
    margin-top: 8px;
  }

  .dropdown {
    .dropdown__item--selected {
      //background: none;
      color: #3d4042;
    }

    .input__field, .dropdown__item {
      font-weight: 400;
    }
  }

  .profileDetails,
  .effectiveTaxRates,
  .totalInput {
    margin-left: 200px;
  }

  label.h5.label {
    margin: auto;
    padding-top: 11px;
  }


  .linked-partner {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    .linked-partner-label {
      max-width: 150px;
      margin-right: 59px;
      flex-grow: 1;
    }

    margin-bottom: 18px;

    .linked-partner-content {
      width: 300px;
      display: block;
      align-items: center;
      justify-content: space-between;
      flex-grow: 2;
    }

    .full-name-and-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .remove-button {
      .btn-skin--borderless {
        color: #BF3B2A;
      }
    }
  }

  .firstNameLabel {
    //float: none !important;
  }

  .linked-partner-full-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .h4 {
    margin-top: 0;
  }

  .h4.sections {
    margin-top: 50px;
  }

  .section {
    margin-bottom: 16px;
  }

  .parent label,
  input {
    display: inline;
  }

  .parent label {
    float: left; /* if you had floats before? otherwise inline-block will behave differently */
  }

  .parent input,
  .parent .SingleDatePicker,
  #genderInput {
    width: 300px;
  }

  .input-container label {
    font-size: 13px;
  }

  .effectiveTaxRates .input-container,
  .profileDetails .input-container,
  .totalInput .input-container {
    width: 100px;
  }

  .effectiveTaxRates .input-container input,
  .totalInput .input-container input {
    width: 50px;
  }

  div#accreditedStatusInput {
    margin-left: 65px;
  }

  div#allocationTypeInput {
    margin-left: 79px;
  }

  .profileDetails .input-container input {
    width: 24px;
  }

  div#genderInput {
    margin-left: 161px;
  }

  div#stateOfResidencyInput {
    margin-left: 91px;
    font-size: 13px;
  }

  .marginright-lg.overflowx-hidden {
    font-size: 13px;
  }

  .marginright-md {
    margin-right: 12px;
  }

  #federalInput,
  #nameInput,
  #totalInput,
  .profileDetails,
  .effectiveTaxRates {
    font-size: 13px;
  }

  .alert-skin--error {
    margin: 8px 13px 13px 13px;
  }

  .alertBannerStyleName {
    font-weight: 500;
  }

  .font-weight-400 {
    font-weight: 400;
  }

  input#birthdateInput,
  input#federalInput {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13px;
  }

  .profileDetails {
    .btn-skin--borderless {
      border: none !important;
    }
  }

  .personalHorizonStyle {
    margin-left: 200px;
  }

  input#personalPlanningHorizonInput,
  input#federalInput,
  input#stateAndLocalInput,
  input#totalInput,
  input#capitalGainsInput,
  input#allInclusiveFeeInput,
  input#ageInput,
  input#middleInitialInput {
    width: 88px;
  }

  .personalPlanningHorizonField {
    position: relative;

    .input-inline-label {
      left: 40px;
    }
  }

  .input-inline-label, b {
    font-weight: 500
  }

  .input-inline-label {
    font-size: 13px;
    font-weight: 500;
  }

  .percent-field b {
    font-weight: 800;
  }

  #ageInput__description {
    font-size: 12px;
    margin-left: 305px;
    margin-top: -30px;
    color: #767676;
    font-weight: 400;
    position: relative;
  }

  .yearsInputDescriptionWithErrors {
    bottom: 22px;
    margin-bottom: 20px;
  }

  .yearsInputDescriptionSansErrors {
    margin-bottom: 18px;
  }

  .inlineInputError {
    margin-left: 200px;
    margin-top: 4px;
  }

  .color-text--error {
    font-size: 12px;
  }

  .totalTax-alignment {
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 25px;
  }

  .birthdateField {
    width: 300px;
  }
}
