@import "../abstracts/colors";

.intro-deck {
  min-height: 100%;
  padding: 48px 48px 48px 100px;
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;

    .nt-logo {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__body {
    display: flex;
    flex: 1;

    .what-is-gdwm {
      hr {
        width: 32px;
        margin: 16px 0;
        color: $color-aqua-300;
      }

      span {
        font-weight: 500;
        font-size: 30px;
        line-height: 150%;
        color: $color-aqua-300;
        margin-bottom: 32px;
      }

      ul {
        font-weight: 300;
        font-size: 26px;
        line-height: 150%;

        li {
          margin-bottom: 16px;
        }
      }
    }

    .scenario-image {
      object-fit: contain;
    }

    .welcome-title {
      margin-top: 2.2em;
      font-weight: 300;
      font-size: 72px;
      line-height: 120%;
      margin-bottom: 24px;
    }

    .welcome-message {
      font-weight: 400;
      font-size: 20px;
      line-height: 170%;
    }

    .start-button {
      width: 160px;
    }
  }

  &__title {
    margin-top: 32px;
    font-weight: 300;
    font-size: 56px;
    line-height: 120%;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 170%;
  }

  &__footer {
    .btn-skin--primary {
      border-color: white;
      background-color: white;
      border-radius: 50%;
      margin-right: 48px;

      i {
        color: $color-aqua-sidebar;
        font-size: 24px;
      }

      &:focus {
        box-shadow: 0 0 0 2px $color-nt-grey-900;
      }

      &:active,
      &:focus,
      &:hover:not([disabled]) {
        background-color: $color-aqua-900;

        i {
          color: white;
        }
      }
    }
  }

}