.goal-timeframe-underlined-header {
  padding-bottom: 6px !important;
}
.goal-glidepath-underlined-header {
  padding-bottom: 3px !important;
}

.goal-time-frame-error-msg {
  grid-column: 2 / span 1;
  line-height: 18px;
  font-family: 'Roboto';
  font-size: 13px;
  color: #D04532;
}
.error-color {
  color: #D04532;
}
