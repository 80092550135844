.ticker-tab {
  margin: 8px 12px;

  &__extra-margin {
    margin: 8px 16px;
  }

  .title {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #3D4042;
  }

  .value {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
  }
}

.ticker-tab-spacer {
  width: 2px;
  height: 48px;
  background: $color-nt-grey-050;
}

.ticker-tabs-container {
  display: flex;
}