.family-tree-zoom {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 5px;
  left: 27px;

  .zoom-button {
    margin-bottom: 22px;
    border: none !important;
    box-shadow: none !important;
    height: 18px;
    width: 18px;
    line-height: 18px;

    .icon {
      line-height: 18px;
    }
  }
}