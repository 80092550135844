.estate-flowchart {
  .review-fiduciaries-button-header {
    height: 40px;
    border: 1px solid white;
    color: white;
  }

  .add-trust-button-text {
    margin-bottom: 1rem;
    font-size: 24px;
  }

  .add-trust-button {
    .dropdown__panel--right {
      left: -50%;
    }
  }

  .estate-flowchart-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
  }

  .flowchart-edit-button {
    display: flex;
    justify-content: flex-end;
    padding: 16px 48px 16px 16px;
  }

  .presentation-pane-header {
    border-bottom: none;
    padding-bottom: 32px;
  }

  .presentation-pane-header-nav {
    border-bottom: none;
    padding: 0px 48px 10px;
    width: 100%;
    background-color: #063b3f;
    display: flex;

    .display-name {
      margin-right: 16px;
      cursor: pointer;
    }
  }

  .presentation-pane-display-name-text {
    padding-bottom: 8px;
  }

  .presentation-pane-display-name-text--active {
    border-bottom: 4px solid white;
    padding-bottom: 8px;
  }
}

.add-form-header {
  display: flex;
  justify-content: space-between;
  padding: 24px 32px 24px 48px;
  background-color: white;
  z-index: 10;
  border-bottom: 1px solid #cecece;
  height: 97px;
  position: sticky;
  top: 0;

  h3 {
    font-family: Roboto, sans-serif;
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 30px !important;
    text-align: left;
  }
}

.review-fiduciaries-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .add-form-header {
    border-bottom: none;
  }

  .review-fiduciaries-content {
    padding-left: 48px;
    display: flex;
    width: 100%;

    .fiduciary-column {
      h4 {
        padding-bottom: 8px;
        border-bottom: 1px solid #cecece;
      }

      width: 550px;

      &.right {
        margin-left: 96px;
      }

      .layout-data-entry-form__field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
          width: 100%;
        }

        .input-wrapper {
          width: 100%;
        }

      }
    }
  }
}

.revocable-trust-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .revocable-trust-content {
    display: flex;
    width: 100%;

    .sub-nav-container {
      display: flex;
      flex-direction: column;
      width: 275px;
      margin-left: 48px;
      padding-top: 24px;

      ul.sub-nav-list {
        border-left: 1px solid #cecece;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: sticky;
        top: 121px;

        li {
          height: 36px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        li.sub-nav-item.sub-nav-jumplink {
          padding-left: 30px;
        }

        li.sub-nav-item {
          padding-left: 18px;
          color: gray;
        }

        li.sub-nav-item--active {
          padding-left: 14px;
          border-left: 4px solid #05676e;
          color: black;
        }

        li.sub-nav-item--active.sub-nav-jumplink {
          padding-left: 30px;
          margin-left: -4px;
        }
      }
    }

    .revocable-trust-form {
      width: calc(100% - 323px);
      border-left: 1px solid #cecece;
      padding-left: 36px;
      padding-top: 24px;
      padding-bottom: 25px;

      .sub-section-header{
        .btn-skin--borderless:focus {
          border: 1px solid #fff;
          box-shadow: none;
          text-decoration: none;
        }
        .btn-skin--borderless:hover {
          text-decoration: underline;
        }
      }

      .alertBannerContainer {
        margin: -24px 0px 0px -36px;
      }

      .form-container {
        display: flex;
        flex-direction: column;
        align-items: left;
        width: 56%;

        .successor-table-header-grid {
          display: grid;
          margin-top: 8px;
          padding-left: 16px;
          padding-right: 16px;
          grid-template-columns: 48px auto auto;

          #successor-name {
            display: flex;
            grid-column-start: 2;
            grid-column-end: 4;
            height: 40px;
            align-items: center;
            font-family: RobotoCondensed, Roboto, sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.5px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          #successor-order {
            display: flex;
            height: 40px;
            align-items: center;
            width: 38px;
            font-family: "RobotoCondensed", sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.5px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
        }

        .successor-input-grid {
          display: grid;
          padding-left: 16px;
          padding-right: 16px;
          grid-template-columns: 48px auto auto;
          margin-bottom: 8px;

          .trust-details__successor-row {
            &:last-child {
              border-bottom: 1px solid #cecece;
            }

            display: flex;
            grid-column-start: 1;
            grid-column-end: 4;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #cecece;
            height: 52px;
            padding: 4px 0px 4px 0px;
            margin-bottom: 0px;

            .drag__icon {
              margin: auto 24px auto 8px;
              color: $color-nt-text;
            }

            .delete-successor-trustee {
              color: #3d4042;
              margin: 0;
              width: 30px;
              cursor: pointer;
            }
          }
        }

        .required-header {
          display: flex;
          align-items: center;
        }

        .layout-data-entry-form__field {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;

          div.name-input {
            width: 100%;

            .parent {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 0 !important;
            }
          }

          input {
            height: 40px;
          }

          .input-wrapper {
            width: 100%;
          }

          .gpi-datepicker {
            width: 100%;
          }

          .textarea-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .textarea-limit-count {
              margin-top: 16px;
              font-family: Roboto, sans-serif;
              font-size: 12px;
            }
          }
        }

        .basic-information-form {
          margin-bottom: 48px;
        }

        .dds-ms {
          div.position-relative.display-flex.align-items-center.justify-content-between {
            button {
              right: 1px;
              height: 24px;
              top: 5px;
            }
          }

          div.input__divider {
            display: none;
          }

          button.iconNameActive {
            height: 28px;
            top: 4px;
          }

          i.icon--error {
            display: none;
          }
        }

        span:has(.dds-ms) {
          width: 100%;
        }

        .sub-section-header {
          padding: 0px 16px 0px 16px;
          margin-bottom: 0px;
          text-align: left;
          line-height: 18px;
        }

        .sub-section-subtitle {
          font-size: 13px;
          margin-top: -5px;
        }

        .textarea-500-limit {
          width: 100%;
        }

        .trust-details-form {
          .basic-information__trust-jurisdiction > span {
            width: 100%;

            .dropdown__item {
              font-size: 15px;
            }
          }
          
        }

        .beneficiary-first-death-form {
          min-height: 68vh;

          .header {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            h1 {
              font-family: Roboto, sans-serif;
              font-size: 30px;
              font-weight: 500;
              line-height: 36px;
              text-align: left;
            }

            h6 {
              margin: 0;
              font-family: Roboto, sans-serif;
              font-size: 15px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
            }
          }

          .add-or-copy-distribution-container {
            .add-copy-trust-heading {
              font-family: Roboto, sans-serif;
              font-weight: 300;
              font-size: 24px !important;
              line-height: 30px !important;
              margin-top: 48px;
            }

            .copy-distribution-button {
              border-left: 1px solid #B1B1B1;
              margin-left: 24px;
              padding-left: 8px;
            }
          }

          .trust-form {
            width: 100%;
            border-top: 4px #05676e solid;
            margin-bottom: 48px;

            .trust-form-header {
              padding-top: 16px;
              padding-bottom: 16px;
            }

            .trust-delete-button {
              color: #3D4042;
              cursor: pointer;
              min-width: 40px;
            }

            .vertical-radiogroup {
              text-align: left;

              .radiogroup__label {
                display: none;
              }
            }

            .bank-icon {
              padding-left: 8px;
              color: #05676e;
            }

            span:has(.beneficiaryDropdown) {
              width: 100%;
            }

            .beneficiaryDropdown {
              text-align: left;
            }

            .dropdown__item {
              font-size: 15px;
            }

            .basic-information__distribution {
              .distributions-sub-text {
                line-height: 15px;
              }
              .error {
                border: 1px solid red;

                &:focus {
                  outline: 2px solid red;
                }
              }
            }

            .powers-of-appointment {
              margin-top: 24px;

              .powers-of-appointment-header {
                width: 564px;
                height: 40px;
              }

              .add-powers-of-appointment-button {
                padding-right: 0px;
                  &:focus {
                    border: 1px solid #fff;
                    box-shadow: none;
                    text-decoration: none;
                  }
                  &:hover {
                    text-decoration: underline;
                  }
              }

              .power-of-appointment-row {
                width: 564px;

                .power-of-appointment-row-header {
                  width: 531px;
                  height: 43px;
                  font-family: RobotoCondensed, Roboto, sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 15px;
                  letter-spacing: 0.5px;
                  font-stretch: condensed;
                  margin-left: 16px;
                  padding: 0px;
                }

                .delete-button {
                  color: #3D4042;
                  margin-right: 0px;
                  width: 18px;
                  cursor: pointer;
                  min-width: 40px;
                }

                .power-holder-dropdown {
                  text-align: left;
                }

                span:has(.power-holder-dropdown) {
                  width: 100%;
                }
              }

              .powers-of-appointment-bottom-button {
                border-top: 1px solid #CECECE;
                height: 40px;
                width: 545px;
              }
            }
          }
        }
      }
    }
  }
}

.form-footer {
  display: flex;
  justify-content: space-between;
  padding: 24px 48px 24px 48px;
  background-color: white;
  border-top: 1px solid #cecece;
  height: 88px;
  position: sticky;
  bottom: 0;

  .save-next-btn-container {
    display: flex;
    justify-content: space-between;
    width: 175px;
  }
}
