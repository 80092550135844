.profile-details-container {
  .profile-details-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .greyed-out {
    color: $color-nt-grey-550;
  }

  .search-for-clients {
    justify-content: center;
    display: flex;
  }

  .search-for-clients p {
    height: 48px;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #3D4042;
    margin-top: 48px;
    max-width: 400px;
  }

  .create-new-proposal {
    font-weight: 500;
    color: $color-aqua-800;
    margin: 0 !important;
  }

  .ReactTable {
    padding-top: 34px;
    border: none;
  }

  .proposal-name {
    font-weight: 500;
    font-family: 'Roboto';
  }

  h5 {
    font-weight: 400 !important;
    font-family: 'Roboto';
  }

  .ReactTable.table {
    .rt-thead.-header .rt-th {
      border-bottom: 1px solid #cecece !important;
    }

    .table__noData {
      .h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        padding-top: 250px;
      }
    }

    .profileProposalMenu {
      margin-left: -16px;

      button {
        border: none;
      }
    }
  }

  .ReactTable .rt-thead .rt-resizable-header:last-child {
    padding-left: unset;
  }

  .loading-proposals {
    text-align: center;
    .h3 {
      font-weight: 300;
      color: #3D4042;
      font-size: 24px;
    }
  }

  .menuItem {
    border-bottom: 1px solid;
    border-color: $color-nt-borders;
    padding: 0;
    margin: 3px -18px;
  }
}

.profile-details-action-menu {
  display: inline-block;
}

.col.meeting-actions-section {
  min-width: 296px;
  max-width: 296px;

  margin-top: 48px;
  padding: 0;
}

.profile-updates {
  .h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #3d4042;
    hr {
      color: #949494;
      height: 1px;
    }
  }
  padding-top: 100px;
  max-width: 45%;
}

.conditions {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}

.table-spacing {
  margin-bottom: 24px;
}

.ReactTable.table.conditons-table {
  padding-top: unset;

  .rt-resizable-header-content {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #3D4042;
  }

  .condition-detail {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 10px;
    color: #3D4042;
  }
}
