*[data-theme="aqua"],
*[data-theme="aqua"] * {
  color: white;
}

*[data-theme="aqua"] .page-action-menu {
  button i {
    margin-left: 1px;
  }

  .dropdown__panel * {
    color: $color-nt-text;
  }
}

*[data-theme="aqua"] .btn-skin--secondary,
*[data-theme="aqua"] .page-action-menu button {
  border-color: white;

  &:focus,
  &:active,
  &:hover:not([disabled]) {
    color: $color-aqua-sidebar;
    border-color: white;
    background-color: white;

    /* stylelint-disable-next-line no-descending-specificity */
    * {
      color: $color-aqua-sidebar;
    }
  }
}

.layout-split-sidebar > aside[data-theme="aqua"],
.layout-split-sidebar > section[data-theme="aqua"] {
  background-color: $color-aqua-sidebar;
}

.layout-split-left > aside[data-theme="aqua"],
.layout-split-left > section[data-theme="aqua"] {
  background-color: $color-aqua-sidebar;
}
