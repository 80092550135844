.meeting-portal {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .login-container {
    background: #ffffff;
    width: 420px;

    .nt-logo {
      padding: 40px 29px 0 29px;
    }

    .login-form {
      padding: 0 59px 66px 54px;

      .login-instructions {
        margin-top: -8px;
        margin-bottom: 23px;
      }

      .input-wrapper {
        width: 100%;

        input {
          height: 40px;
        }
      }

      .label {
        margin-top: 0;
      }

      .meeting-id-input, .meeting-passcode-input {
        margin: 0;
      }

      .meeting-passcode-input .label {
        margin-top: 10px;
      }

      .join-meeting-button {
        margin-top: 24px;
        width: 100%;
      }
    }
  }

  .meeting-portal-meeting-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: end;

    .meeting-portal-image-container {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
    }

    .meeting-portal-visual-content {
      display: flex;
      align-items: end;
      justify-content: center;

      height: 100%;
      width: 100%;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 64px;
      right: 0;
    }

    .meeting-portal-sidebar-left {
      position: relative;
      height: 100%;
      width: 184px;
      background: black;

      .meeting-portal-video-gallery-scroll {
        &-up, &-down {
          position: absolute;
          z-index: 1;
          left: 0;
          right: 0;
          margin: 8px;
          border: 1px solid #dfdfdf;
          background: none;
          border-radius: 4px;
          height: 30px;

          .icon {
            font-size: 25px;
            color: #ffffff;
          }
        }

        &-down {
          top: 544px;
        }
      }

      .meeting-portal-video-gallery {
        position: absolute;
        left: 0;
        top: 38px;
        height: 100%;
        width: 100%;

        [data-ui-id="video-gallery"] > .ms-Stack > div {
          display: flex;
          flex-direction: column;
          height: auto;
          margin-top: 6px;
        }

        div {
          padding: 0;
        }

        video {
          border-radius: 0;
        }

        .ms-Stack {
          background: transparent;
          border-radius: 0;

          .svg {
            color: white
          }
        }

        [data-ui-id="video-tile"] {
          width: calc(100% - 16px);
          height: 95px;
          min-height: 95px;
          margin: 0 8px;
          background-color: #4A4D4F;
        }

        [data-ui-id="video-gallery"] > .ms-Stack > div > .ms-Stack:last-child {
          position: absolute;
          bottom: 46px;
          width: 100%;
        }

        .ms-Button {
          display: none;
        }
      }
    }
  }

  .client-view-pointer {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;

    &--hidden {
      display: none;
    }
  }

  .center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .content-box-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }

  .meeting-content {
    width: 1366px;
    height: 1024px;
    border: none;
    overflow: hidden;
    background-color: white;

    .sidebar {
      display: flex;
      flex-direction: column;
      padding: 32px 48px 32px 48px;
      height: 100%;

      .stacked-bar-chart {
        height: 100%;

        &__label {
          white-space: break-spaces;
        }
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 300;
      line-height: 30px;
    }

    .agenda {
      button {
        display: none;
      }
    }

    .quick-slides__footer {
      display: none;
    }
  }
}

.meeting-portal-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .background-img {
    position: absolute;
    height: 100%;
  }

  .meeting-portal-message-whiteboard {
    img {
      position: relative;
    }

    hr {
      width: 50px;
      max-width: 15%;
      height: 0px;
      border-bottom: 2px solid black;
      border-radius: 24px;
    }

    background: rgba(255, 255, 255, .9); 
    box-shadow: 0px 5px 50px black; 
    border-radius: 24px;
    backdrop-filter: blur(4px);
    min-width: 300px;
    width: 40%;
    height: auto;
    max-height: 80%;
    max-width: 500px;
    min-height: 100px;
    padding: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .meeting-portal-message-text {
      @media (max-width: 900px) {
        font-weight: 300;
        font-size: 18px;
        max-width: 90%;
        text-align: center;
      }
      @media (min-width: 900px) {
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        max-width: 90%;
        text-align: center;
      }
    }
  }
}