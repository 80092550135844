.entry-item-summary {
  position: relative;
  height: fit-content;
  flex-grow: 1;
  padding: $spacing-sm $spacing-2xl;
  color: $color-nt-container-background;
  display: block;

  .hide {
    display: none;
  }

  .header {
    border-bottom: solid 1px $color-nt-container-background;
    clear: both;
    width: 100%;

    .left {
      float: left;
    }

    .right {
      float: right;
    }
  }

  > div {
    width: 100%;
  }

  .content {
    clear: both;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 6px;

    .left {
      float: left;
      width: 70%;

      > div {
        height: 12px;
        width: 12px;
        float: left;
        margin-top: 5px;
        margin-right: 10px;
      }

      > div.withStripe {
        background: repeating-linear-gradient(
                        -45deg,
                        #ffffff,
                        #ffffff 1px,
                        #d99131 4px,
                        #d99131 5px
        );
      }

      > div.withoutStripe {
        background-color: #d99131;
      }

      > label {
        align-content: space-around;
      }
    }

    .right {
      float: right;
    }
  }

}
