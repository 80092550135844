@import '../abstracts/colors';

.asset-reliance-accordion-container {
  grid-column: 1 / span 10;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    padding-left: 16px;

    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        display: flex;
      }
    }
  }

  &.__net-assets {
    grid-row-start: 3;
  }

  &.__goals {
    grid-row-start: 4;
  }

  &.__excess-assets {
    grid-row-start: 5;
  }

  .title-content {
    color: $color-nt-grey-900;
    font-size: 18px;
    margin: 0;
  }

  .subtitle {
    color: $color-nt-grey-550;
    font-weight: 400;
    font-size: 12px;
    margin-top: -4px;
  }

  .accordion__content {
    .accented-accordion-item {
      margin-bottom: 0;
    }

    .accented-accordion-item__accent {
      left: -12px;
      width: 12px;
      position: absolute;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}