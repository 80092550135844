.table-action-menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__dropdown {
    width: 32px;
    height: 40px;

    > button {
      width: 100%;
      height: 100%;
      transform: translateZ(0);
      // box-shadow: none !important;
      border: none !important;

      i {
        transform: translateY(-1px);
      }

      &:focus {
        box-shadow: inset 0 0 0 3px $color-aqua-500;
      }

      &:focus,
      &:hover {
        background-color: $color-aqua-100;
      }
    }
  }

  .dropdown__panel {
    z-index: 200;
    transform: translateZ(0);
  }

  .dropdown__item {
    display: flex;
    align-items: center;

    &:focus {
      box-shadow: 0 0 0 2px $color-aqua-500 !important;
    }
  }
}

.accented-accordion-item__right-content {
  .table-action-menu .dropdown {
    height: 64px;

    button {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.ReactTable.table .table-action-menu {
  left: -4px;
}

.ReactTable.table .rt-tbody .rt-tr:last-child {
  .table-action-menu .dropdown button {
    border-bottom-right-radius: 5px;
  }
}
