.meeting-scheduled-summary {
  .meeting-scheduled-item {
    margin-top: 16px;

    .scheduled-item__icon {
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .scheduled-item__details {
      .row:nth-child(2) {
        margin-top: 2px;
        color: $color-nt-grey-600;
      }
    }

    /* This styles the dashed lines between meeting scheduled calendar icons */
    &:not(:last-child) .scheduled-item__icon .icon:after {
      // content is required for this pseudo-selector to be displayed
      content: "";
      border-left: 1px dashed #B1B1B1;
      // Draws the line up to the correct height and subtracts some for spacing between the line and icon
      height: calc(100% - 2px);
      // Positions the start of the line to draw
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateY(-25%);
    }

  }
}

.schedule-meeting-btn {
  margin-top: 8px;
}

.schedule-meeting-subhead {
  font-size: 15px;
  margin-bottom: 16px;
}

.schedule-meeting-input-container > * {
      margin-bottom: 3px;
}

.schedule-meeting-input-container {

  .schedule-meeting-title {
    input {
      width: 460px !important;
    }
  }
}

.meeting-action-menu-wrapper {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;

  button {
    border: none
  }
}

