.partner-workstation {
  .partner-details {
    display: flex;
    flex-direction: row;
  }

  label {
    margin-right: 109px;
  }
}

.partner_details {
  .row-container {
    padding-left: 12px;
    padding-right: 0px;
  }

  .input-form {
    display: flex;
    align-items: center;
    margin-top: 4px;

    label {
      width: 80px;
      margin-right: 18px;
    }

    .input-box {
      width: 243px;
      height: 40px;
      padding: 1px 9px;
    }

  }

  .search-fields-container {
    margin-top: 64px;
    max-width: 373px;
    margin-right: 4px;
  }

  .search-partner-workstation {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 332px;

    .btn-skin--borderless {
      color: #3d4042;
    }

    #search_partner_workstation_button {
      width: 227px;
      height: 40px;
      padding-right: 12.5px;
      padding-left: 12.5px;
    }
  }

  .search-partner-workstation-button {
    width: 200px;
    min-width: 200px;
    text-align: center;

  }

  .search-for-clients {
    justify-content: center;
    display: flex;
  }

  .search-for-clients p {
    height: 48px;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #3D4042;
    margin-top: 48px;
    max-width: 400px;
  }

  .helper {
    display: flex;
    flex-direction: row;
  }

  .table-spacing {
    margin-top: 1.462px;

    .search-results-header {
      padding-left: 1.462rem;
      border-bottom: 0.077rem solid $color-nt-grey-700;
    }

    .accordion {
      width: 100%;

      .accordion__item {
        height: auto;

        .accordion__button {
          z-index: 1;
        }

        .accordion__button .row {
          align-items: start;
          margin-left: 0;
          margin-right: 0;
        }

        .row {
          .dds-icons.icon {
            margin-top: 8px;
          }

          .col h5 {
            margin-top: 8px;

            .col {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        &--expanded {
          border-bottom: none;
        }
      }
    }
  }

  .nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }

  .search-results {
    margin-top: 39px;
  }

  .expanded-search-results {
    padding: 2.154rem 2.769rem 3.077rem 2.769rem;
    background-color: $color-nt-grey-025;

    .client-details {
      max-width: 26rem;

      .row {
        align-items: baseline;
      }
    }

    .account-details {
      margin-top: 2.538rem;

      &__header, &__entry {
        .col:nth-child(1) {
          max-width: 12.923rem;
        }

        .col:nth-child(2) {
          max-width: 30.154rem;
        }
      }

      &__entry {
        margin-bottom: 1.308rem;

        &:last-child {
          margin-bottom: 2.077rem;
        }
      }

      .row {
        align-items: baseline;

        li {
          list-style: none;
        }
      }
    }

    .agreement_border {
      position: absolute;
      height: 1px;
      left: 483px;
      right: 67px;
      top: 655px;
      background: #CECECE;
    }

    .service-team {
      display: flex;
      flex-direction: column;
      padding-top: 0.615rem;

      .service-team-members {
        padding: 0.462rem 0rem;

        .name {
          max-width: 11.923rem;
        }
      }

      hr {
        width: 100%;
      }

      .link-this-client-container {
        margin-top: 0.923rem;
        flex-grow: 1;
      }
    }
  }

  .search_no_results {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    padding: 0 16rem;
    align-content: center;
  }
}