.standalone-account,
.personal-asset,
.general-inflow,
.personal-liability,
.social-security,
.legal-agreement,
.life-insurance,
.partially-owned,
.equity-compensation,
.edit-vesting,
.held-away-account { // todo: replace these with .asset
  flex-direction: column;

  .data-entry-footer--sticky {
    position: relative;
  }

  .data-entry-footer__button-delete {
    color: $color-red-800 !important;
  }

  &__form {
    display: flex;
    justify-content: space-between;

    > article {
      width: 68%;
    }

    > aside {
      width: 32%;
    }
  }

  .asset-details-section-header {
    justify-content: flex-start !important;
  }

  .dropdown__item {
    font-size: 15px;
  }

  .tax-rate {
    .input-wrapper {
      width: 30%
    }
  }

  .interest-rate {
    .input-wrapper {
      width: 30%
    }
  }

  .inflow-section {
    .input-wrapper {
      width: 65%
    }

    .inflow-section-grid-container {
      display: grid;
      grid-template-columns: 34% minmax(16ch, auto) 12ch 10ch;
      padding: 0 16px;
      gap: 8px;
      align-items: center;

      .inflow-time-frame-error-msg {
        grid-column: 2 / span 3;
        line-height: 18px;
        font-family: 'Roboto';
        font-size: 13px;
        color: #D04532
      }

      .empty-grid-2-columns {
        grid-column: 3 / span 2;
      }
    }
  }
}
