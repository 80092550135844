.net-worth-over-time {
  &__projected-net-worth {
    display: flex;
    align-items: center;
    padding-left: 51px;
    padding-top: 24px;
  }

  &__triple-net-discount-rate {
    display: flex;
    align-items: center;
    padding-left: 51px;
    padding-top: 13px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__triple-net-discount-rate_value {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    width: 48px;
    padding-left: 8px;
    line-height: 24px;
  }

  .years-input-container-with-icons {
    position: relative;
  }

  .input__icon {
    color: #05676E;
    position: absolute;

    &__increase-support-button {
      top: 45%;
      left: 5%;
    }

    &__decrease-support-button {
      top: 85%;
      left: 5%;
    }
  }

  &__age-input {
    width: 100%;
    height: 100%;
  }

  input:focus {
    outline: none !important;
    border: 0 ! important;
  }

  #projectedNetWorthAge {
    background-color: #ffffff;
    border-radius: 6px;
    width: 167px;
    height: 70px;
    font-size: 30px;
    font-weight: 300;
  }

  .input-inline-label {
    height: 72px;
    padding-left: 30%;
    font-size: 18px;
  }

  .net-worth-over-time-body {
    margin-top: 33px;

    .net-worth-over-time-goal-table {
      width: 432px;
      height: 160px;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      padding-left: 80px;
      padding-bottom: 46px;
      margin-top: 20px;
      margin-bottom: 80px;

      .popover__arrow--left:after {
        top: calc(50% + 8px);
        left: 8px;
        z-index: 9;
      }

      .goals-table-title {
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 6px;
      }

      .goals-table-details {
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
      }

      .goals-table-values {
        font-size: 18px;
        font-weight: 300;
        text-align: right;

        &.goals-table-last-row {
          margin-right: 28px;
        }
      }

      .goals-popover-condensed-subtitle {
        font-style: italic;
        font-weight: 400;
        margin-bottom: 16px;
      }

      .goals-popover-categories-and-data {
        li {
          display: flex;
          justify-content: space-between;
          font-weight: 400;
          margin-top: 16px;
        }

        .goals-dot {
          height: 12px;
          width: 13px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6px;

          &.lifestyle-spending-dot {
            background-color: #52897F;
          }

          &.discretionary-dot {
            background-color: #D99131;
          }

          &.philanthropic-dot {
            background-color: #D99131;
          }

          &.family-dot {
            background-color: #FFC24B;
          }
        }
      }
    }
  }

  .net-worth-over-time-chart-plot-line {
    font-family: 'Roboto', sans-serif;
    background-color: #0A7A82;
    color: white;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    border-radius: 3px;
    align-content: center;
    justify-content: center;
    min-height: 36px;
    padding: 8px 16px;
  }

  .net-worth-over-time-page-action-menu-options {
    display: flex;
    justify-content: space-between;

    input {
      accent-color: #3D4042;
    }

    .life-insurance-value-at-death-option {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .three-dots-menu {
    .icon {
      font-size: 18px;
      font-weight: 400;
      color: white;
      padding-right: 8px;

      &:hover {
        color: $color-aqua-800;
        box-shadow: none;
      }
    }
  }

  .insufficient-assets-message {
    padding-left: 200px;
    margin: 100px 100px 100px 170px;

    .asset-reliance-container {
      background-color: rgb(255, 255, 255);
      color: #283A3C;
      border-color: #283A3C;
      &:hover {
        color: rgb(255, 255, 255);
        background-color: #283A3C;
      }
    }
  }
}