@font-face {
  font-family: 'RobotoCondensed';
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  src: local('RobotoCondensed'), local('RobotoCondensed-Regular'), url('https://cdn.northerntrust.com/common/webfonts/roboto/condensed/RobotoCondensed-Regular.ttf') format('truetype');
}

.fontweight-300,
.fontweight-light {
  font-weight: $font-weight-light !important;
}

.fontweight-400,
.fontweight-normal {
  font-weight: $font-weight-normal !important;
}

.fontweight-500,
.fontweight-medium {
  font-weight: $font-weight-medium !important;
}

.condensed-subtitle {
  font-family: 'RobotoCondensed', 'Roboto', sans-serif;
  font-size: 13px;
  font-stretch: condensed;
  font-weight: $font-weight-normal;
  line-height: 15px;
  min-height: 15px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.condensed-subtitle-small {
  font-family: 'RobotoCondensed', 'Roboto', sans-serif;
  font-size: 11px;
  font-stretch: condensed;
  font-weight: $font-weight-normal;
  line-height: 13px;
  min-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.eyebrow-label {
  font-size: 12px;
  font-weight: $font-weight-medium;
  line-height: 24px;
  min-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.required-asterisk {
  color: $color-red-500;
  margin-left: 4px;
}
