.chart-status-line-container {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;

  span {
    white-space: nowrap;
    padding-right: 8px;
    position: relative;
    top: 8px;
  }

  .wpo-label {
    padding-right: 0px;
    position: relative;
    bottom: 9px;
    margin-left: -55px !important;
    width: 50px;
    text-align: right;
    font-size: $font-size-small;
  }


  .horizontal-rule {
    position: absolute;
    left: 0;
    border-radius: 6px;
    margin-right: 0;
  }
}