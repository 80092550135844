.held-away-account {
  .held-away-section {
    .layout-data-entry-form__field {
      padding-bottom: 20px;
    }
    #doesPermitBeneficiary {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 8px;

      label {
        flex-basis: 33%;
        margin-right: 16px;
      }
    }
  }

  li {
    list-style: none;
  }
}