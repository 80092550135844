.nav-drawer-side-menu {
  position: fixed;
  bottom: 0;
  top: 64px;
  left: 200px;
  width: 420px;
  height: calc(100% - 64px);
  padding: 32px;
  z-index: -1;
  transform: translateZ(0);
  background-color: $color-nt-grey-050;
  box-shadow: 5px 0 7px 2px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s, visibility 0.4s;
  overflow: auto;

  &--open {
    transform: translateX(0);
    visibility: visible;
  }

  &--closed {
    transform: translateX(-150%);
    visibility: hidden;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__header-share-point {
    margin-bottom: 5px;
  }

  &__content-share-point {
    padding-top: 29px;
  }

  &__title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
  }

  &__refresh-button {
    padding-left: 1px;
    margin-left: 103px;
  }

  &__share-point-link {
    font-size: 12px;
  }

  &__close-button {
    border: none !important;
    .icon {
      font-size: 18px;
    }
  }

  .accented-accordion-item {
    &__header {
      background: none;
      height: 40px;
    }

    &__left-content {
      padding-left: 0;
      .h4 {
        font-size: 15px;
        font-weight: 500 !important;
      }
    }
  }

}
