.data-entry-side-drawer {
  &--small,
  &--large {
    .data-entry-header {
      padding: 32px;
    }

    .layout-data-entry-form {
      > article {
        padding-left: 32px;
        padding-right: 32px;
      }

      &__field {
        padding: 0;
      }
    }
  }

  .layout-data-entry-form > article > .tabs__list {
    margin-bottom: 16px;
  }
}
