.wealth-plan-optimizer {
  height: 100%;

  .__alert-banner-container {
    padding: 0 48px;
  }

  .populated-page {
    background-color: $color-nt-grey-050;
    height: 100%;
  }

  .add-strategy-button-header {
    .btn-skin--primary {
      background-color: white;
      color: $color-nt-grey-700;

      &:focus {
        box-shadow: 0 0 0 2px $color-nt-grey-900;
        outline: $color-aqua-500 3px solid;
      }

      &:hover {
        color: $color-aqua-950 !important;
        background-color: $color-aqua-100 !important;
      }
    }
  }

  .line-overlay-container {
    position: relative;

    .line-overlay {
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1px;
      background-color: $color-nt-grey-500;
    }
  }

  .no-strategy-container {
    background-color: $color-nt-container-background;

    align-content: center;
    width: 100%;
    margin-top: 160px;

    .empty-state-container--large {
      max-width: 100%;
      margin: 0;
    }

    .add-strategy-dropdown {
      .dropdown__panel {
        text-align: left;
        left: 50%;
        right: auto;
        transform: translate(-50%, 0);
      }

      .btn-skin--primary {
        &:focus {
          outline: $color-aqua-500 3px solid;
        }
      }
    }
  }

  .wealthpo-summary-container {
    background-color: $color-nt-grey-050;

    .summary-statement-card {
      background: $color-nt-container-background;
      border: 1px solid $color-nt-grey-100;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      padding: 24px;
      font-size: 24px;
      line-height: 30px;
      font-weight: 300;
    }

    .overview-section-with-sor-card {
      height: 100%;
      padding: 24px;
    }

    .estimated-impact-summary-section-with-sor-card {
      height: 371px;
      padding: 0 24px 0 24px;
      grid-gap: 24px;
    }

    .estimated-impact-summary-section-without-sor-card {
      height: 321px;
      padding: 24px 24px 0 24px;
    }

    .estimated-impact-summary-section {
      flex-direction: row;
      align-items: center;
      width: 100%;
      display: flex;

      .future-estate-value-container {
        width: 100%;
        height: 100%;
        max-width: 647px !important;

        border: 1px solid $color-nt-grey-100;
        border-radius: 5px;
        background: $color-nt-container-background;
        padding: 24px 32px 24px 32px !important;
      }

      .future-estate-value-header {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 71px;

        h4 {
          margin: 0;
        }

        > div {
          padding-top: 2px;
          display: flex;
          justify-content: space-between;
          vertical-align: middle;
        }
      }

      .future-estate-value-footer {
        width: 100%;
        height: 18px;
        padding-left: 42px;

        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, 1fr);

        > span {
          font-family: Roboto, sans-serif;
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          text-align: center;
        }
      }

      .future-estate-value-bar-chart-container {
        width: 100%;
        padding: 24px 0 24px 0;
      }

      .future-estate-value-bar-chart-container-with-sor-card {
        height: 281px;
      }

      .future-estate-value-bar-chart-container-without-sor-card {
        height: 200px;
      }

      .future-estate-value-bar-charts {
        height: 100%;
        width: 100%;
        align-items: flex-end;
        position: relative;
        display: flex;

        .wpo-bar-chart-container {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .strategies {
          position: absolute;
          height: 100%;
          width: 100%;
          padding-left: 42px;
          z-index: 1;

          align-items: center;
          display: flex;
          flex-direction: row;
          flex-grow: 1;
        }

        .amount-to-beneficiaries-bar-chart.stacked-bar-chart {
          justify-content: right;

          .stacked-bar-chart__labels {
            left: 0 !important;
            padding-right: 8px;
            text-align: right;
          }
        }

        .est-estate-tax-bar-chart.stacked-bar-chart {
          justify-content: left;

          .stacked-bar-chart__labels {
            left: 50% !important;
            padding-left: 8px;
            text-align: left;
          }
        }

        .stacked-bar-chart {
          display: flex;
          height: 100%;
          width: 100%;
          flex-grow: 0;

          &__labels {
            width: 50%;
            margin: 0;
          }

          &__items {
            width: 50% !important;
            min-width: 50% !important;
          }

          &__highlight {
            width: 100%;
            left: 0;
          }
        }
      }

      .future-estate-value-legend-label {
        font-family: Roboto, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      .summary-container-with-sor-card {
        border: 1px solid $color-nt-grey-100;
        border-radius: 5px;
        background: $color-nt-container-background;
        padding: 24px;
      }

      .summary-container-without-sor-card {
        grid-gap: 24px;
        padding-left: 24px;
      }

      .summary-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: 1fr;

        .overview-section-without-sor-card {
          height: 100%;
          max-height: 136px;
        }

        .impact-section {
          height: 100%;

          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: 1fr;
          grid-gap: 24px;

          .impact-card-background-without-sor-card {
            background: $color-nt-container-background;
          }

          .impact-card-background-with-sor-card {
            background-color: $color-nt-grey-025;
          }

          .impact-card {
            display: grid;
            grid-auto-flow: row;
            grid-auto-rows: 1fr;

            width: 100%;
            height: 100%;
            max-height: 137px;

            border: 1px solid $color-nt-grey-100;
            border-radius: 5px;

            .impact-value {
              padding: 5px 5px 5px 0;
              font-weight: 500;
              font-size: 24px;

              .greyed-out {
                color: $color-nt-grey-300;
                font-size: large;
              }

              .amount-to-beneficiaries {
                color: $color-nt-green-600;
              }

              .estate-tax-savings {
                color: $color-orange-800;
              }
            }

            &__footer {
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: $color-nt-grey-550;
              padding-left: 5px;
            }
          }

          .amount-to-beneficiaries-border {
            border-left: 4px solid $color-assets-accounts;
          }

          .estate-tax-savings-border {
            border-left: 4px solid $color-goals-tax-liabilities;
          }
        }

        .state-of-residency-overview {
          padding: 0 !important;
          width: 100%;

          h4 {
            margin-top: 0;
          }

          &__accordion-row {
            padding: 0 16px 0 16px !important;

            .accordion__item {
              border: none;

              .sor-action-menu {
                height: 50px !important;
                background: $color-nt-grey-025;
              }

              .table-action-menu {
                height: 50px;
              }
            }

            .delete-trash-icon {
              margin-left: auto;
            }
          }

          .accordion__button {
            border: none;
            &:focus {
              box-shadow: none;
              outline: none;
            }
          }

          .state-of-residency-accordion-header__web {
            .span-1 {
              grid-column: 1 / span 2;
              width: 260px;
            }

            .span-2 {
              grid-column: 3 / span 1;
              width: 185px;
            }
          }

          .state-of-residency-accordion-header {
            padding: 8px 32px 8px 8px;

            justify-content: space-between;
            display: grid;
            grid-auto-flow: column;
            align-items: end;
            grid-template-columns: repeat(4, 1fr);

            border-bottom: 1px solid $color-nt-grey-100;

            span {
              font-family: RobotoCondensed, Roboto, sans-serif;
              color: $color-nt-grey-700;
              line-height: 15px;
              font-size: 13px;
              font-weight: 400;
              font-stretch: condensed;
              text-transform: uppercase;
            }

            .span-1 {
              grid-column: 1 / span 2;
            }

            .span-2 {
              grid-column: 3 / span 1;
            }

            .span-3 {
              grid-column: 4 / span 1;
            }
          }

          .state-of-residency-accordion-item {
            align-items: center;
            grid-template-columns: auto;
            justify-content: space-between;

            span {
              font-family: Roboto, sans-serif;
              line-height: 20px;
              font-size: 15px;
              font-weight: 400;
            }

            &__report {
              grid-gap: 8px;
              padding: 0 32px 0 8px !important;

              display: grid;
              grid-auto-flow: column;
              height: 50px;
              align-items: center;
              background: white;
              grid-template-columns: repeat(4, 1fr);

              span {
                color: $color-nt-grey-700;
              }

              .span-1 {
                grid-column: 1 / span 2;
              }

              .span-2 {
                grid-column: 3 / span 1;
              }

              .span-3 {
                grid-column: 4 / span 1;
              }
            }

            &__web-selected {
              background: $color-nt-grey-025 !important;

              .h4 {
                margin-bottom: 0;
                margin-top: 0;
              }

              .h5 {
                display: none;
              }

              span {
                color: $color-nt-grey-700 !important;
              }
            }

            &__web {
              padding: 0 0 0 8px !important;

              display: grid;
              grid-auto-flow: column;
              height: 50px;
              align-items: center;
              background: white;
              grid-template-columns: repeat(4, 1fr);

              .span-1 {
                grid-column: 1 / span 2;
                width: 260px;
              }

              .span-2 {
                grid-column: 3 / span 1;
                width: 185px;
              }

              .span-3 {
                grid-column: 4 / span 1;
              }

              span {
                color: $color-nt-grey-550;
              }
            }
          }
        }
      }
    }

    &-bottom-chart {
      width: 100%;
      height: 384px;
      padding: 0 24px 0 24px;
    }
  }

  .amount-to-beneficiaries-disclaimer {
    margin: 18px 32px;

    span {
      margin-left: 8px;
    }

    &-text {
      font-family: Roboto;
      font-size: 15px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
    }
  }
}

.wealthpo-strategy {
  &__underlined-header {
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  &__form {
    display: flex;
    justify-content: space-between;
    padding-left: 48px;

    section {
      .info-icon {
        color: $color-aqua-800;
      }
    }

    section:not(:first-child) {
      margin-top: 36px;
    }

    > article {
      width: 68%;
      padding-right: 48px;
    }

    > aside {
      width: 32%;
    }

    .strategy-time-frame-error-msg {
      padding-left: 30.5%;
      padding-top: 3px;
      padding-bottom: 8px;
      width: 73%;
      line-height: 18px;
      font-family: 'Roboto';
      font-size: 13px;
      color: #D04532;
    }

    .layout-data-entry-form {
      &__field {
        display: flex;
        flex-direction: row;
        width: 85%;
        min-height: 40px;

        .h5 {
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .strategyTimeFrameDate {
          display: flex;
          margin-top: 12px;
          margin-left: 32px;
          width: 35%;
          justify-content: space-between;
        }

        .error-color {
          color: #D04532;
        }
      }
    }

    .strategy-payout-schedule {
      display: flex;
      flex-direction: column;
      width: 85%;
      padding: 0 16px 50px 16px;

      [role="row"] {
        line-height: 18px;
        font-family: 'Roboto';
        font-size: 13px;
      }
    }

    .percent-input-field {
      .input-wrapper {
        width: 87px;
      }
    }

    .subheader {
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }

    .state-of-residency-field {
      width: 100% !important;
    }

    .annual-flow-input-field {
      .input-wrapper {
        width: 100%;
      }
    }

    .growth-rate-value {
      font-family: Roboto, sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }

    .payout-percentages {
      display: flex;
      flex-direction: column;
      padding: 8px 0 8px 0;
    }

    .dropdown__panel {
      width: 100% !important;
    }

    #strategySummary {
      padding-right: 48px;
      padding-top: 38px;
    }

    #estimatedImpact {
      padding-right: 48px;
    }

    #resources {
      padding-right: 48px;
      .nav-drawer-side-menu-list__item{
        padding-left: 0px;
        .doc-name__custom-alignment{
          padding-left: 23px;
          margin-top: -19px;
        }
      }
    }

    #estimatedImpactContainer {
      padding-right: 48px;
      padding-top: 40px;
    }

    #strategyExemption {
      padding-right: 48px;
    }

    #exemptionContainer {
      padding-right: 48px;
      padding-top: 40px;
    }

    #estimatedInfoTooltip {
      display: flex;
      flex-direction: row;
    }
  }
}
