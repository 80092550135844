.profile-settings-container {
  width: 100%;
  height: 100%;
  padding: 24px;
}

.profile-settings-cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-container {
  min-height: calc(100vh - 112px) !important;
}

.profile-settings {

  .title {
    padding-bottom: 18px;
  }

  .row-container {
    padding-left: 12px;
    padding-right: 0px;
  }

  .requiredAlert {
    color: #d04532;
  }

  .errorMessage {
    margin-top: 4px;
  }

  .dropdown {
    .dropdown__item--selected {
      background: none;
      color: #3d4042;
    }

    .input__field, .dropdown__item {
      font-weight: 400;
    }
  }

  .primary-contact-info {

    .btn--medium, .btn--small {
      padding: 0px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    .family-tree-link {
      margin-top: 50px;

      a {
        text-decoration: underline;
      }
    }

    .label {
      max-width: 158px;
    }

    .h4.parent .multi-line {
      padding-top: 3px;
    }

    .detail {
      padding: 11px 0 11px 200px;
      font-size: 13px;
      font-weight: 400;
    }

    .linked-partner-full-name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .remove-button {
      .btn-skin--borderless {
        color: #BF3B2A;
      }
    }
  }

  .primaryContactInfo,
  .profileDetails,
  .effectiveTaxRates,
  .totalInput {
    padding-left: 200px;
  }

  label.h5.label {
    margin: auto;
    padding-top: 11px;
  }

  .h4 {
    margin-top: 0px;
  }

  .h4.sections {
    margin-top: 50px;
  }

  .section {
    margin-bottom: 16px;
  }

  .parent label,
  input {
    display: inline;
  }

  .parent label {
    float: left; /* if you had floats before? otherwise inline-block will behave differently */
  }

  .parent input,
  .parent .SingleDatePicker {
    width: 300px;
  }

  .input-container label {
    font-size: 13px;
  }

  .effectiveTaxRates .input-container,
  .profileDetails .input-container,
  .totalInput .input-container {
    width: 100px;
  }

  .effectiveTaxRates .input-container input,
  .totalInput .input-container input {
    width: 50px;
  }

  div#accreditedStatusInput {
    margin-left: 62px;
  }

  div#allocationTypeInput {
    margin-left: 76px;
  }

  .profileDetails .input-container input {
    width: 24px;
  }

  .marginright-lg.overflowx-hidden {
    font-size: 13px;
  }

  .marginright-md {
    margin-right: 12px;
  }

  #federalInput,
  #stateAndLocalInput,
  #capitalGainsInput,
  #totalInput,
  #allInclusiveFeeInput,
  .profileDetails,
  .effectiveTaxRates {
    font-size: 13px;
  }

  .alert-skin--error {
    margin: 8px 13px 13px 13px;
  }

  .alertBannerStyleName {
    font-weight: 500;
  }

  .font-weight-400 {
    font-weight: 400;
  }

  input#federalInput {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13px;
  }

  .profileDetails {
    .btn-skin--borderless {
      border: none !important;
    }
  }

  input#federalInput,
  input#stateAndLocalInput,
  input#totalInput,
  input#capitalGainsInput,
  input#allInclusiveFeeInput {
    width: 88px;
    color: $color-black;
  }

  .input-inline-label, b {
    font-weight: 500
  }

  .input-inline-label {
    font-size: 13px;
    font-weight: 500;
  }

  .percent-field b {
    font-weight: 800;
  }

  .disabledInput {
    font-weight: 400;
    color: $color-black;
  }

  .inlineInputError {
    margin-left: 200px;
    margin-top: 4px;
  }

  .color-text--error {
    font-size: 12px;
  }

  .totalTax-alignment {
    margin-left: 10px;
    padding-top: 8px;
    padding-bottom: 25px;
  }

  .no-partner-workstation-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 72px 48px;

    span {
      font-family: Roboto;
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;
      color: #3D4042;
    }
  }

  .ReactTable {
    border: none;
  }

  .ReactTable.table .rt-thead.-header .rt-th {
    border-bottom-color: #949494;
  }

  .ReactTable.table .table__noData {
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 72px 48px;

    .h3 {
      font-family: Roboto;
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;
      color: #3D4042;
    }
  }

  .service-team-subtitle {
    padding-top: 17px;
    padding-bottom: 24px;
  }

  .prospect-team-subtitle {
    padding-top: 17px;
    padding-bottom: 24px;
  }

  .radio {
    display: flex;
    height: 100%;
    padding-top: 0.308rem;
  }

  .popoverContainer {
    margin-left: 16px;
    margin-right: 0;
  }
}

fieldset {
  border: 0;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
}
