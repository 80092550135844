.popoverContainer {
  margin-left: 7px;
  margin-right: 7px;

  .popover-button {
    height: 18px;
    width: 18px;
    line-height: 18px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    .dds-icons.icon {
      color: $color-aqua-800;
      vertical-align: top;

      &:hover {
        color: $color-aqua-950;
      }
    }
  }

  .popover-text {
    font-weight: 400;
    padding-left: 0;

    &.popover__arrow {
      &--top, &--bottom, &--left, &--right {
        .popover__content:before {
          content: '';
          position: absolute;
        }
      }

      &--top, &--bottom {
        .popover__content:before {
          left: calc(50% - (max(32px, 16%) / 2));
          width: max(32px, 16%);
          height: 16px;
        }
      }

      &--top .popover__content:before {
        top: -16px;
      }

      &--bottom .popover__content:before {
        bottom: -16px;
      }

      &--left, &--right {
        .popover__content:before {
          top: calc(50% - (max(32px, 16%) / 2));
          width: 17px;
          height: max(32px, 16%);
        }
      }

      &--left .popover__content:before {
        left: -17px;
      }

      &--right .popover__content:before {
        right: -17px;
      }
    }
  }
}