.edit-asset-reliance-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .page-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #3D4042;
    padding: 40px 45px 40px 48px;
  }

  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: #3D4042;
  }

  &__grid-container {
    width: 100%;
    padding-left: 48px;
    padding-right: 48px;

    .edit-asset-reliance-container {
      overflow: auto;

      .grid_content {
        .edit-asset-reliance-header {
          .col {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }

        .row {
          flex-wrap: nowrap !important;
        }

        .title-container {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 159px !important;
          height: 62px;

          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .asset-header-container {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 159px !important;
          height: 62px;
          background-color: $color-nt-grey-025;

          .asset-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
          }

          .edit-header-name-hover {
            position: absolute;
            top: 3px;
            right: 3px;
          }

          &-hovered {
            background-color: $color-aqua-100;
          }

          .edit-header-input-container {
            font-size: 13px;
            width: 100%;

            .edit-header-input {
              outline: none;
              border: 3px solid $color-aqua-600;
              font-style: normal;
              font-weight: 400;
              max-width: 125px;
              right: 15px;
              top: 3px;
              left: 0;
              position: relative;
              resize: none;
            }
          }

        }

        .stack-value-container {
          justify-content: center;
          align-items: center;
          max-width: 159px !important;
          max-height: fit-content;
          background-color: $color-nt-grey-025;

          .stack-value-cell {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
          }
        }
      }
    }
  }
}