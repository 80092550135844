@import "../abstracts/colors";

.standalone-account {

  .asset-details-section {

    #doesPermitBeneficiary {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 8px;

      label {
        flex-basis: 33%;
        margin-right: 16px;
      }
    }
  }

  .account-summary-section {

    .data-entry-summary__custom-content {
      margin-bottom: 32px;
      padding-left: 24px;
      padding-right: 24px;
    }

    .account-summary-content {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      font-weight: 300;
    }

    .account-summary-empty-content {
      min-height: 100px;
    }

    .no-asset-details {
      font-size: 16px;
    }

    .edit-holdings-button {
      width: 100%;
      text-align: center;
    }
  }
  .dropdown__group__label {
    color: grey;
    font-weight: 500;
    font-size: 12px;
  }
}
