.empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $spacing-2xxl;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 640px;
    height: 100%;
    padding: 72px $spacing-xl;
    box-sizing: content-box;
  }

  &__icon .icon {
    font-size: 36px;
  }

  &__title {
    text-align: center;
  }

  &__description {
    max-width: 560px;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--large {
    max-width: 864px;

    .empty-state-container__title {
      font-size: 24px;
      font-weight: $font-weight-light;
      line-height: 30px;
    }
  }

  &--small {
    max-width: 400px;

    .empty-state-container__title {
      font-size: 18px;
      font-weight: $font-weight-light;
      line-height: 24px;
    }

    .empty-state-container__icon .icon {
      font-size: 24px;
    }
  }
  &--hide-border {
    box-shadow: none;
  }
}
