@import "../abstracts/colors";
@import "../abstracts/spacing";

.equity-compensation {
  .layout-data-entry-form {
    margin-top: 16px;

    article {
      width: 63%;
    }

    aside {
      width: 37%;
      margin-top: 0;
    }

    &__field {
      label {
        margin-top: auto;
        margin-bottom: auto;
        flex-basis: 37%;
      }
    }
  }

  #doesPermitBeneficiary {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: $spacing-lg;
    margin-bottom: 8px;

    label {
      flex-basis: 37%;
      margin-right: 24px;
    }
  }

  .data-entry-summary {
    &__title,
    &__items,
    &__custom-content {
      padding-left: $spacing-xl;
      padding-right: $spacing-xl;
      margin-bottom: $spacing-xl;
    }
  }
}
.stock-options-grid-table,
.stock-grants-grid-table,
.vesting-table {
  font-size: 13px;

  .stock-options-grid-table-header,
  .stock-grants-grid-table-header,
  &__header {
    line-height: 15px;
    background-color: white;
    align-items: center;

    [role="columnheader"] {
      font-family: RobotoCondensed, Roboto, sans-serif;
      color: $color-nt-grey-900;
      font-size: 13px;
      font-weight: 400;
      font-stretch: condensed;
      text-transform: uppercase;
    }
  }
}

.stock-options-grid-accordion-item,
.stock-options-grid-table-header,
.stock-options-grid-table-row,
.stock-grants-grid-accordion-item,
.stock-grants-grid-table-header,
.stock-grants-grid-table-row,
.vesting-table__row,
.vesting-table__header {
  grid-column: 1 / -1;
  height: 48px;
  align-items: center;
}

.stock-options-and-grants {
  $stock-date-picker-input-width: 108px;

  .cell-text-content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  &__subtitle {
    color: $color-nt-grey-550;
    white-space: pre-wrap;
  }

  &__list {
    margin: 24px 48px;
  }

  .stock-options-grid {
    display: grid;
    grid-template-columns: auto 108px 72px 108px 64px 64px 72px repeat(2, 64px) repeat(5, 64px) 32px;
    column-gap: 8px;
  }

  .stock-options-grid-column-hidden {
    display: grid;
    grid-template-columns: auto 108px 84px 108px 72px 90px 84px repeat(2, 84px) repeat(2, 128px) 32px;
    column-gap: 8px;
  }

  .stock-grants-grid {
    display: grid;
    grid-template-columns: auto 108px 89px 113px repeat(3, 103px) 132px 216px 32px;
    column-gap: 10px;
  }

  .stock-options-grid-table,.stock-grants-grid-table {
    [role="cell"] {
      position: relative;
  
      &:first-child {
        padding-left: $spacing-xxl;
      }
    }

    &__footer {
      font-weight: 500;
      margin-top: $spacing-lg;
      margin-bottom: $spacing-xl;

      .total-stock-options-label {
        grid-column: span 9;
        margin-right: $spacing-lg;
      }

      .total-stock-grants-label {
        grid-column: span 7;
        margin-right: $spacing-lg;
      }
    }

    .stock-grants-grid-table {
      [role="cell"] {
        &:last-child {
          padding-right: $spacing-xxl;
        }
      }
    }

    .stock-options-grid-table-header, .stock-grants-grid-table-header {
      border-top: 1px solid $color-nt-grey-100;
      height: 60px;

      &[role="row"] > :first-child {
        padding-left: $spacing-xxl;
      }
    }

    .stock-grants-grid-table-header {
      &[role="row"] > :last-child {
        padding-right: $spacing-xxl;
      }
    }

    .stock-options-grid-accordion-item,
    .stock-options-grid-table-header,
    .stock-options-grid-table-row,
    .stock-grants-grid-accordion-item,
    .stock-grants-grid-table-header,
    .stock-grants-grid-table-row{
      &:hover,
      &:focus {
        background-color: $color-aqua-100;
        outline: 0;
      }
    }
  }

  .stock-options-grid-table-row, .stock-grants-grid-table-row {
    .input-wrapper {
      width: 100%;
    }

    input:disabled {
      background-color: transparent !important;
    }

    .input-right-aligned {
      .input-wrapper {
        float: right;

        input[type="text"] {
          text-align: right;
        }
      }
    }

    .gpi-datepicker {
      .input-skin,
      .input-skin--focus {
        padding: 0 4px;
        width: $stock-date-picker-input-width;

        input {
          width: 72px !important;
        }
      }
    }

    .edit-vesting-button {
      cursor: pointer;
    }
  }

  .stock-option-quickslide-item {
    padding-top: 15px;
  }

  .input-inline-align--left {
    input {
      padding-left: 12px;
    }
  }

  .input-inline-label {
    padding-left: 4px;
  }
}

.edit-vesting {
  &__form {
    .layout-data-entry-form__field {
      line-height: 40px;
    }
  }

  .customize-vesting-events {
    &__list {
      max-height: 1190px;
      overflow-y: scroll;
    }

    &__empty-view {
      display: flex;
      margin: 64px 0;
      justify-content: center;
    }

    .alert__actions {
      display: none;
    }
  }

  .vesting-table {
    margin-bottom: 8px;
    padding: 0 16px;

    .vesting-grid {
      display: grid;
      grid-template-columns: 3fr 84px 4fr 32px;
      column-gap: 8px;
    }

    &__header {
      height: auto;
    }

    &__row {
      .input-wrapper {
        width: 100%;
      }
  
      .input-right-aligned {
        .input-wrapper {
          float: right;
  
          input[type="text"] {
            text-align: right;
          }
        }
      }

      .input-inline-label {
        left: 8px;
      }
  
      .edit-vesting-button {
        cursor: pointer;
      }

      .vested-icon {
        background-color: $color-green-600;
        border-radius: 50%;
        color: white;
        font-size: 10px;
        padding: 2px;
        font-weight: bold;
        margin-right: 4px;
      }

      .vested-text {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: $color-nt-grey-550;
        font-weight: 400;
      }
    }
  }
}

.equity-compensation-table {
  .assets-grid-table-header[role=row] > :first-child {
    padding-left: 32px !important;
  }

  .equity-compensation-summary-accordion {
    .assets-grid-table-row > :first-child {
      padding-left: 8px !important;
    }

    .accordion__item {
      height: max-content;
      border: none;

      .nested-row-within-accordion > :first-child {
        padding-left: 48px !important;
      }
    }
  }
}

.popoverContainer{
  margin-left: 7px;
  margin-right: 3px
}
.popover__content:before{
  content: '';
  position: absolute;
  width: max(32px, 16%);
  height: 0;
}
.popover__arrow--top:before{
  top:1px;
  left: calc(50% + 8px);
  transform:rotate(135deg);
}
.popover__arrow--top:after{
  top:-8px;
  left:calc(50% + 8px);
}
.popover-skin--primary .popover__content{
  background-color:#fff;
  border-color:#cecece
}
.popover-skin--primary:before{
  border-color: transparent transparent rgba(0,0,0,.2) rgba(0,0,0,.2);
}
.popover-text {
  font-weight: 400;
  padding-left: 0;
}

.popover-button {
  height: 18px;
  width: 18px;
  line-height: 18px;
  border: none;
  outline: none;
  box-shadow: none;

  .dds-icons.icon {
    color: $color-aqua-800;
    vertical-align: top;

    &:hover {
      color: $color-aqua-950;
    }
  }
}