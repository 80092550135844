.rich-text-editor {
    .ql-editor {
        font-size: 15px;

        strong {
            font-weight: bold;
        }
    }

    .ql-blank:before {
        font-style: normal;
        color: $color-aqua-500
    }
}

.html-viewer {
    font-size: 15px;

    p {
        margin: 0;
        line-height: 26px;
    }

    ul {
        padding-left: 40px;
    }
}