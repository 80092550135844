.asset-reliance-title-grid-container {
  display: flex;
  height: 64px;
  padding-top: 18px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 500;
    width: 100%;
  }
}