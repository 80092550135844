.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.badge--alert {
  background-color: $color-orange-300;
}

.badge--white {
  background-color: white;
}

.badge--grey {
  background-color: #565a5c;
  color: white;
}
