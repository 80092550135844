.held-away-holdings {
  .line {
    height: 1px;
    grid-column: 1 / -1;
    background: $color-nt-grey-200;
  }
  .holding-grid {
    display: grid;
    grid-template-columns: 4fr 4fr 1fr repeat(3, 2fr) 1fr 1fr;
    grid-column-gap: 8px;

    .holding-grid-column-custom-span {
      grid-column: span 3;
    }
  }
  .holdings-footer {
    min-height: 48px;
    grid-column: 1/-1;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 12px;

    .asset-classification-total {
      font-size: 15px;
      font-weight: 500;
      text-align: right;
    }
  }
  .holding-grid-container {
    padding: 24px 48px;
    .holding-asset-classification {
      .holding-grid-accordion-item {
        grid-column: 1 / -1; // Span through columns first to last

        .holding-subclass-row {
          line-height: 40px;
          font-weight: 700;

          .holding-subclass-name {
            padding-left: 32px;
          }
        }

        .holding-subclass-detail-row {
          line-height: 40px;
          border-top: 1px solid #eaeaea;

          .holding-subclass-detail-name {
            padding-left: 64px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .product-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .delete-holding-button {
            border: none;
          }
        }
      }
    }
  }
  .checkbox {
    display: block;
    padding: 5px 0 0 30px;
    align-self: center;

    input {
      margin-top: 5px;
    }
  }
}
