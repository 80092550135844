.holistic-advice-summary-page {

  .current-net-worth-grid {
    display: grid;
    grid-template-columns: minmax(25%, 30%) minmax(10%, 55ch) minmax(10%, 55ch) minmax(10%, 55ch) minmax(10%, 55ch) 40px;
  }

  .current-net-worth-header-grid-with-actionmenu {
    display: grid;
    grid-template-columns: minmax(27%, 33%) minmax(10%, 55ch) minmax(10%, 55ch) minmax(10%, 55ch) minmax(10%, 55ch) 32px;
  }

  .current-net-worth-grid-with-actionmenu {
    display: grid;
    grid-template-columns: minmax(25%, 30%) minmax(10%, 55ch) minmax(10%, 55ch) minmax(10%, 55ch) minmax(10%, 55ch) 40px 30px;
  }

  .outOfEstate-grid {
    display: grid;
    grid-template-columns: minmax(26%, 1fr) repeat(4, minmax(50px, 1fr)) 40px;
  }

  .accordion-header-content {
    height: 64px;
    background-color: #fff;
    align-items: center;
    border-radius: 5px;

    & > * {
      padding-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .asset-accordion-header-caption {
      color: #767676;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }
  }
}

.side-drawer-form {
  padding: 24px 48px;

  section {
    max-width: 550px;

    header.underlined-sub-header {
      margin-bottom: 0;
    }
  }

  article > section > button {
    margin-top: $spacing-xxl;
  }

  header {
    padding: 0;
  }

  .side-drawer-form-content {
    display: flex;

    > article {
      width: 65%;
    }

    > aside {
      width: 35%;
      padding-left: $spacing-xl;
      margin-top: $spacing-xxxl;
      margin-bottom: $spacing-xxxl;
    }

    &-subtotals {
      padding-top: 14px;

      &-row {
        display: grid;
        grid-template-columns: 75% 25%;
        justify-content: right;
        padding-top: 10px;
        padding-right: 33px;

        > div {
          text-align: right;
        }
      }
    }
  }

  .alert {
    margin-bottom: $spacing-xl;
  }
}

.centered {
  display: block;
  margin: 0 auto;
}

.data-form-table {
  display: grid;
  grid-template-columns: 2fr 1fr 25px;
  margin-top: $spacing-20;

  @include nt-data-table-number-input;

  .data-form-table-header {
    @include nt-data-table-header;
    @include nt-border-bottom;

    &__placeholder {
      @include nt-border-bottom;
    }
  }

  //noinspection CssReplaceWithShorthandSafely
  .data-form-table-row {
    width: 100%;
    padding-left: $spacing-md;
    padding-right: $spacing-md;
    padding-bottom: $spacing-sm;
    padding-top: $spacing-sm;

    @include nt-border-bottom;

    &.no-bottom-border {
      border-bottom: none;
    }

    &.bequest-name-missing {
      padding-bottom: $spacing-20;
    }

    &.bequest-name-missing-percent-input {
      padding-bottom: $spacing-20 + 2;
    }
  }

  .input--medium {
    padding: 0 15px;
  }

  .button-delete {
    height: 100%;
    padding-left: 0;
    min-width: unset;
    @include nt-border-bottom;

    &.no-bottom-border {
      border-bottom: none;
    }

    i {
      color: $color-nt-text;
      font-size: 18px;
    }

    &.bequest-name-missing-error-spacing {
      padding: 0 0 24px 0;
    }
  }
}

.subtitle {
  padding: 34px 24px 0 24px;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  font-weight: 300;
}

.estate-summary-grid {
  display: grid;
  grid-template-columns: minmax(22%, 30%) minmax(12.5%, 55ch) minmax(16%, 4fr) minmax(16%, 5fr) minmax(14%, 55ch) 40px;
}

.estate-grid-table [role=cell]:first-child {
  padding-left: 49px;
}

.estate-grid-table {

  [role="cell"] {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;

    &:first-child {
      padding-left: 32px;
    }

    .cell-with-popover {
      display: flex;

      .popover-wrapper {
        width: 20px;
      }
    }

    .cell-text-content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.text-grey-900 {
  color: $color-nt-grey-900;
}

.bar-chart-subtitle {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

.bequest-type-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 25px;
  margin-top: $spacing-20;

  .bequest-type-label {
    padding-left: $spacing-md;
    padding-right: $spacing-md;
    padding-bottom: $spacing-sm;
    padding-top: $spacing-md;
    justify-content: center;
    align-items: center;
    font-weight: normal;
  }
}

.wealth-transfer-navigation-dropdown {
  button {
    display: flex;
  }
}

.wealth-transfer-options-dropdown {
  .dropdown__item {
    line-height: 34px;
    padding: 0 8px;

    .page-action-menu-options {
      width: 100%;
    }
  }
}

.hide-element-with-static-position {
  position: static;
  opacity: 0;
}
