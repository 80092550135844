.products-container {
  .search-products-results-grid {
    display: grid;
    grid-template-columns: 2fr 10fr 3fr 5fr 5fr 6fr;
    grid-column-gap: 16px;
  }

  .search-approved-products-results-grid {
    display: grid;
    grid-template-columns: 3fr 12fr 6fr 5fr;
    grid-column-gap: 16px;
  }

  .alert-banner-container {
    padding-bottom: 10px;
  }

  .input-wrapper {
    width: 100%;

    input[type=text] {
      text-align: right;
    }
  }

  .line {
    height: 1px;
    grid-column: 1 / -1;
    background: $color-nt-grey-200;
  }

  .holding-grid {
    display: grid;
    grid-template-columns: 6fr 8ch 4fr 10ch repeat(3, 4fr) 1fr;
    grid-column-gap: 16px;

    .holding-grid-column-custom-span {
      grid-column: span 4;
    }
  }

  .holdings-footer {
    min-height: 48px;
    grid-column: 1/-1;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 12px;

    .asset-classification-total {
      font-size: 15px;
      font-weight: 500;
      text-align: right;
    }
  }

  .holding-grid-container {
    padding: 24px 48px;

    .holding-asset-classification {
      .holding-grid-accordion-item {
        grid-column: 1 / -1; // Span through columns first to last

        .holding-subclass-row {
          line-height: 48px;
          border-bottom: 1px solid #cecece;

          .holding-subclass-name {
            padding-left: 32px;
          }
        }

        .holding-subclass-detail-row {
          line-height: 48px;
          border-bottom: 1px solid #cecece;

          .holding-subclass-detail-name {
            padding-left: 64px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .ticker-name, .cusip-name {
            input {
              text-align: left;
            }
          }

          .product-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            input {
              text-align: left;
            }
          }

          .delete-holding-button {
            border: none;
          }
        }
      }
    }
  }
}


