.profile-controls {
  .close-profile-button {
    width: 176px;
    height: 40px;
  }
  margin: 0 12px 12px 12px;
  z-index: 1000;
  position: absolute;
  left: 0;
  bottom: 0;
}