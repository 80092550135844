.lifestyle-spending {
  &__form {
    display: flex;
    padding: 0 0 20px 48px;

    > section {
      flex: 1 0 33%;
    }

    > article {
      flex: 0 0 66%;
    }

    hr {
      margin-right: 48px
    }
  }

  section:not(:first-child) {
    margin-top: 36px;
  }

  section {
    padding-right: 48px;
    margin-bottom: auto !important;
  }

  .lifestyle-spending-section-header {
    justify-content: flex-start !important;
  }

  .requiredAlert {
    color: #d04532;
  }

  .lifestyle-spending-section {
    #taxStatusInput {
      .dropdown__item {
        font-size: 15px;
      }
    }
  }

  .lifestyle-spending-total-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 40px;
    margin-top: 10px;
    padding-right: 30px;
  }

  .aligned-inflows-popover {
    width: 280px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: normal;

  }

  .aligned-inflows-popover-data {
    padding-top: 8px;

    .inflow-description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .inflow-value {
      text-align: right;
      min-width: 12ch;
    }
  }

  .data-entry-summary {
    margin-top: 40px;

    &__custom-content {
      margin-bottom: 32px;
      padding-left: 24px;
      padding-right: 24px;

      .edit-holdings-button {
        width: 100%;
        text-align: center;
      }
    }
    &__error > .data-entry-summary__item__value {
      color: $color-red-500;
    }
  }

  .years-field {
    width: 110px;
  }

  .range-inputs {
    display: flex;
    flex-direction: row;

    input {
      width: 52px
    }

    span {
      width: 30px;
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .layout-data-entry-form {
    &__field {
      label {
        flex-basis: 33%;
      }

      padding-right: 64px;
    }
  }

  .layout-data-entry-form__text {
    label {
      flex-basis: 35%;
    }

    padding-top: 10px;
  }
}

.present-value-banner-container {
  padding: 0 48px;
}

.banner-text {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 450;
  color: #3D4042;
  font-style: normal;
  line-height: 18px;
}

.data-entry-header {
  padding-bottom: 16px !important;
}

.data-entry-header-goal-family {
  padding: 24px 18px !important;
}


.lifestyle-goal-funding-table {
  display: grid;
  grid-template-columns: 33% 67%;
  gap: 10px 20px;

  > label{
    padding-left: 15px;
  }

  .funded-by-portfolio-through-age-description {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .funded-by-non-investable-assets-slider-container {
    display: flex;
    flex-direction: row;

    .funded-by-non-investable-assets-slider {
      flex-grow: 0;
    }
  }
}

.lifestyle-grid{
  display: grid;
  grid-template-columns: 262px 128px 108px 12px;
  gap: 10px 24px;
  .delete_icon {
      margin-left: -40px;
    }
}

.spending-period{
  margin-top: 10px
}

.lifestyle_grid-display-contents {
  display: contents;
}

.descriptionFieldInput {
    width: 100% !important;
}

.AnnualSpendFieldInput {
  width: 100% !important;
}

.LengthOfSpendFieldInput {
  width: 80% !important;
}

.LifestyleSpendingPeriodRanges {
  display: grid;
  grid-template-columns: 160px 160px;
  grid-column: 2 / 4;
  grid-row-gap: 10px;
  font-size: 15px;
}

.LifestyleSpendingPeriodHeaders{
  font-weight:500;
}
