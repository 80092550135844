// -----------------------------------------------------------------------------
// This file contains typography styles.
// -----------------------------------------------------------------------------

$font-family-stack: 'Roboto', sans-serif !default;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;

$font-size-small: 13px;
$font-size-large: 18px;

@mixin typography-body {
  font-family: $font-family-stack;
  font-size: 15px;
  font-weight: $font-weight-normal;
  line-height: 20px;
  min-height: 20px;
}

@mixin nt-data-table-header {
  padding-bottom: $spacing-md;
  padding-left: $spacing-lg;
  padding-right: $spacing-lg;
  font-size: $font-size-small;
  font-family: 'RobotoCondensed', 'Roboto', sans-serif;
  text-transform: uppercase;
  line-height: 15px;
}

@mixin nt-data-table-number-input {
  .currency-field {
    input {
      text-align: right;
    }
  }
}
