html, body {
  height: 100%;
  touch-action: none;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Page_content {
  flex: 1
}

footer {
  /* flex-shrink: 0; */
}

.client-table {
  max-width: 100%;
  height: auto;
  background-color: white;
  padding: 24px;
}

.new-client-id {
  float: right;
}

.new-client-text {
  padding: 12px 1px 13px 1px;
  height: 13px;
  width: 124px;
}

.presentation-view {
  position: absolute;
}



/* .transition {
  position: absolute;
  left: 15px;
  right: 15px;
}

.transition-enter {
  opacity: 0;
  transform: translateX(100%);
}
.transition-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms, transform 2.0s;
}
.transition-exit {
  opacity: 1;
  transform: translateX(0%);
}
.transition-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 2.0s;
} */

