.nav-drawer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 200px;
  height: calc(100% - 64px);
  min-height: calc(100% - 64px);
  padding: $spacing-md;
  overflow-y: auto;
  background-color: $color-nt-page-background;
  transition: left 0.4s, visibility 0.4s;

  &--open {
    left: 0;
    visibility: visible;
  }

  &--closed {
    left: -200px;
    visibility: hidden;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    li {
      display: flex;
      width: 100%;
    }

    .accordion__item {
      border: none;
    }

    .accordion__button {
      border: none;

      &:focus {
        box-shadow: 0 0 0 2px $color-aqua-500;
        border: 1px solid $color-nt-container-background;
      }
    }

    .nav-drawer-no-documents{
      padding-top: 340px;
      padding-left: 65px;

      &__title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
      }
      &__message1 {
        padding-left: 9px;
        font-size: 13px;
        margin-top: -10px;
      }
      &__message2 {
        padding-left: 3px;
        font-size: 13px;
      }
      &__message3 {
        padding-left: 58px;
        font-size: 13px;
      }

      &__share-point-link {
        font-size: 10px;
        padding-left: 77px;
        padding-top: 8px;
      }
    }

    .unable-to-load-documents{
      padding-top: 340px;
      padding-left: 65px;

      &__title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        padding-left: 15px;
      }

      &__message1 {
        padding-left: 30px;
        font-size: 13px;
        margin-top: -10px;
      }
      &__message2 {
        padding-left: -4px;
        font-size: 13px;
      }
      &__message3 {
        padding-left: 10px;
        font-size: 13px;
      }

      &__message4 {
        padding-left: 54px;
        font-size: 13px;
      }

      &__message5 {
        padding-left: 38px;
        font-size: 13px;
      }

      &__refresh-button {
        font-size: 10px;
        padding-left: 112px;
        padding-top: 0px;
      }
    }

    .no-folder-exists{
      padding-top: 340px;
      padding-left: 49px;

      &__title1 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        padding-left: 0px;
      }

      &__title2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        padding-left: 91px;
      }

      &__message1 {
        padding-left: 0px;
        font-size: 13px;
        margin-top: -10px;
      }
      &__message2 {
        padding-left: 19px;
        font-size: 13px;
      }
      &__message3 {
        padding-left: 30px;
        font-size: 13px;
      }

      &__message4 {
        padding-left: 55px;
        font-size: 13px;
      }

      &__message5 {
        padding-left: 38px;
        font-size: 13px;
      }

      &__refresh-button {
        font-size: 10px;
        padding-left: 33px;
        padding-top: 0px;
      }
    }
  }

  &__separator {
    height: 1px;
    margin: $spacing-md $spacing-12 $spacing-md;
    background-color: $color-nt-borders;
  }

  &__toolbar {
    position: relative;
    width: 100%;
  }
}
