.display-flex {
  display: flex;
}

.flex-column {
  flex-direction: column !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.height-100p {
  height: 100% !important;
}

.label-13px {
  font-size: 13px;
  line-height: 18px;
}

.label-15px {
  font-size: 15px;
  line-height: 20px;
}

.sublabel-12px {
  font-size: 12px;
  line-height: 15px;
  color: $color-nt-grey-550;
}

.label-grey {
  color: $color-nt-grey-550;
}

.paddingleft-5 {
  padding-left: 5px;
}
