.meeting-participants {

  background-color: $color-black;

  .participant-audio-video-container {
    height: 160px;
    width: 200px;

    background-color: transparent;
  }

  .participant-control-bar {
    align-self: center;
    display: flex;
    width: 184px;

    :hover {
      background-color: transparent;
      color: white
    }

    .span {
      font-family: 'Roboto';
      line-height: 18px;
      font-size: 10px
    }
  }

  .participant-video-gallery {
    align-self: center;
    height: 105px;
    width: 184px;
    background-color: #4A4D4F;

    div {
      padding: 0;
    }

    video {
      border-radius: 0;
    }

    .ms-Stack {
      background: transparent;

      .svg {
        color: white
      }
    }
  }

  .meeting-participant-list {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h3 {
      margin-top: 0;
      color: white;
    }

    button {
      margin-top: 0;
      border: none;
      color: white;
    }
  }
}