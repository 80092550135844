// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin nt-border {
  border: solid 1px $color-nt-borders;
}

@mixin nt-border-bottom {
  border-bottom: solid 1px $color-nt-border-lines;
}

@mixin nt-border-top {
  border-top: solid 1px $color-nt-borders;
}

@mixin nt-border-right {
  border-right: solid 1px $color-nt-borders;
}

@mixin nt-border-left {
  border-left: solid 1px $color-nt-borders;
}
