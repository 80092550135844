.presentation-pane-header {
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
  padding: 32px 48px 51px;
  background-color: #063b3f;

  .display-name {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-align: left;
    text-transform: uppercase;
    color: white;
  }

  h1 {
    color: white;
    margin: 12px 0 0 0;
    display: flex;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-skin--borderless:not(.btn__icon-only) {
    color: white;
  }

  &__subtitle {
    color: $color-nt-grey-900;
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    line-height: 40px; /* 200% */
    padding-left: 8px;
  }
}