.sufficiency-bar-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $spacing-2xxl;

  &__container {
    position: relative;
    width: 100%;
    height: 28px;
  }

  &__assets-label {
    margin-right: $spacing-md;
    color: $color-aqua-300 !important;
  }

  &__assets-bar {
    position: absolute;
    left: 0;
    height: 28px;
    background-color: $color-assets-accounts;
  }

  &__sufficiency-label {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__percent-bar {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 10px;
    border-radius: 10px;
  }

  &__sufficiency-pill {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    height: 18px;
    padding: 2px 8px;
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
    background-color: white;

    b {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.5px;
      background-color: white;
    }
  }

  &__goals-divider {
    position: absolute;
    top: -3px;
    height: 34px;
    width: 2px;
    background-color: $color-goals-family;
  }

  &__goals-label {
    position: absolute;
    bottom: -20px;
    left: 0;
    transform: translateX(-50%);
    width: max-content;
    color: $color-yellow-400 !important;
  }

  &__sufficiency-bar {
    position: absolute;
    right: 0;
    height: 28px;
  }

  &__error-message {
    color: $color-nt-grey-200 !important;
  }

  .condensed-subtitle {
    font-weight: 500;
    font-size: 10px;
    white-space: nowrap;
  }

  &--insufficient {
    .sufficiency-bar-chart__percent-bar {
      background-color: $color-orange-400;
    }

    .sufficiency-bar-chart__sufficiency-pill {
      border-color: $color-orange-800;

      b {
        color: $color-orange-800 !important;
      }
    }

    .sufficiency-bar-chart__goals-divider {
      right: -2px;
    }
  }

  &--sufficient {
    .sufficiency-bar-chart__percent-bar {
      background-color: $color-aqua-300;
    }

    .sufficiency-bar-chart__sufficiency-pill {
      border-color: $color-aqua-800;

      b {
        color: $color-aqua-800 !important;
      }
    }

    .sufficiency-bar-chart__goals-divider {
      left: -2px;
    }
  }

  &--negative-assets {
    .sufficiency-bar-chart__assets-bar {
      min-width: 10px;
      background-color: $color-nt-grey-300;
    }

    .sufficiency-bar-chart__assets-label {
      color: $color-orange-400 !important;
    }

    .sufficiency-bar-chart__percent-bar {
      left: 10px;
      width: calc(100% - 10px);
    }
  }

  &--zero-goals {
    .sufficiency-bar-chart__sufficiency-label {
      display: none;
    }
  }

  &--zero-sufficiency {
    .sufficiency-bar-chart__sufficiency-label,
    .sufficiency-bar-chart__sufficiency-pill {
      display: none;
    }
  }

  &--no-goals-entered {
    .sufficiency-bar-chart__sufficiency-label,
    .sufficiency-bar-chart__sufficiency-pill,
    .sufficiency-bar-chart__percent-bar,
    .sufficiency-bar-chart__goals-divider {
      display: none;
    }
  }

  &--no-assets-entered {
    .sufficiency-bar-chart__sufficiency-bar {
      display: none;
    }

    .sufficiency-bar-chart__assets-bar {
      min-width: 10px;
      background-color: $color-nt-grey-300;
    }

    .sufficiency-bar-chart__assets-label {
      color: $color-nt-grey-300 !important;
    }
  }
}

.sufficiency-bar-chart--negative-assets.sufficiency-bar-chart--zero-goals {
  .sufficiency-bar-chart__goals-divider {
    left: -2px;
  }

  .sufficiency-bar-chart__percent-bar,
  .sufficiency-bar-chart__sufficiency-pill {
    display: none;
  }
}

.sufficiency-bar-chart--zero-assets.sufficiency-bar-chart--no-goals-entered {
  .sufficiency-bar-chart__sufficiency-bar {
    display: none;
  }

  .sufficiency-bar-chart__assets-bar {
    min-width: 10px;
    background-color: $color-nt-grey-300;
  }

  .sufficiency-bar-chart__assets-label {
    color: $color-nt-grey-300 !important;
  }
}

.sufficiency-bar-chart--zero-assets.sufficiency-bar-chart--insufficient {
  .sufficiency-bar-chart__assets-bar {
    min-width: 10px;
    background-color: $color-nt-grey-300;
  }

  .sufficiency-bar-chart__percent-bar {
    left: 10px;
    width: calc(100% - 10px);
  }

  .sufficiency-bar-chart__assets-label {
    color: $color-nt-grey-300 !important;
  }
}

.sufficiency-bar-chart--sufficient.sufficiency-bar-chart--zero-goals {
  .sufficiency-bar-chart__sufficiency-label {
    display: block;
  }
}
