.meeting-actions {
  .label {
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
  }

  .meeting-action {
    padding: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .meeting-action__container {
      position: relative;
      background: transparent;
      flex-direction: column;

      &:hover, &:focus {
        background: transparent;
      }

      .meeting-action__label {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
      }

      .meeting-action__description {
        margin-top: 3px;
      }
    }

    &__start {
      background-color: $color-nt-container-background;

      &:hover, &:focus {
        background-color: $color-aqua-100;
      }

      &:focus {
        outline: $color-aqua-800 solid 2px;
      }

      .meeting-action__label {
        color: $color-aqua-800;
      }
    }

    &__join {
      background-color: $color-aqua-800;
      color: $color-nt-container-background;

      &:hover, &:focus {
        background-color: $color-aqua-950;
      }

      &:focus {
        outline: $color-aqua-500 solid 2px;
      }
    }
  }
}

.meeting-history-section {
  margin-top: 58px;

  .meeting-history-tab {
    border-bottom: none;

    &:hover, &:focus {
      border-bottom: none;
    }
  }
}

.meeting-history {

  .meeting-history-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;

    .title {
      font-size: 15px;
      font-weight: 500;
      padding-right: 24px;
    }

    .view-all {
      font-size: 12px;
      font-weight: 500;
      padding-left: 30px;
    }
  }

  .meeting-history-item {
    margin-top: 16px;

    .history-item__icon {
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .history-item__details {
      .row:nth-child(2) {
        margin-top: 2px;
        color: $color-nt-grey-600;
      }
    }

    /* This styles the dashed lines between meeting history calendar icons */
    &:not(:last-child) .history-item__icon .icon:after {
      // content is required for this pseudo-selector to be displayed
      content: "";
      border-left: 1px dashed #B1B1B1;
      // Draws the line up to the correct height and subtracts some for spacing between the line and icon
      height: calc(100% - 2px);
      // Positions the start of the line to draw
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateY(-25%);
    }

  }

}

.ReactModalPortal .ReactModal__Overlay {
  overflow: auto;

  .ReactModal__Content, .modal__content, .modal__body {
    overflow: visible;

    .loading-container {
      text-align: center;
      margin: auto;
      height: 100%;

      .h3 {
        font-weight: 300;
        font-size: 24px;
        color: $color-nt-text;
        margin-bottom: 30px;
      }
    }

    .font-md {
      margin-bottom: 16px;
    }

    .meeting-modal-header {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
    }

    .noMargin {
      margin-top: 0;
      margin-bottom: 0;
    }

    .layout-data-entry-form__field {
      padding: 0;
      flex-direction: column;

      & > *, label, input {
        width: 100%;
      }

      label {
        margin: 0 0 4px 0;
        font-size: 15px;
      }

      span {
        font-size: 13px;
      }

      .input-wrapper {
        width: 100%;
      }

      .radiogroup.show-red-asterisk > label:after {
        margin-left: 0;
      }
    }

    .dropdown__panel {
      max-height: 255px;
    }

    .dropdown__item {
      font-size: 15px;
    }
  }
}

.meeting-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: auto;
  height: auto;
  width: 200px;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  .control-menu-heading {
    display: grid;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding: 0 8px 4px 8px;

    .menu-chevron-container {
      display: flex;
      justify-content: center;
      justify-items: center;

      .control-menu-heading__chevron {
        margin-top: 3px;

        &.meeting-in-progress {
          color: $color-nt-grey-900;
          opacity: 0.5;
        }
      }
    }

    .header-dropdown {
      display: flex;
      align-items: center;
    }

    .meeting-control-action-menu {
      width: 18px !important;

      .dropdown__panel {
        background-color: $color-nt-grey-025;
      }

      button {
        border: none;
      }

      .meeting-info-dropdown-item {
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .control-menu-item__icon {
      margin-right: 4px;
    }
  }

  .control-menu-actions {
    padding: 0 8px 8px 8px;

    .control-menu-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      height: 32px;
      margin-top: 8px;

      &-primary {
        background-color: $color-aqua-800;
        color: #ffffff;

        &:hover, &:focus {
          background-color: $color-aqua-950;
        }

        &:focus {
          box-shadow: 0 0 0 2px $color-aqua-500;
        }
      }

      &-alternate {
        background-color: $color-red-800;
        color: #ffffff;

        &:hover, &:focus {
          background-color: $color-red-900;
        }

        &:focus {
          box-shadow: 0 0 0 2px $color-red-500;
        }
      }
    }

    .meeting-participants {
      font-size: 12px;
      height: 15px;
    }

    .meeting-presenter-label {
      font-size: 12px;
      height: 15px;
      font-weight: 700;
      line-height: 15px;
    }

    .meeting-presenter {
      font-size: 12px;
      height: 15px;
      font-weight: 400;
      line-height: 15px;
    }

    .meeting-participants-badge {
      font-size: 8px;
      width: 24px;
      height: 16px;
      color: white;
      background-color: #206f23;
      margin-bottom: 7px;
      position: relative;
      top: -1px
    }
  }
}

.client-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: white;

  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 32px 48px 32px 48px;
    height: 100%;

    .stacked-bar-chart {
      height: 100%;

      &__label {
        white-space: break-spaces;
      }
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }

  .agenda {
    button {
      display: none;
    }
  }
}

.meeting-toast-container {
  position: absolute;
  bottom: 38px;
  right: 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  z-index: 4;
}

.meeting-modal-inline-error {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 8px;
  margin-top: 0px;
}

.meeting-modal-inline-error-padding {
  margin-top: 8px;
}

.preview-container {
  margin: 12rem auto 16rem auto;
}