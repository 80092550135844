.donut-container {

  &__xsm {
    width: 64px;
    height: 64px;
  }

  &__sm {
    width: 100px;
    height: 100px;
  }
  &__md {
    width: 157px;
    height: 157px;
  }
  &__lg {
    width: 275px;
    height: 275px;
  }
  &__xl {
    width: 299px;
    height: 299px;
  }

  .donut {
    display: flex;
    align-items: center;
    grid-row: span 2;
  }
}