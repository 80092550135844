.accented-accordion-item {
  position: relative;
  display: flex;
  margin-bottom: $spacing-md;
  border-radius: 5px;

  .accordion__item {
    background-color: $color-nt-grey-025;
    border: solid 1px $color-nt-grey-100;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .accordion__button--expandable {
    cursor: pointer;
  }

  .accordion__button {
    border: none !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: none !important;

    &:focus, &:hover {
        background-color: #e1fcff;
        outline: 0px;
    }
  }

  &--hidden {
    display: none;
  }

  &__accent {
    width: $spacing-12;
    background-color: $color-aqua-800;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $spacing-2xxl;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    > span {
      display: flex;
      align-items: center;
    }
  }

  &__left-content {
    padding-left: 9px;
  }

  .accordion-item-present-value-header {
    display: flex;
    align-items: center;
    font-size: $font-size-large;
    padding-right: $spacing-2xl;
  }
}
