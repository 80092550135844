@import "../abstracts/colors";

.nav-drawer-side-menu-list {
  &__section {
    margin-bottom: 8px;
  }

  &__items {
    list-style: none;
  }

  &__item {
    padding-left: 26px;
    &:hover {
      background-color: $color-nt-grey-200;
      color: $color-nt-grey-900;
    }

    a {
      padding-top: 8px;
      padding-bottom: 8px;
      color: $color-nt-grey-900;
      text-decoration: none;
      width: 100%;
    }

    a:hover {
      color: $color-nt-grey-900;
      text-decoration: none;
    }
  }

  .accordion__heading {
    padding: 0;
    font-weight: 500;
  }

  .accordion {
    &__item--expanded {
      cursor: pointer;
      .accented-accordion-item__header {
        border-bottom: 1px solid #cecece !important;
      }
    }

    &__item--collapsed {
      cursor: pointer;
      .accented-accordion-item__header {
        border-bottom: none !important;
      }
    }
  }

}
