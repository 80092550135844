.table-row-container {

  .row {
    height: 41px;
    font-weight: 400;

    .accent {
      height: 100%;
      width: $spacing-12;
      background-color: $color-nt-grey-100;
    }

    .hover-cell {
      input:not(:checked)::before {
        background-color: #D8EEF1 !important;
      }

      background-color: $color-aqua-100 !important;
    }

    .empty-cell {
      background-color: $color-nt-grey-025;
    }

    .empty-hover-cell {
      background-color: #D8EEF1 !important;
    }

    .col {
      padding: 0 32px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color-nt-grey-100;
      border-top: none;
      border-left: none;
      overflow: hidden;
      text-overflow: ellipsis;

      &.title {
        width: 310px;
        padding: 0 8px 0 0 !important;
        justify-content: flex-start;

        .icon {
          &.drag_icon {
            padding-left: 4px;
            padding-right: 4px;
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 6px !important;
          }

          font-size: 18px;
          width: 18px;
          margin-left: 12px;
          margin-right: 7px;
        }
      }
    }

    &.summary-row {
      height: 64px;
      margin-top: 10px;
      font-weight: 400;

      .col {
        border-top: 1px solid $color-nt-grey-100;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
        }

        &.title {
          font-size: 18px;
        }
      }
    }
  }

  .table-row-title-container {
    display: flex;
    flex-direction: column;

    .table-row-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 235px;
    }

    .sub-title {
      color: #767676;
      font-size: 12px;
      font-weight: 400;
      margin-top: -4px;
    }

    .secondary-label {
      font-size: 13px;
    }
  }

  .rendered-value {
    text-align: center;
    width: 100px;
  }

  .indent-title {
    margin-left: 16px;
  }
}