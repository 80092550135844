.data-entry-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $spacing-2xxl;
  padding: $spacing-lg 28px;
  background-color: white;

  @include nt-border-top;

  &--sticky {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateZ(0);
  }

  &__button-delete {
    margin-right: $spacing-md;
  }

  &__button-add,
  &__button-duplicate {
    margin-left: $spacing-md;
  }
}
