.meeting-controls {
  display: flex;
  flex-direction: column;

  .control-menu-item__label {
    white-space: nowrap;
  }

  &__progress-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $spacing-xxxl;
    font-size: 14px;

    &--active {
      background-color: $color-dark-green-600;
    }

    &--paused {
      background-color: $color-orange-700;
    }

    &--stopped {
      background-color: $color-red-700;
    }

    .icon {
      padding: 0;
      margin-right: $spacing-sm;
      font-size: 14px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: $spacing-12;
    background-color: $color-nt-grey-050;
  }

  &__meeting-buttons {
    display: flex;

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: normal;
      text-align: center;
      min-width: calc(50% - 4px);
      height: $spacing-2xxl;
      padding: 0;

      .icon {
        padding: 0;
        margin-bottom: $spacing-sm;
      }
    }
  }

  &__presentation-buttons {
    display: flex;
    margin-top: $spacing-md;

    button {
      padding: 0;
    }

    button:first-child {
      min-width: calc(100% - 40px);
    }

    button:last-child {
      width: $spacing-xxl;
      height: $spacing-xxl;
    }
  }

  &__meeting-buttons,
  &__presentation-buttons {
    button:first-child {
      margin-right: $spacing-sm;
    }

    button:last-child {
      margin-left: $spacing-sm;
    }
  }

  &__participants {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacing-md;
    line-height: 32px;
    font-size: 11px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    background-color: white;

    .badge {
      margin-left: 0;
      margin-right: 8px;
    }

    .dropdown,
    .dropdown button {
      width: 100% !important;
    }
  }

  .btn-skin--secondary {
    background-color: white;
    border: none !important;
    font-size: 11px;
  }
}
