
$checkmarkIcon: "\e94e";

input.asset-stack-checkbox {
  width: 0;
  height: 0;
}

input.asset-stack-checkbox::before {
  content: '';
  background: $color-aqua-800;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

input.asset-stack-checkbox:not(:checked)::before {
  background: $color-nt-grey-050;
}

input.asset-stack-checkbox::after {
  content: $checkmarkIcon;
  width: 24px;
  height: 24px;
  padding: 3px 4px;
  color: $color-aqua-800;
  background: $color-nt-container-background;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: nt-dds-icons, sans-serif;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 4px;
  cursor: pointer;
}

input.asset-stack-checkbox:not(:checked)::after {
  content: '';
  border: 1.3px solid $color-nt-grey-900;
}

input.asset-stack-checkbox:disabled::after {
  border-color: #BBBCBC
}