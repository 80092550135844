.asset-reliance-pill-container {
  display: inline-block;
  margin: auto;
  position: absolute;
  width: max-content;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid;
  border-radius: 32px;
  height: 23px;
  padding: 0 10px 0 11px;

  .pill-text {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
  }
}