// -----------------------------------------------------------------------------
// This file contains padding and margin styles.
// -----------------------------------------------------------------------------

$spacing-xs: 2px;
$spacing-3: 3px;
$spacing-sm: 4px;
$spacing-md: 8px;
$spacing-10: 10px;
$spacing-12: 12px;
$spacing-lg: 16px;
$spacing-20: 20px;
$spacing-xl: 24px;
$spacing-xxl: 32px;
$spacing-xxxl: 40px;
$spacing-2xl: 48px;
$spacing-2xxl: 64px;
$spacing-2xxxl: 96px;

@mixin spacing-make($sizes, $names) {
  @for $i from 1 through length($sizes) {
    /* margins */
    .margin-#{nth($names, $i)} { margin: #{nth($sizes, $i)}px; }
    .margintop-#{nth($names, $i)} { margin-top: #{nth($sizes, $i)}px; }
    .marginbottom-#{nth($names, $i)} { margin-bottom: #{nth($sizes, $i)}px; }
    .marginleft-#{nth($names, $i)} { margin-left: #{nth($sizes, $i)}px; }
    .marginright-#{nth($names, $i)} { margin-right: #{nth($sizes, $i)}px; }

    /* padding */
    .padding-#{nth($names, $i)} { padding: #{nth($sizes, $i)}px; }
    .paddingtop-#{nth($names, $i)} { padding-top: #{nth($sizes, $i)}px; }
    .paddingbottom-#{nth($names, $i)} { padding-bottom: #{nth($sizes, $i)}px; }
    .paddingleft-#{nth($names, $i)} { padding-left: #{nth($sizes, $i)}px; }
    .paddingright-#{nth($names, $i)} { padding-right: #{nth($sizes, $i)}px; }
  }
}

@include spacing-make(0 2 3 4 8 12 16 20 24 32 40, 'none' 'xs' '3' 'sm' 'md' '12' 'lg' '20' 'xl' 'xxl' 'xxxl');
