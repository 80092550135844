.current-proposed-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color-nt-container-background;

  .line-overlay-container {
    position: relative;

    .line-overlay {
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1px;
      background-color: $color-nt-grey-500;
    }
  }

  .current-proposed-container {
    padding-top: 48px;

    .row {
      flex-wrap: nowrap !important;
    }

    .allocation-donut {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .title {
        display: flex;
        justify-content: center;
        padding-bottom: 8px;
        font-size: 15px;
        font-weight: 500;
      }
    }

    .current-allocation-amount {
      font-weight: 500;
    }

    .investable-assets {
      font-size: 24px;
      line-height: 30px;
      font-weight: $font-weight-light
    }

    .asset-holding-title-container {
      display: flex;
      align-items: center;
      padding-left: 8px;

      .title {
        width: 100px;
        height: 24px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $color-nt-grey-700;
        white-space: nowrap;
      }

      .asset-holding-accordion-icon {
        padding-left: 12px;
        padding-right: 5px;
      }
    }

    .amount-header-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      width: 265px;

      .amount-header {
        width: 116px;
        height: 24px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: right;
      }
    }

    .accented-accordion-item__accent {
      z-index: 1;
    }
  }

  .allocation-type {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 337px;
    padding-bottom: 8px;

    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #3D4042;
    }
  }

  .sub-header-container {
    display: flex;
    flex-direction: column;

    &.proposed {
      margin-left: -2px;
    }

    .amounts {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-bottom: 8px;

      .total-dollar-amount {
        height: 15px;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        text-align: right;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      .total-percent-amount {
        height: 15px;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        text-align: right;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      .sub-header {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        padding-bottom: 9px;
        padding-left: 25px;
        padding-top: 12px;

        &.cell-text-align {
          text-align: right;
          border-bottom: 1px solid #CECECE;
        }

      }
    }
  }

  .total-allocation-font {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    color: #6B6E6F;
  }

  .footer {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 25px;

    &.cell-text-align {
      text-align: right;
    }

    &.total-allocation {
      display: flex;
      padding-left: 34px;
    }
  }

  .allocation-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 15px;

    &.current {
      width: 265px;
    }

    &.proposed {
      justify-content: space-between;
      width: 265px;
      border-left: 1px solid #808182;
    }

    &.empty {
      justify-content: flex-end;
    }

    &.cell-text-align {
      text-align: left;
    }

  }

  .allocation-grid {
    border-top: 1px solid #808182;

    .total-allocation-container {
      display: flex;
      justify-content: flex-end;
      width: 265px;

      &.proposed {
        height: 41px;
        margin-top: -8px;
        align-items: center;
      }

      .total {
        display: flex;
        justify-content: flex-end;
        width: 116px;
      }
    }
  }

  .allocation-grid-table {
    [role="cell"] {
      padding-left: 8px;
      padding-right: 8px;
      white-space: nowrap;

      &.cell-text-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .asset-allocation-grid {
    align-items: center;
    background-color: $color-nt-container-background;
    border-radius: 5px;
    height: 64px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    color: $color-nt-text;
  }

  .asset-stack-table-accordion {
    width: 100%;
  }

  .asset-subclass-summary-grid {
    background-color: $color-nt-container-background;
    height: 41px;
    align-items: center;

    .subclass-total-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      width: 265px;

      .subclass-total-amount {
        text-align: right;
      }
    }

    .subclass-first-column-container {
      display: flex;
      align-items: center;
    }

    .subclass-first-column {
      padding-left: 32px;
    }

    .asset-class-first-column {
      padding-left: 20px;
    }

  }

  .empty-subclass-total {
    text-align: right;
    padding-right: 3px;
  }
}