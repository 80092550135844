.empty-asset-allocation {
  display: flex;
  justify-content: center;
  margin-bottom: 964px;
  margin-top: 88px;

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;

    /* Grey/900 */

    color: #3D4042;
  }
}

