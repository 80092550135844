.stacked-bar-chart {
  position: relative;
  height: 100%; // fallback
  height: fit-content;
  flex-grow: 1;

  &__items {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 2px;
    transition: opacity 0.3s;

    &--inactive {
      opacity: 0.5;
    }
  }

  &__fill {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &--selectable &__fill {
    cursor: pointer;
    outline: none;
  }

  &__highlight {
    position: absolute;
    left: -5%;
    width: 110%;
    transition: height 0.4s, visibility 0.399s;

    &--hidden {
      visibility: hidden;
    }
  }

  &__labels {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    height: 100%;
    margin-left: $spacing-xl;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__label-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: height 0.3s, opacity 0.3s;

    &--hidden {
      opacity: 0;
    }

    &--inactive {
      opacity: 0;
    }
  }

  &__label {
    font-size: 15px;
    line-height: 18px;
    white-space: nowrap;
  }

  &__total {
    font-size: 18px;
    line-height: 21px;
    white-space: nowrap;
  }

  &__divider {
    height: 2px;
    min-height: 2px;
    max-height: 2px;
    transform: scaleX(1.1);
    background-color: white;

    &--hidden {
      visibility: hidden;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
