.archived-proposals-container {

  .container-fluid {
    padding: 0px;
  }

  .profile-details-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #search_container {
    height: 3.077rem;
    width: 375px;
    margin-left: 12px;
    font-size: 15px;
    //float: left;
    .search__btn--close-no-label {
      margin-top: 1px;
    }
  }

  .table-spacing {
    margin-bottom: 24px;
  }

  .ReactTable {
    padding-top: 16px;
    border: none;

    .table__noData {
      .h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 30px;
        padding-top: 88px;
      }
    }
  }


  .ReactTable.table {
    .rt-thead.-header .rt-th {
      border-bottom: 1px solid #cecece !important;
    }
  }

  .profileProposalMenu {
    margin-left: -16px;

    button {
      border: none;
    }
  }

  .menuItem {
    border-bottom: 1px solid;
    border-color: $color-nt-borders;
    padding: 0;
    margin: 3px -18px;
  }
}