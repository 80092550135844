.tax-detail-required-alert {
  padding-left: 340px;

  .input__info {
    margin-top: 0;
  }
}

.tax-details {
  .tax-details-info-icon {
    color: $color-aqua-800;
  }

  .radiogroup {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 16px;

    label {
      flex-basis: 66%;
      margin: auto 16px auto 0;
    }
  }

  .radio-group-with-icon {
    display: flex;
    position: relative;

    .radiogroup {
      margin-bottom: 0;
    }

    button {
      position: absolute;
      right: 0;
    }

    .dds-icons.icon {
      color: $color-aqua-800;
    }

    .dds-icons.icon:hover {
      color: #283a3c;
    }
  }

  .tax-info-card {

    border: none;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 20%);
    transform: translateY(0);

    &__content {
      border: 1px solid #cecece;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      padding: 16px;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      z-index: 10;
      background-color: #fff;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      box-sizing: border-box;
      border: 6px solid transparent;
      transform-origin: 0 0;
      z-index: 11;
      left: 98.5%;
    }

    &:before {
      top: 0;
      transform: rotate(135deg);
      border-color: transparent transparent #cecece #cecece;
    }

    &:after {
      top: 1px;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: none;
      background-color: white;
    }
  }

  .tax-liabilities-grid {
    display: grid;
    grid-template-columns: 5fr 2fr 2fr;

    .tax-liabilities-table-header {
      span {
        font-size: 13px;
        font-weight: 400;
        font-stretch: condensed;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-family: RobotoCondensed, Roboto, sans-serif;
      }
    }

    .tax-liabilities-table-row {
      span {
        font-size: 15px;
        font-weight: 500;
      }
    }

    .tax-liabilities-grid-item {
      border-bottom: 1px solid $color-nt-grey-200;
      color: $color-nt-grey-900;
      align-items: center;
      font-family: Roboto, sans-serif;
      padding: 8px 0;
    }

    .grid-span-all {
      grid-column: 1 / -1;
    }

    .disabled-dash {
      color: $color-nt-grey-200;
    }
  }
}