.range-inputs {
  display: flex;
  flex-direction: row;
  input {
    width: 52px !important;
  }

  span {
    width: 30px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}
